<div class="flex flex-row items-stretch pl-4 pr-2 pt-7">
  <div class="flex-1">
    <ng-container [ngSwitch]="translate.language$ | async">
      <div *ngSwitchCase="SupportedLanguage.EN">
        <img class="w-[10.375rem]" src="assets/swire_logo-black_en.svg" />
        <img class="w-[8.375rem] mt-2" src="assets/menu_logo_text_en.svg" />
      </div>

      <div *ngSwitchCase="SupportedLanguage.ZH_HANT_HK">
        <img
          class="w-[6.75rem]"
          src="assets/swire_logo-black_zh-hant-hk.png"
          [alt]="'NAV.LOGO_ALT' | translate"
        />
        <img
          class="w-[9.625rem] mt-2"
          src="assets/menu_logo_text_zh-hant-hk.svg"
        />
      </div>

      <div *ngSwitchCase="SupportedLanguage.ZH_HANS">
        <img
          class="w-[6.75rem]"
          src="assets/swire_logo-black_zh-hans.png"
          [alt]="'NAV.LOGO_ALT' | translate"
        />
        <img
          class="w-[9.625rem] mt-2"
          src="assets/menu_logo_text_zh-hans.svg"
          [alt]="'NAV.LOGO_ALT' | translate"
        />
      </div>
    </ng-container>
  </div>
  <button
    mat-icon-button
    class="self-start -mt-2 leading-none"
    (click)="closeMenu.emit()"
  >
    <mat-icon
      inline
      class="inline-block w-6 h-6 text-primary_darker"
      svgIcon="streamline:remove"
    ></mat-icon>
  </button>
</div>
<mat-nav-list class="nav-list flex-1 overflow-y-auto mt-5 sm:mt-3">
  <ng-template #section let-section let-title="title">
    <ng-container *ngIf="sections.get(section) as items">
      <ng-template #singleNavItem let-item>
        <a
          mat-list-item
          [routerLink]="item.route"
          class="link"
          routerLinkActive="link--active"
          (click)="onLinkClicked()"
          cdkFocusInitial
        >
          <mat-icon
            *ngIf="item.svgIcon"
            inline
            class="inline-block flex-shrink-0 w-6 h-6 mr-3 icon"
            [svgIcon]="item.svgIcon"
          ></mat-icon>
          <span
            class="text-base tracking-wide10 leading-tight line-clamp-1 break-all flex-1"
            >{{ item.titleKey | translate }}</span
          >
          <app-badge-icon
            *ngIf="notificationAlerts"
            [maxDisplayNum]="999"
            [shouldShowDot]="item.badgeIconStyle === 'dot'"
            [count]="notificationAlerts[item.alertType] || 0"
          ></app-badge-icon>
        </a>
      </ng-template>
      <section
        *ngIf="items.length > 0"
        class="menu-section menu-section_p px-2 pb-3"
      >
        <h3
          *ngIf="sectionTitleKey(section) as titleKey"
          class="mx-2 mt-4 mb-2 text-xs tracking-wide8 leading-tight font-bold text-primary_800 line-clamp-1 break-all"
        >
          {{ titleKey | translate }}
        </h3>
        <ng-container *ngFor="let navItem of items">
          <ng-container *ngIf="navItem.type === NavMenuItemType.SingleItem">
            <ng-container
              *ngTemplateOutlet="singleNavItem; context: { $implicit: navItem }"
            >
            </ng-container>
          </ng-container>
          <ng-container *ngIf="navItem.type === NavMenuItemType.ExpandableItem">
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-icon
                  inline
                  class="inline-block flex-shrink-0 w-6 h-6 mr-3 icon"
                  [svgIcon]="navItem.svgIcon"
                ></mat-icon>
                <span
                  class="text-base tracking-wide10 leading-tight line-clamp-1 break-all flex-1"
                  >{{ navItem.titleKey | translate }}</span
                >
              </mat-expansion-panel-header>
              <ng-container *ngFor="let item of navItem.items">
                <ng-container
                  *ngTemplateOutlet="
                    singleNavItem;
                    context: { $implicit: item }
                  "
                >
                </ng-container>
              </ng-container>
            </mat-expansion-panel>
          </ng-container>
        </ng-container>
      </section>
    </ng-container>
  </ng-template>
  <ng-container
    *ngTemplateOutlet="section; context: { $implicit: NavMenuSection.Top }"
  ></ng-container>
  <hr class="divider border-border" />
  <ng-container
    *ngTemplateOutlet="
      section;
      context: {
        $implicit: NavMenuSection.Communication
      }
    "
  ></ng-container>
  <hr class="divider border-border" />
  <ng-container
    *ngTemplateOutlet="
      section;
      context: {
        $implicit: NavMenuSection.SelfServices
      }
    "
  ></ng-container>
  <hr class="divider border-border" />
  <ng-container
    *ngTemplateOutlet="
      section;
      context: {
        $implicit: NavMenuSection.Account
      }
    "
  ></ng-container>
</mat-nav-list>
