export interface IBuilding {
  id: number;
  name: string;
}

export interface IEditableBuilding extends IBuilding {
  isEditable: boolean;
}

export function compareBuilding(a: IBuilding, b: IBuilding): number {
  return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
}
