import { OperationCode } from '@core/enum';

export type OperationCodeExpression = OperationCode | And | Or | Not;

export interface And {
  operator: 'and';
  subExprArray: OperationCodeExpression[];
}

export interface Or {
  operator: 'or';
  subExprArray: OperationCodeExpression[];
}

export interface Not {
  operator: 'not';
  subExpr: OperationCodeExpression;
}

export function and(...subExprArray: OperationCodeExpression[]): And {
  return {
    operator: 'and',
    subExprArray,
  };
}

export function or(...subExprArray: OperationCodeExpression[]): Or {
  return {
    operator: 'or',
    subExprArray,
  };
}

export function not(subExpr: OperationCodeExpression): Not {
  return {
    operator: 'not',
    subExpr,
  };
}
