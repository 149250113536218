import { Component, HostBinding, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-hover-tooltip',
  templateUrl: './hover-tooltip.component.html',
  styleUrls: ['./hover-tooltip.component.scss'],
})
export class HoverTooltipComponent {
  @Input()
  value: string | undefined;

  @Input()
  contentTemplate: TemplateRef<any> | null = null;

  @Input()
  contentTemplateContext: any;

  @HostBinding('style.left')
  left = '0px';

  @HostBinding('style.top')
  top = '0px';
}
