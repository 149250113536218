import { Injectable } from '@angular/core';
import { ApiClient } from '@core/http/api-client';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import {
  IAPINotification,
  IAPINotificationAlert,
  IAPINotificationMarkReadRequestParams,
  IAPINotificationRequestParams,
  IAPINotificationType,
  INotificationSortField,
} from './models/notifications';
import {
  mapAPINotification,
  mapAPINotificationAlert,
  mapAPINotificationType,
} from './mappers/notifications-api';
import { IListResponse } from './models/common';
import { createHttpParams } from './utils/params';
import { mapIListResponse } from './mappers/common-api';

@Injectable({
  providedIn: 'root',
})
export class NotificationsAPIService {
  constructor(private readonly http: ApiClient) {}

  public getNotifications(
    params?: IAPINotificationRequestParams
  ): Observable<IListResponse<IAPINotification>> {
    const httpParams = createHttpParams({
      types: params?.types?.join(','),
      buildingIDs: params?.buildingIds?.join(','),
      unread: params?.unread ? true : null,
      searchTerm: params?.search,
      sortBy: params?.sort?.field ?? INotificationSortField.date,
      order: params?.sort?.order === 'desc' ? 'DESC' : 'ASC',
      limit: params?.limit,
      page: params?.page,
    });

    return this.http
      .get<IListResponse<any>>('notifications', {
        params: httpParams,
      })
      .pipe(map((resp) => mapIListResponse(resp, mapAPINotification)));
  }

  public getTypes(): Observable<IAPINotificationType[]> {
    return this.http.get<any>('notifications/types').pipe(
      map((resp) => {
        return resp.list.map(mapAPINotificationType);
      })
    );
  }

  public markAllAsRead(
    params?: IAPINotificationMarkReadRequestParams
  ): Observable<IAPINotification[]> {
    const httpParams = createHttpParams({
      types: params?.types?.join(','),
      buildingIDs: params?.buildingIds?.join(','),
      unread: params?.unread,
    });
    return this.http
      .put<any>('notifications/mark-read', {
        params: httpParams,
      })
      .pipe(map((resp) => resp.list.map(mapAPINotification)));
  }

  public markAsRead(id: number): Observable<IAPINotification> {
    return this.http
      .put(`notifications/${id}/mark-read`, {})
      .pipe(map(mapAPINotification));
  }

  public delete(id: number): Observable<void> {
    return this.http.delete(`notifications/${id}`, {}).pipe(mapTo(undefined));
  }

  public deleteAll(): Observable<void> {
    return this.http.delete(`notifications`, {}).pipe(mapTo(undefined));
  }

  public getAlerts(): Observable<IAPINotificationAlert[]> {
    return this.http
      .get<any>(`notifications/alerts`)
      .pipe(map((resp) => resp.list.map(mapAPINotificationAlert)));
  }
}
