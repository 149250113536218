import {
  Directive,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DismissableModalService {
  readonly dismissableModals = new Set<DismissableModalDirective>();
}

@Directive({
  selector: '[appDismissableModal]',
})
export class DismissableModalDirective implements OnInit, OnDestroy {
  @Output('appDismissableModal')
  readonly dismiss = new EventEmitter();

  @Input('appDismissableModalIsActive')
  isActive = true;

  constructor(private readonly modals: DismissableModalService) {}

  ngOnInit(): void {
    this.modals.dismissableModals.add(this);
  }

  ngOnDestroy(): void {
    this.modals.dismissableModals.delete(this);
  }
}
