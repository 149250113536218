<ng-container *ngIf="value">
  {{ value }}
</ng-container>

<ng-container *ngIf="contentTemplate">
  <ng-container
    [ngTemplateOutlet]="contentTemplate"
    [ngTemplateOutletContext]="contentTemplateContext"
  ></ng-container>
</ng-container>
