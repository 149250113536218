<div class="flex items-stretch h-full">
  <ng-container *ngFor="let button of buttons | async">
    <a
      *ngIf="checkIfHasAccessRight(button.operationCode) | async"
      [routerLink]="button.link"
      class="relative toolbar-button flex flex-col justify-center text-xs text-secondary_dark items-center sm:pt-2 pt-1 sm:mx-0 mx-1.5"
    >
      <span class="opacity-40 sm:mb-2 mb-1 sm:w-6 sm:h-6 sm w-5 h-5 text-black">
        <mat-icon [inline]="true" [svgIcon]="button.svgIcon"></mat-icon
      ></span>
      <span class="tracking-wide8">{{ button.titleKey | translate }}</span>
      <app-badge-icon
        *ngIf="notificationAlerts"
        class="absolute top-1 right-1 sm:top-3 sm:right-3"
        [maxDisplayNum]="99"
        [count]="notificationAlerts[button.alertType] || 0"
      ></app-badge-icon>
    </a>
  </ng-container>
</div>
