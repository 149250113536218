import { ICodeDescription } from '@model/common';
import { APIBuildingFloorCodec, IListResponse } from './common';
import * as t from 'io-ts';

export interface IEmailSignInParams {
  email: string;
  password: string;
}

export interface ITokenSignInParams {
  token: string;
}

export type SignInParams = IEmailSignInParams | ITokenSignInParams;

export interface IAuthResponse {
  accessToken: string;
  idToken: string;
  refreshToken: string;
  expiresIn: number;
  requiredPasswordReset: boolean;
}

export interface IOTPRequestResponse {
  uuid: string;
  expiryMinutes: number;
  otp?: string;
  otpGenerationWait: boolean;
}

export interface IOTPValidationRequestParams {
  uuid: string;
  otp: string;
}

export interface IOTPValidationResponse {
  success: boolean;
  token: string | null;
  message: any;
}

export enum APIRoleACLType {
  GppSubmissionGroup = 'GPP_SUBMISSION_GROUP',
  LeaseIdentifier = 'LEASE_IDENTIFIER',
  Building = 'BUILDING',
  LeaseFloor = 'LEASE_FLOOR',
}

export interface IAPIRole {
  roleCode: string;
  description: string;
  descriptionZhHK: string | null;
  descriptionZhCN: string | null;
  allowSetupInTP: boolean;
  visibleInTP: boolean;
  aclType: APIRoleACLType;
}

export interface ISignUpRequestParams {
  email: string;
  password: string;
  token: string;
}

export interface ISignUpResponse {
  enabled: boolean;
  userStatus: string;
  lastModifiedDate: string;
  userCreatedDate: string;
}

export interface IResetPasswordRequestParams {
  email: string;
  password: string;
  token: string;
}

export interface IResetPasswordResponse {
  enabled: boolean;
  email: string;
}

export interface IUserInfoUpdateRequestPhoneParams {
  sequence: number;
  phoneType: string;
  telNo: string;
}

export interface IUserInfoUpdateRequestParams {
  displayName: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  company: string;
  phones: IUserInfoUpdateRequestPhoneParams[];
}

export interface IUserPasswordUpdateRequestParams {
  oldPassword: string;
  newPassword: string;
}

export interface IUserPreferenceLanguageUpdateRequestParams {
  language: 'en' | 'zh_hk' | 'zh_cn';
}

export interface IAPIUserSupportContact {
  buildingID: number;
  contactType: string;
  telNo: string;
  email: string;
}

export interface IAPIUserSupportContactType {
  code: string;
  description: string;
  descriptionZhHK: string;
  descriptionZhCN: string;
}

export interface IAPITenancy {
  shopName: string | null;
  unitName: string | null;
  buildingID: number;
  buildingName: string;
  localBuildingName: string;
  portfolioType: string;
  roles: IAPIRole[];
  leaseIdentifier: string | null;
  leaseNo: string | null;
  tenantName: string;
}

export type IUserSupportContactResponse = IListResponse<IAPIUserSupportContact>;
export type IUserSupportContactTypeResponse =
  IListResponse<IAPIUserSupportContactType>;
export type IGetTenancyListReponse = IListResponse<IAPITenancy>;

export interface IAPIUserPhoneType {
  tpShow: boolean;
  active: boolean;
  phoneType: string;
  description: string;
  descriptionZhHK: string;
  descriptionZhCN: string;
  sequence: number;
}

export interface IAPIRegisterRequest {
  buildingID: number;
  buildingName: string;
  unit: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneType: string;
  telNo: string;
  jobTitle: string;
}

export interface IAPIUser {
  version: number;
  userId: number;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string | null;
  department: string | null;
  company: string | null;
  remarks: string | null;
  status: string;
  operationCodes: string[];
  accessRights: IAPIUserAccessRight[];
  acl: IAPIUserACL | null;
  roles: IAPIRole[] | null;
  phones: IAPIUserPhone[];
  hasFullAccess: boolean;
  migratedUser: boolean;
  preference: IAPIUserPreference;
}

export interface IAPIUserPreference {
  lang: string;
  domainWhitelist: string[];
}

export interface IAPIUserAccessRight {
  buildingID: number | null;
  buildingName: string | null;
  gppSubmissionGroupID: number | null;
  leaseIdentifier: string | null;
  leaseNo: string | null;
  localBuildingName: string | null;
  portfolioType: string | null;
  roles: IAPIRole[];
  shopName: string | null;
  tenantName: string | null;
  unitName: string | null;
  status: string | null;
  additionalInfo?: { carParkDescription: string | null };
  tenantAdmin: boolean;
}

export interface IAPIUserACL {
  roles: IAPIUserACLRole[];
}

export interface IAPIUserACLRole {
  roleCode: string;
  entitlements: IAPIUserACLEntitlement[];
}

export interface IAPIUserACLEntitlement {
  buildingID: string | null;
  leaseIdentifier: string | null;
  buildingFloorID: string | null;
  gppSubmissionGroupID: number | null;
}

export interface IAPIUserPhone {
  phoneType: string;
  telNo: string;
  sequence: number;
}

export interface IAPIAuthSubmissionGroupRole {
  shopName?: string;
  unitName?: string;
  buildingID?: string;
  buildingName?: string;
  localBuildingName?: string;
  portfolioType?: string;
  roles: IAPIRole[];
  leaseIdentifier?: string;
  leaseNo?: string;
  tenantName?: string;
  status?: string;
  statusName?: string;
  gppSubmissionGroupID: number;
  additionalInfo?: string;
}

export interface IAPIAuthSubmissionGroup {
  companyID: number;
  companyName: string;
  submissionGroupID: number;
  submissionGroupName: string;
  effectiveMonth: Date;
  expiryMonth: Date;
  expiryMonthMode: string;
  status: ICodeDescription;
  buildingIDs: number[];
  buildingNames: string[] | null;
}

export type IAPIUserOpCodesResponse = Record<
  string,
  | {
      buildingIDs: number[];
      leaseIdentifiers: string[];
    }
  | undefined
>;

export const APILeaseFloorCodec = t.type({
  rodeCode: t.string, // It is a typo from B/E
  leaseIdentifier: t.string,
  buildingFloors: t.array(APIBuildingFloorCodec),
});
export type APILeaseFloor = t.TypeOf<typeof APILeaseFloorCodec>;
