import { Component, OnInit } from '@angular/core';
import { APIError, isAPIErrorResponse } from '@core/http/errors';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorComponent implements OnInit {
  error: APIError | null = null;
  actionLabel: string | null = null;
  generalError: string;
  currentTime = new Date();
  showDetail: boolean = false;

  ngOnInit(): void {
    const resp = window.history.state.error?.resp;
    if (resp && isAPIErrorResponse(resp)) {
      this.error = new APIError(resp);
    } else if (window.history.state.error?.stack) {
      this.generalError = window.history.state.error?.stack;
    }

    this.actionLabel = window.history.state.actionLabel || null;
  }

  showHideDetail(): void {
    this.showDetail = !this.showDetail;
  }
}
