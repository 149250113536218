import { IUserAccessRight } from '@model/user';
import {
  IAPIRole,
  IAPIUserAccessRight,
  APIRoleACLType,
  IAPIUserACL,
  IAPIUser,
  IAPIUserPreference,
} from '@service/api/models/auth';
import {
  IMappedUserManagementUser,
  IGppSubmissionGroup,
  IGppRole,
  IUserGppAccessRight,
  IGppSubmissionGroupRoles,
  IUserACLLeaseFloorBuildingFloorID,
  IUserACL,
  IUserACLLeaseFloor,
  IUserPreference,
  IUserManagementUser,
  IUserRequestPhone,
  IAPIUserStatus,
} from '@service/api/models/user-management';
import { mapLanguagePreference } from '@service/api/utils/utils';
import { IUserCreationRequestPhoneParams } from '@service/user-management/user-management.service';
import { formatPhone } from '@shared/utils/phone';
import { sortBy } from 'lodash';
import moment from 'moment';
import { mapUserPhone } from './auth';
import { mapLease, mapRole } from './common';
import { IAPIGppSubmissionGroup } from '@service/api/models/access-control';
import { ICodeDescription } from '@model/common';

export function compareTenant(
  a: IMappedUserManagementUser,
  b: IMappedUserManagementUser
): number {
  return (
    a.displayName.localeCompare(b.displayName) ||
    (a.jobTitle?.localeCompare(b.jobTitle ?? '') ?? 0)
  );
}

export function mapRequestPhone(
  p: IUserCreationRequestPhoneParams,
  index: number
): IUserRequestPhone {
  const formattedPhone = formatPhone(p.areaCode, p.phoneNumber);
  return {
    phoneType: p.phoneType,
    sequence: index + 1,
    telNo: formattedPhone,
  };
}

export function mapIGppSubmissionGroup(
  apiItem: IAPIGppSubmissionGroup
): IGppSubmissionGroup {
  return {
    gppSubmissionGroupId: apiItem.gppSubmissionGroupID,
    groupName: apiItem.name,
    companyName: apiItem.companyName,
    effectiveDate: apiItem.effectiveMonth,
    expiryDate: apiItem.expiryMonth,
    isExpired: moment(apiItem.expiryMonth)
      .endOf('month')
      .isBefore(moment().startOf('month')),
    editable: apiItem.editable,
  };
}

export function mapGppRole(apiRole: IAPIRole): IGppRole {
  return {
    roleCode: apiRole.roleCode,
    roleName: {
      enUS: apiRole.description,
      zhHant: apiRole.descriptionZhHK || undefined,
      zhHans: apiRole.descriptionZhCN || undefined,
    },
  };
}

export function mapAccessRights(rights: IAPIUserAccessRight[]): {
  gppAccessRights: IUserGppAccessRight[];
  leaseAccessRights: IUserAccessRight[];
} {
  const leaseAccessRights: IUserAccessRight[] = [];
  const gppAccessRights: IUserGppAccessRight[] = [];
  for (const right of rights) {
    if (right.gppSubmissionGroupID) {
      gppAccessRights.push({
        gppSubmissionGroupId: right.gppSubmissionGroupID,
        roles: right.roles
          .filter((r) => r.aclType === APIRoleACLType.GppSubmissionGroup)
          .map(mapGppRole)
          .sort((lhs, rhs) =>
            lhs.roleName.enUS.localeCompare(rhs.roleName.enUS, 'en', {
              sensitivity: 'base',
            })
          ),
      });
    } else {
      const leaseRight: IUserAccessRight = {
        lease: mapLease({
          currentShopList: right.shopName,
          unitName: right.unitName,
          building: {
            buildingID: right.buildingID,
            localBuildingName: right.localBuildingName,
          },
          portfolioType: right.portfolioType,
          leaseIdentifier: right.leaseIdentifier,
          leaseNo: right.leaseNo,
          tradeCategoryGroupID: null,
          currentTenantName: right.tenantName,
          status: right.status,
          additionalInfo: right.additionalInfo ?? { carParkDescription: null },
        }),
        roles: right.roles
          .filter((r) =>
            [
              APIRoleACLType.LeaseIdentifier,
              APIRoleACLType.Building,
              APIRoleACLType.LeaseFloor,
            ].includes(r.aclType)
          )
          .map(mapRole)
          .sort((a, b) =>
            a.description.enUS.localeCompare(b.description.enUS, 'en', {
              sensitivity: 'base',
            })
          ),
        isTenantAdmin: right.tenantAdmin,
      };
      leaseAccessRights.push(leaseRight);
    }
  }
  return {
    leaseAccessRights,
    gppAccessRights,
  };
}

export function mapMappedAccessRights(
  rights: IAPIUserAccessRight[],
  gppSubmissionGroupMap: Map<number, IGppSubmissionGroup>
): {
  gppSubmissionGroupRoles: IGppSubmissionGroupRoles[];
  leaseAccessRights: IUserAccessRight[];
} {
  const groupedRights = mapAccessRights(rights);
  const leaseAccessRights: IUserAccessRight[] = groupedRights.leaseAccessRights;
  const gppSubmissionGroupRoles: IGppSubmissionGroupRoles[] =
    groupedRights.gppAccessRights.flatMap((gppRight) => {
      const gppGroup = gppSubmissionGroupMap.get(gppRight.gppSubmissionGroupId);
      if (gppGroup == null) {
        console.error(
          `Cannot find gpp submission group of id ${gppRight.gppSubmissionGroupId}`,
          gppRight
        );
        return [];
      }
      return [
        {
          gppSubmissionGroup: gppGroup,
          roles: gppRight.roles,
        },
      ];
    });

  return {
    leaseAccessRights,
    gppSubmissionGroupRoles,
  };
}

export function parseBuildingFloorID(
  apiValue: string
): IUserACLLeaseFloorBuildingFloorID | null {
  if (apiValue === '*') {
    return '*';
  }

  if (!isNaN(Number(apiValue))) {
    return Number(apiValue);
  }

  console.error(`Unable to parse invalid building floor ID ${apiValue}`);
  return null;
}

export function mapUserACL(
  acl: IAPIUserACL,
  roles: IAPIRole[],
  accessRights: IAPIUserAccessRight[]
): IUserACL {
  const roleMap = new Map(roles.map((role) => [role.roleCode, role]));
  const leaseFloorList: IUserACLLeaseFloor[] = [
    ...new Set(
      accessRights
        .filter((ar) => ar.leaseIdentifier != null)
        .map((ar) => ar.leaseIdentifier)
    ),
  ].map((leaseIdentifier) => ({
    leaseIdentifier,
    roleEntitlements: [],
  }));

  acl.roles
    .filter((role) => {
      return roleMap.get(role.roleCode)?.aclType === APIRoleACLType.LeaseFloor;
    })
    .forEach((role) => {
      role.entitlements.forEach((entitlement) => {
        if (
          entitlement.leaseIdentifier == null ||
          entitlement.buildingFloorID == null
        ) {
          return;
        }

        const leaseFloor = leaseFloorList.find(
          (floor) => floor.leaseIdentifier === entitlement.leaseIdentifier
        );

        if (leaseFloor == null) {
          return;
        }

        const roleEntitlement = leaseFloor.roleEntitlements.find(
          (leaseFloorEntitlement) =>
            leaseFloorEntitlement.roleCode === role.roleCode
        );

        const currentBuildingFloorID = parseBuildingFloorID(
          entitlement.buildingFloorID
        );

        if (roleEntitlement == null && currentBuildingFloorID != null) {
          leaseFloor.roleEntitlements.push({
            roleCode: role.roleCode,
            buildingFloorIDs: [currentBuildingFloorID],
          });
        } else if (roleEntitlement != null && currentBuildingFloorID != null) {
          roleEntitlement.buildingFloorIDs.push(currentBuildingFloorID);
        }
      });
    });
  return {
    leaseFloors: leaseFloorList,
  };
}

export function mapUserPreference(api: IAPIUserPreference): IUserPreference {
  return {
    language: mapLanguagePreference(api.lang),
    domainWhitelist: api.domainWhitelist,
  };
}

export function mapUserManagementUser(apiUser: IAPIUser): IUserManagementUser {
  const phones = sortBy(apiUser.phones, (p) => p.sequence).map(mapUserPhone);
  const rights = mapAccessRights(apiUser.accessRights);
  const acl =
    apiUser.acl != null && apiUser.roles != null
      ? mapUserACL(apiUser.acl, apiUser.roles, apiUser.accessRights)
      : null;
  return {
    id: apiUser.userId,
    displayName: apiUser.displayName,
    firstName: apiUser.firstName,
    lastName: apiUser.lastName,
    email: apiUser.email,
    phones: phones,
    jobTitle: apiUser.jobTitle,
    company: apiUser.company,
    department: apiUser.department,
    remarks: apiUser.remarks,
    operationCodes: apiUser.operationCodes.sort((a, b) => a.localeCompare(b)),
    status: apiUser.status,
    acl: acl,
    accessRights: rights.leaseAccessRights,
    version: apiUser.version,
    hasFullAccess: apiUser.hasFullAccess,
    gppAccessRights: rights.gppAccessRights,
    migratedUser: apiUser.migratedUser,
    preference:
      apiUser.preference == null ? null : mapUserPreference(apiUser.preference),
  };
}

export function mapUserStatus(status: IAPIUserStatus): ICodeDescription {
  return {
    code: status.statusCode,
    description: {
      enUS: status.description,
      zhHans: status.descriptionZhCN,
      zhHant: status.descriptionZhHK,
    },
  };
}
