import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ScreenSize {
  XS = 0,
  SM = 1,
  MD = 2,
  LG = 3,
  XL = 4,
}

export function observeScreenSize(
  breakpoint: BreakpointObserver
): Observable<ScreenSize> {
  const smQuery = '(min-width: 640px) and (min-height: 520px)';
  const mdQuery = '(min-width: 768px) and (min-height: 520px)';
  const lgQuery = '(min-width: 1024px) and (min-height: 520px)';
  const xlQuery = '(min-width: 1280px) and (min-height: 520px)';
  return breakpoint.observe([smQuery, mdQuery, lgQuery, xlQuery]).pipe(
    map((value) => {
      if (value.breakpoints[xlQuery]) {
        return ScreenSize.XL;
      } else if (value.breakpoints[lgQuery]) {
        return ScreenSize.LG;
      } else if (value.breakpoints[mdQuery]) {
        return ScreenSize.MD;
      } else if (value.breakpoints[smQuery]) {
        return ScreenSize.SM;
      }
      return ScreenSize.XS;
    })
  );
}
