import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@shared/directives/directives.module';
import { HTMLDialogComponent } from './html-dialog/html-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { LocalizeErrorModule } from '@shared/pipes/localize-error/localize-error.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmReadTAndCDialogComponent } from './confirm-read-terms-and-conditions-dialog/confirm-read-terms-and-conditions-dialog.component';
import { FormatDatePipeModule } from '@shared/pipes/format-date/format-date.module';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ConfirmReadTAndCDialogComponent,
    HTMLDialogComponent,
    ErrorDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    DirectivesModule,
    TranslateModule,
    LocalizeErrorModule,
    MatCheckboxModule,
    FormatDatePipeModule,
  ],

  exports: [
    ConfirmDialogComponent,
    HTMLDialogComponent,
    ErrorDialogComponent,
    ConfirmReadTAndCDialogComponent,
  ],
})
export class DialogModule {}
