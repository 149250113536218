import { Component } from '@angular/core';
import { mergeMap, tap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';

@Component({
  selector: 'app-lazy-load-fail-dialog',
  templateUrl: './lazy-load-fail-dialog.component.html',
  styleUrls: ['./lazy-load-fail-dialog.component.scss'],
})
export class LazyLoadFailDialogComponent {
  doReload = (): Observable<boolean> => {
    return of(true).pipe(
      tap(() => {
        window.location.reload();
      }),
      mergeMap(() => EMPTY)
    );
  };
}
