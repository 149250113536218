export enum GPPConsumptionType {
  Electricity = 'electricity',
  Waste = 'waste',
  Water = 'water',
}

export const GPPAllConsumptionTypes: GPPConsumptionType[] = [
  GPPConsumptionType.Electricity,
  GPPConsumptionType.Waste,
  GPPConsumptionType.Water,
];
