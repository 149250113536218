import { InjectionToken } from '@angular/core';
import { OperationCode } from '@core/enum';
import { AlertType } from '@model/notifications';

export enum NavMenuSection {
  Top = 'top',
  Communication = 'communication',
  SelfServices = 'self-services',
  Account = 'account',
}

export interface INavMenuItem {
  type: NavMenuItemType.SingleItem;
  route: string;
  section: NavMenuSection;
  svgIcon: string;
  titleKey: string;
  operationCode?: OperationCode | OperationCode[];
  order: number;
  alertType?: AlertType;
  badgeIconStyle?: 'dot' | 'text';
}

export interface IChildNavMenuItem {
  route: string;
  titleKey: string;
  operationCode?: OperationCode | OperationCode[];
  alertType?: AlertType;
  badgeIconStyle?: 'dot' | 'text';
}

export const NAV_MENU_ITEM = new InjectionToken<INavMenuItem>('NAV_MENU_ITEM');

export interface IExpandableNavMenuItem {
  type: NavMenuItemType.ExpandableItem;
  section: NavMenuSection;
  svgIcon: string;
  titleKey: string;
  operationCode?: OperationCode | OperationCode[];
  order: number;
  items: IChildNavMenuItem[];
}

export type NavMenuItem = INavMenuItem | IExpandableNavMenuItem;

export enum NavMenuItemType {
  SingleItem = 'single-item',
  ExpandableItem = 'expandable-item',
}
