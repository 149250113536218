import { IBuilding, ILocalizedString } from '@model/common';

export interface INotificationType {
  code: string;
  description: ILocalizedString;
  labelColour: string;
  order: number;
}

export interface INotificationLink {
  type: NotificationLinkType;
  routerLink: string;
  queryParams: Record<string, string>;
}

export enum NotificationLinkType {
  Circular = 'CIRCULAR',
  FormSubmission = 'FORM_SUBMISSION',
  DebitNote = 'DEBIT_NOTE',
  FacilityMaintenance = 'FACILITY_MAINTENANCE',
  ACRequest = 'AIR_COND_REQUEST',
  GPPBaselineSetting = 'GPP_BASELINE_SETTING',
  GPPAwardGroup = 'GPP_AWARD_GROUP',
  Unknown = '',
}

export type INotificationBuilding = IBuilding;

export interface INotificationLease {
  leaseIdentifier: string;
  shopName: string | null;
  tenantName: string;
  unitName: string;
  portfolioType: string;
}

export interface INotificationSubmissionGroup {
  companyID: number;
  submissionGroupID: number;
  submissionGroupName: string;
}

export interface INotification {
  id: number;
  isRead: boolean;

  buildings: INotificationBuilding[];
  leases: INotificationLease[];
  submissionGroupInfo?: INotificationSubmissionGroup;
  type: INotificationType;
  content: string;

  date: Date;
  linkId: string | null;
  linkType: NotificationLinkType;
}

export enum AlertType {
  SALES = 'SALES',
  BILLING = 'BILLING',
  DOCUMENT = 'DOCUMENT',
  CIRCULAR = 'CIRCULAR',
  FORMS = 'FORMS',
  // FIXME: Use correct gpp alertType key
  GPP = 'GPP',
}
export interface INotificationAlerts {
  [AlertType.SALES]: number;
  [AlertType.BILLING]: number;
  [AlertType.DOCUMENT]: number;
  [AlertType.CIRCULAR]: number;
  [AlertType.FORMS]: number;
  // FIXME: Use correct gpp alertType key
  [AlertType.GPP]: number;
}
