import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { firstValueFrom } from '@core/util/firstValueFrom';
import { SystemService } from '@service/system/system.service';

@Injectable({
  providedIn: 'root',
})
export class SystemStatusGuard implements CanActivate {
  private checked = false;
  constructor(
    private readonly system: SystemService,
    private readonly router: Router
  ) {}

  async canActivate(): Promise<boolean> {
    if (this.checked) {
      return true;
    }

    const status = await firstValueFrom(this.system.getStatus());
    if (status.type === 'MAINTENANCE') {
      this.router.navigateByUrl('pages/system/503', {
        state: { status },
      });
      return false;
    }

    this.checked = true;
    return true;
  }
}
