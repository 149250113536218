export enum OperationCode {
  AccessDocuments = 'TP_OP:rDocument',
  AccessCircular = 'TP_OP:rCircular',
  AccessReadTurnover = 'TP_OP:rTurnoverSales',
  AccessWriteTurnover = 'TP_OP:wTurnoverSales',
  AccessBilling = 'TP_OP:rBilling',
  AccessReadUserManagement = 'TP_OP:rUserManagement',
  AccessWriteUserManagement = 'TP_OP:wUserManagement',
  AccessReadForms = 'TP_OP:rForm',
  AccessWriteForms = 'TP_OP:wForm',
  AccessWriteEForms = 'TP_OP:wEForm',
  AccessReadContacts = 'TP_OP:rContact',
  AccessWriteContacts = 'TP_OP:wContact',
  AccessReadACRequests = 'TP_OP:rACRequest',
  AccessWriteACRequests = 'TP_OP:wACRequest',
  AccessReadDirectorySignage = 'TP_OP:rDirectorySignageAndInformationRequest',
  AccessWriteDirectorySignage = 'TP_OP:wDirectorySignageAndInformationRequest',
  AccessReadVisitorAccess = 'TP_OP:rVisitorAccessCard',
  AccessReadVisitorAccessLeaseFloor = 'TP_OP:rVisitorAccessCardLeaseFloor',
  AccessWriteVisitorAccess = 'TP_OP:wVisitorAccessCard',
  AccessWriteVisitorAccessLeaseFloor = 'TP_OP:wVisitorAccessCardLeaseFloor',
  AccessReadStaffAccess = 'TP_OP:rStaffAccessCard',
  AccessReadStaffAccessLeaseFloor = 'TP_OP:rStaffAccessCardLeaseFloor',
  AccessWriteStaffAccess = 'TP_OP:wStaffAccessCard',
  AccessWriteStaffAccessLeaseFloor = 'TP_OP:wStaffAccessCardLeaseFloor',
  AccessMassWriteStaffAccess = 'TP_OP:wMassStaffAccessCard',
  AccessMassWriteStaffAccessLeaseFloor = 'TP_OP:wMassStaffAccessCardLeaseFloor',
  AccessMassDeleteStaffAccess = 'TP_OP:dMassStaffAccessCard',
  AccessMassDeleteStaffAccessLeaseFloor = 'TP_OP:dMassStaffAccessCardLeaseFloor',
  AccessExportStaffList = 'TP_OP:ExportStaff',
  AccessExportStaffListLeaseFloor = 'TP_OP:ExportStaffLeaseFloor',
  AccessReadStandardFitting = 'TP_OP:rStandardFittingRequest',
  AccessWriteStandardFitting = 'TP_OP:wStandardFittingRequest',
  AccessReadFacilityMaintenance = 'TP_OP:rFacilityMaintenance',
  AccessWriteFacilityMaintenance = 'TP_OP:wFacilityMaintenance',
  AccessReadServiceLift = 'TP_OP:rServiceLiftRequest',
  AccessWriteServiceLift = 'TP_OP:wServiceLiftRequest',
  AccessReadMonthlyCarParking = 'TP_OP:rMonthlyCarParkingRequest',
  AccessWriteMonthlyCarParking = 'TP_OP:wMonthlyCarParkingRequest',
  AccessReadGPPSDProfile = 'TP_OP:rGPPSDProfile',
  AccessWriteGPPSDProfile = 'TP_OP:wGPPSDProfile',
  AcccesExportGPPSDProfile = 'TP_OP:rptGPPSDProfile',
  AccessReadGPPDataCollection = 'TP_OP:rGPPDataCollection',
  AccessWriteGPPDataCollection = 'TP_OP:wGPPDataCollection',
  AccessReadGPPDataDashboardEnergyPerformance = 'TP_OP:rGPPDataDashboardEnergyPerformance',
  AccessReadGPPDataDashboardEnergyBreakdown = 'TP_OP:rGPPDataDashboardEnergyBreakdown',
  AccessReadGPPDataDashboardEnergyTrend = 'TP_OP:rGPPDataDashboardEnergyTrend',
  AccessReadGPPDataDashboardWaterPerformance = 'TP_OP:rGPPDataDashboardWaterPerformance',
  AccessReadGPPDataDashboardWaterTrend = 'TP_OP:rGPPDataDashboardWaterTrend',
  AccessReadGPPDataDashboardWastePerformance = 'TP_OP:rGPPDataDashboardWastePerformance',
  AccessReadGPPDataDashboardWasteBreakdown = 'TP_OP:rGPPDataDashboardWasteBreakdown',
  AccessReadGPPDataDashboardWasteTrend = 'TP_OP:rGPPDataDashboardWasteTrend',
  AccessReadGPPDataDashboard = 'TP_OP:rGPPDataDashboard',
  AccessExportGPPDataDashboard = 'TP_OP:rGPPDataDashboardExport',
  AccessReadGPPUserManangement = 'TP_OP:rGPPUserManagement',
  AccessWriteGPPUserManangement = 'TP_OP:wGPPUserManagement',
  AccessReadGPPBaselineSettings = 'TP_OP:rGPPBaselineSettings',
  AccessWriteGPPBaselineSettings = 'TP_OP:wGPPBaselineSettings',
  AccessReadGPPTierAwards = 'TP_OP:rGPPTierAward',
  AccessWriteGPPTierAwards = 'TP_OP:wGPPTierAward',

  AccessReadACUnitSchedule = 'TP_OP:rACUnitSch',
}
