import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoHideDirective } from './auto-hide.directive';
import { OnHoverShowDirective } from './on-hover-show.directive';
import { FillScrollableDirective } from './fill-scrollable.directive';
import { NumberOnlyDirective } from './number-only.directive';
import { ChildFormDirective } from './child-form.directive';
import { FormControlErrorDirective } from './form-control-error.directive';
import { LoadingButtonDirective } from './loading-button.directive';
import { FormDirective } from './form.directive';
import { HoverTooltipDirective } from './hover-tooltip.directive';
import { HoverTooltipComponent } from './hover-tooltip/hover-tooltip.component';
import { DragFileDirective } from './drag-file.directive';
import { DismissableModalDirective } from './dismissable-modal';
import { MatTooltipDirective } from './mat-tooltip.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FeatureFlagDirective } from './feature-flag.directive';
import { AppMatErrorCaseDirective } from './mat-error-case.directive';
import { AppMatFormFieldDirective } from './mat-form-field.directive';
import { GridCellDirective } from './grid-cell.directive';
import { AppMatOptionSelectPanelPositionDirective } from './mat-option-select-panel-position.directive';
import { DateRangePickerMaxRangeDirective } from './date-range-picker-max-range.directive';

@NgModule({
  declarations: [
    AppMatFormFieldDirective,
    AppMatErrorCaseDirective,
    HoverTooltipDirective,
    OnHoverShowDirective,
    AutoHideDirective,
    FillScrollableDirective,
    NumberOnlyDirective,
    ChildFormDirective,
    FormControlErrorDirective,
    LoadingButtonDirective,
    FormDirective,
    HoverTooltipComponent,
    DragFileDirective,
    DismissableModalDirective,
    MatTooltipDirective,
    FeatureFlagDirective,
    GridCellDirective,
    AppMatOptionSelectPanelPositionDirective,
    DateRangePickerMaxRangeDirective,
  ],
  imports: [CommonModule, MatTooltipModule],
  exports: [
    AppMatFormFieldDirective,
    AppMatErrorCaseDirective,
    HoverTooltipDirective,
    OnHoverShowDirective,
    AutoHideDirective,
    FillScrollableDirective,
    NumberOnlyDirective,
    ChildFormDirective,
    FormControlErrorDirective,
    LoadingButtonDirective,
    FormDirective,
    DragFileDirective,
    DismissableModalDirective,
    MatTooltipDirective,
    FeatureFlagDirective,
    GridCellDirective,
    AppMatOptionSelectPanelPositionDirective,
    DateRangePickerMaxRangeDirective,
  ],
})
export class DirectivesModule {}
