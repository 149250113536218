import { Routes } from '@angular/router';
import { SystemStatusGuard } from './system-status.guard';

export const AuthPageRoutes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: async () =>
      import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [SystemStatusGuard],
    data: {
      requiresUser: false,
    },
  },
  {
    path: 'signup',
    pathMatch: 'full',
    loadChildren: async () =>
      import('./signup/signup.module').then((m) => m.SignUpModule),
    canActivate: [SystemStatusGuard],
    data: {
      requiresUser: false,
    },
  },
  {
    path: 'reset-password',
    pathMatch: 'full',
    loadChildren: async () =>
      import('./reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [SystemStatusGuard],
    data: { requiresUser: false },
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    loadChildren: async () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
    canActivate: [SystemStatusGuard],
    data: { requiresUser: false },
  },
  {
    path: 'register',
    pathMatch: 'full',
    loadChildren: async () =>
      import('./register/register.module').then((m) => m.RegisterModule),
    canActivate: [SystemStatusGuard],
    data: {
      requireUser: false,
    },
  },
];
