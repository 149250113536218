import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@core/util/untilDestroyed';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, filter, switchMap, delay } from 'rxjs/operators';
import { AuthService } from '@service/auth/auth.service';
import { AppTranslateService } from '@shared/services/translate.service';
import { Meta } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '@env/environment';
import { parseUserAgent } from '@shared/utils/user-agent';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { PageHistoryService } from '@shared/services/page-history.service';
import { DOCUMENT } from '@angular/common';
import { IUserManagementUser } from '@service/api/models/user-management';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isMobile: boolean = false;
  env: string = environment.env;
  pageHistorySubscription: Subscription;
  readonly isInitialized$ = new BehaviorSubject(false);
  readonly isNavigating$ = new BehaviorSubject(false);
  readonly isLoading$ = combineLatest([
    this.isInitialized$,
    this.isNavigating$,
  ]).pipe(
    map(([isInitialized, isNavigating]) => !isInitialized || isNavigating)
  );

  constructor(
    private readonly translate: AppTranslateService,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly meta: Meta,
    private readonly deviceDetector: DeviceDetectorService,
    private readonly logoutIdle: Idle,
    private readonly pageHistory: PageHistoryService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isMobile = this.deviceDetector.isMobile();
  }

  ngOnInit(): void {
    this.pageHistorySubscription = this.pageHistory.subscribeEvent();

    this.auth.isInitialized$
      .pipe(untilDestroyed(this))
      .subscribe(this.isInitialized$);

    if (!this.validateBrowserVersion()) {
      this.auth.initialize();
      return;
    }

    if (this.isMobile) {
      this.meta.updateTag(
        {
          content:
            'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no',
          name: 'viewport',
        },
        'name="viewport"'
      );
    }

    if (environment.idleLogout.enabled) {
      this.logoutIdle.setIdleName('logout');
      this.logoutIdle.setIdle(environment.idleLogout.idleSeconds);
      this.logoutIdle.setTimeout(environment.idleLogout.timeoutSeconds);
      this.logoutIdle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.logoutIdle.onTimeout
        .pipe(
          switchMap(() => this.auth.logout()),
          untilDestroyed(this)
        )
        .subscribe();
    }

    this.router.events
      .pipe(
        map((event: Event) => {
          switch (true) {
            case event instanceof NavigationStart:
              return true;

            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError:
              return false;
            default:
              return null;
          }
        }),
        filter((x) => x != null),
        // Prevent routing in onInit from affecting the parent navigation event.
        delay(0),
        untilDestroyed(this)
      )
      .subscribe(this.isNavigating$);

    this.auth.user$
      .pipe(untilDestroyed(this))
      .subscribe((user) => this.onUserChange(user));

    this.auth.initialize();
    this.injectDyntraceScript();
  }

  private validateBrowserVersion(): boolean {
    const ua = parseUserAgent(navigator.userAgent);

    let isSupported = false;
    for (const [browser, version] of Object.entries(
      environment.supportedBrowsers
    )) {
      if (ua.name.indexOf(browser) >= 0 && ua.version >= version) {
        isSupported = true;
        break;
      }
    }
    if (!isSupported) {
      this.router.navigateByUrl('pages/system/unsupported-browser');
      return false;
    }
    return true;
  }

  private onUserChange(user: IUserManagementUser | null): void {
    if (user) {
      this.translate.setLanguage(user.preference.language);
      this.logoutIdle.watch();
    } else {
      this.logoutIdle.stop();
    }
  }

  injectDyntraceScript(): void {
    if (document.querySelector('[data-dtconfig]')) {
      return;
    }

    const script = this.renderer2.createElement('script');
    script.type = `text/javascript`;
    script.crossOrigin = 'anonymous';
    script.src = environment.dynatrace.scriptUri;
    script.dataset.dtconfig = environment.dynatrace.config;

    this.renderer2.appendChild(this.document.body, script);
  }

  ngOnDestroy(): void {
    this.pageHistorySubscription.unsubscribe();
  }
}
