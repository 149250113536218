import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { API_ERROR_HANDLER } from '@core/http/error-handler';
import { APIErrorHandler } from './services/api-error-handler.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [{ provide: API_ERROR_HANDLER, useClass: APIErrorHandler }],
})
export class SharedModule {}
