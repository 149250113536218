import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { clamp } from 'lodash';
import { HoverTooltipComponent } from './hover-tooltip/hover-tooltip.component';

const DEFAULT_HOVER_TIMEOUT = 500;
const ELEMENT_OFFSET = 8;

@Directive({
  selector: '[appHoverTooltip]',
})
export class HoverTooltipDirective implements OnDestroy {
  private readonly tooltipFactory: ComponentFactory<HoverTooltipComponent>;

  @Input('appHoverTooltip')
  content: TemplateRef<unknown> | string | null = null;

  @Input('appHoverTooltipContext')
  context: any;

  @Input('appHoverTooltipMode')
  mode: 'when-overflow' | 'always' | 'never' | undefined;

  @Input()
  hoverTimeout = DEFAULT_HOVER_TIMEOUT;

  constructor(
    private readonly elemRef: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.tooltipFactory = this.componentFactoryResolver.resolveComponentFactory(
      HoverTooltipComponent
    );
  }

  private timeout: any;
  private x = 0;
  private y = 0;
  private componentRef: ComponentRef<HoverTooltipComponent>;

  ngOnDestroy(): void {
    this._cleanupElement();
  }

  private _hideElement(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  private _isOverflow(): boolean {
    const elem = this.elemRef.nativeElement;
    return (
      elem.scrollHeight > elem.clientHeight ||
      elem.scrollWidth > elem.clientWidth
    );
  }

  private shouldShowTooltip() {
    const mode = this.mode ?? (this.content ? 'always' : 'when-overflow');
    if (mode === 'always') {
      return true;
    }
    if (mode === 'never') {
      return false;
    }
    return this._isOverflow();
  }

  private _onMouseHover(): void {
    if (!this.shouldShowTooltip()) {
      return;
    }

    this._hideElement();

    this.componentRef = this.viewContainerRef.createComponent(
      this.tooltipFactory
    );
    const tooltipElem: HTMLElement = this.componentRef.location.nativeElement;
    const content = this.content || this.elemRef.nativeElement.innerText;
    if (typeof content === 'string' || content == null) {
      this.componentRef.instance.value = content;
    } else {
      this.componentRef.instance.contentTemplateContext = this.context;
      this.componentRef.instance.contentTemplate = content;
    }
    this.componentRef.changeDetectorRef.detectChanges();
    document.body.appendChild(tooltipElem);

    const x = clamp(
      this.x - ELEMENT_OFFSET,
      ELEMENT_OFFSET,
      window.innerWidth - tooltipElem.clientWidth - ELEMENT_OFFSET
    );
    const y = clamp(
      this.y + ELEMENT_OFFSET,
      ELEMENT_OFFSET,
      window.innerHeight - tooltipElem.clientHeight - ELEMENT_OFFSET
    );
    this.componentRef.instance.left = `${x}px`;
    this.componentRef.instance.top = `${y}px`;
    this.componentRef.changeDetectorRef.detectChanges();
  }

  private _scheduleHover(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => this._onMouseHover(), this.hoverTimeout);
  }

  private _cleanupElement() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    this._hideElement();
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(e: MouseEvent): void {
    this._storeCurrentCursorPosition(e);
    this._scheduleHover();
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(): void {
    this._cleanupElement();
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(e: MouseEvent): void {
    this._storeCurrentCursorPosition(e);
    this._scheduleHover();
  }

  private _storeCurrentCursorPosition(e: MouseEvent): void {
    this.x = e.clientX;
    this.y = e.clientY;
  }
}
