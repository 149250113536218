import { InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

export interface IShellDisplay {
  route: string;

  navTitleSvgIcon: string | null;
  navTitle$?: Observable<string>;
  navTitleKey: string | null;

  fullScreen?: boolean;
  showFullBackground?: boolean;

  hideNavBar$?: Observable<boolean>;
  matchAs?: (router: Router) => string;
}

export const SHELL_DISPLAY = new InjectionToken<IShellDisplay>('SHELL_DISPLAY');
