import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OperationCode } from '@core/enum';
import { or } from '@core/util/opCodeExpression';
import { AuthPageRoutes } from '@pages/auth/routes';
import { ErrorPageModule } from '@pages/system/error-page/error-page.module';
import { SystemPageRoutes } from '@pages/system/routes';
import { AuthGuard } from '@service/auth/guard/auth.guard';

import { ErrorShell } from './shells/error/error.service';
import { Shell } from './shells/shell/shell.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/pages/home',
  },
  {
    path: 'pages',
    canActivateChild: [AuthGuard],
    children: [
      ...AuthPageRoutes,
      {
        path: 'system',
        children: [ErrorShell.childRoutes(SystemPageRoutes)],
      },
      {
        path: 'terms',
        loadChildren: async () =>
          import('@pages/terms/terms-routing.module').then(
            (m) => m.TermsRoutingModule
          ),
      },
      Shell.childRoutes([
        {
          path: 'turnover-sales',
          loadChildren: async () =>
            import('@pages/turnover/turnover-routing.module').then(
              (m) => m.TurnoverRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessReadTurnover,
          },
        },
        {
          path: 'billing',
          loadChildren: async () =>
            import('@pages/billing/billing-routing.module').then(
              (m) => m.BillingRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessBilling,
          },
        },
        {
          path: 'circulars',
          loadChildren: async () =>
            import('@pages/circular/circular-routing.module').then(
              (m) => m.CircularRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessCircular,
          },
        },
        {
          path: 'documents',
          loadChildren: async () =>
            import('@pages/document/document-routing.module').then(
              (m) => m.DocumentRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessDocuments,
          },
        },
        {
          path: 'notifications',
          loadChildren: async () =>
            import('@pages/notification/notification-routing.module').then(
              (m) => m.NotificationRoutingModule
            ),
          data: {
            requiresUser: true,
          },
        },
        {
          path: 'home',
          loadChildren: async () =>
            import('@pages/home/home-routing.module').then(
              (m) => m.HomeRoutingModule
            ),
          data: {
            requiresUser: true,
          },
        },
        {
          path: 'otp',
          loadChildren: async () =>
            import('@pages/otp/otp-routing.module').then(
              (m) => m.OTPRoutingModule
            ),
          data: {
            requiresUser: true,
          },
        },
        {
          path: 'profile',
          loadChildren: async () =>
            import('@pages/profile/profile-routing.module').then(
              (m) => m.ProfileRoutingModule
            ),
          data: {
            requiresUser: true,
          },
        },
        {
          path: 'user-management',
          loadChildren: async () =>
            import(
              '@pages/user-management/user-management-routing.module'
            ).then((m) => m.UserManagementRoutingModule),
          data: {
            requiresUser: true,
            requiresOpCode: or(
              OperationCode.AccessReadUserManagement,
              OperationCode.AccessReadGPPUserManangement
            ),
          },
        },
        {
          path: 'forms',
          loadChildren: async () =>
            import('@pages/forms/forms-routing.module').then(
              (m) => m.FormsRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: or(
              OperationCode.AccessReadForms,
              OperationCode.AccessWriteACRequests,
              OperationCode.AccessWriteFacilityMaintenance,
              OperationCode.AccessWriteStaffAccess,
              OperationCode.AccessWriteStaffAccessLeaseFloor,
              OperationCode.AccessWriteStandardFitting,
              OperationCode.AccessWriteVisitorAccess,
              OperationCode.AccessWriteVisitorAccessLeaseFloor
            ),
          },
        },
        {
          path: 'contacts',
          loadChildren: async () =>
            import('@pages/contacts/contacts-routing.module').then(
              (m) => m.ContactsRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessReadContacts,
          },
        },
        {
          path: 'ac-request',
          loadChildren: async () =>
            import('@pages/ac-request/ac-request-routing.module').then(
              (m) => m.AcRequestRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessReadACRequests,
          },
        },
        {
          path: 'submission-history',
          loadChildren: async () =>
            import(
              '@pages/submission-history/submission-history-routing.module'
            ).then((m) => m.SubmissionHisoryRoutingModule),
          data: {
            requiresUser: true,
          },
        },
        {
          path: 'visitor-access',
          loadChildren: async () =>
            import('@pages/visitor-access/visitor-access-routing.module').then(
              (m) => m.VisitorAccessRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: or(
              OperationCode.AccessReadVisitorAccess,
              OperationCode.AccessReadVisitorAccessLeaseFloor
            ),
          },
        },
        {
          path: 'staff-access',
          loadChildren: async () =>
            import('@pages/staff-access/staff-access-routing.module').then(
              (m) => m.StaffAccessRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: or(
              OperationCode.AccessReadStaffAccess,
              OperationCode.AccessReadStaffAccessLeaseFloor
            ),
          },
        },
        {
          path: 'standard-fitting',
          loadChildren: async () =>
            import(
              '@pages/standard-fitting/standard-fitting-routing.module'
            ).then((m) => m.StandardFittingRoutingModule),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessReadStandardFitting,
          },
        },
        {
          path: 'service-lift',
          loadChildren: async () =>
            import('@pages/service-lift/service-lift-routing.module').then(
              (m) => m.ServiceLiftRoutingModule
            ),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessReadServiceLift,
          },
        },
        {
          path: 'monthly-car-parking',
          loadChildren: async () =>
            import(
              '@pages/monthly-car-parking/monthly-car-parking-routing.module'
            ).then((m) => m.MonthlyCarParkingRoutingModule),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessReadMonthlyCarParking,
          },
        },
        {
          path: 'directory-signage',
          loadChildren: async () =>
            import(
              '@pages/directory-signage/directory-signage-routing.module'
            ).then((m) => m.DirectorySignageRoutingModule),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessReadDirectorySignage,
          },
        },
        {
          path: 'facility-maintenance',
          loadChildren: async () =>
            import(
              '@pages/facility-maintenance/facility-maintenance-routing.module'
            ).then((m) => m.FacilityMaintenanceRoutingModule),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessReadFacilityMaintenance,
          },
        },
        {
          path: 'gpp',
          loadChildren: async () =>
            import(
              '@pages/green-performance-pledge/green-performance-pledge-routing.module'
            ).then((m) => m.GreenPerformancePledgeRoutingModule),
          data: {
            requiresUser: true,
            requiresOpCode: OperationCode.AccessReadGPPDataCollection,
          },
        },
        {
          path: '**',
          redirectTo: '/pages/system/404',
        },
      ]),
    ],
  },
  { path: '**', redirectTo: '/pages/home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
    }),
    ErrorPageModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
