import { Injectable } from '@angular/core';
import { ApiClient } from '@core/http/api-client';
import { IBuilding } from '@model/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAPILease, IListResponse } from './models/common';
import {
  IAPIForm,
  IAPIFormCategory,
  IAPIFormSubmission,
  IAPIFormSubmissionStatus,
  IAPIFormSubmissionRequest,
  IAPIFormSubmissionQuery,
  IAPIFormUploadUrl,
} from './models/forms-v2';
import { mapAPIForm, mapAPISubmission } from './mappers/forms-v2-api';
import { mapAPILease, mapIListResponse } from './mappers/common-api';
import { createHttpParams } from './utils/params';

@Injectable({
  providedIn: 'root',
})
export class FormsV2APIService {
  constructor(private readonly http: ApiClient) {}

  public getForms(): Observable<IAPIForm[]> {
    return this.http
      .get<IListResponse<any>>('v2/forms')
      .pipe(map((resp) => resp.list.map(mapAPIForm)));
  }

  public getBuildings(): Observable<IBuilding[]> {
    return this.http.get<IListResponse<any>>('v2/forms/buildings').pipe(
      map((resp) =>
        resp.list.map(
          (item): IBuilding => ({
            id: item.buildingID,
            name: item.localBuildingName,
          })
        )
      )
    );
  }

  public getCategories(): Observable<IAPIFormCategory[]> {
    return this.http.get<IListResponse<any>>('v2/forms/categories').pipe(
      map((resp) =>
        resp.list.map(
          (item): IAPIFormCategory => ({
            code: item.code,
            description: {
              enUS: item.description,
              zhHant: item.descriptionZhHK,
              zhHans: item.descriptionZhCN,
            },
          })
        )
      )
    );
  }

  public getFormLeases(id: number): Observable<IAPILease[]> {
    return this.http
      .get<IListResponse<any>>(`v2/forms/${id}/leases`)
      .pipe(map((resp) => resp.list.map(mapAPILease)));
  }

  public getLeases(): Observable<IAPILease[]> {
    return this.http
      .get<IListResponse<any>>(`v2/forms/leases`)
      .pipe(map((resp) => resp.list.map(mapAPILease)));
  }

  public setStar(id: number, starred: boolean): Observable<boolean> {
    return this.http
      .put(`v2/forms/${id}/set-star`, {
        formID: id,
        starred,
      })
      .pipe(map((resp: any) => Boolean(resp.starred)));
  }

  public getStatuses(): Observable<IAPIFormSubmissionStatus[]> {
    return this.http
      .get<IListResponse<any>>(`v2/forms/submissions/status`)
      .pipe(
        map((resp) =>
          resp.list.map(
            (item): IAPIFormSubmissionStatus => ({
              code: item.code,
              description: {
                enUS: item.description,
                zhHant: item.descriptionZhHK,
                zhHans: item.descriptionZhCN,
              },
              labelColour: item.labelColour,
            })
          )
        )
      );
  }

  public getSubmissions(
    query: IAPIFormSubmissionQuery
  ): Observable<IListResponse<IAPIFormSubmission>> {
    return this.http
      .get<IListResponse<any>>('v2/forms/submissions', {
        params: createHttpParams({
          ...query,
          buildingIDs: query.buildingIDs?.join(','),
          leaseIdentifiers: query.leaseIdentifiers?.join(','),
          searchFields: query.searchFields?.join(','),
          page: query.page ? (query.page - 1).toString() : undefined,
          limit: query.limit?.toString(),
        }),
      })
      .pipe(map((resp) => mapIListResponse(resp, mapAPISubmission)));
  }

  public getSubmission(id: number): Observable<IAPIFormSubmission> {
    return this.http
      .get<IAPIFormSubmission>(`v2/forms/submissions/${id}`)
      .pipe(map(mapAPISubmission));
  }

  public getSubmissionFileDownloadUrl(
    submissionID: number,
    fileID: number
  ): Observable<string> {
    return this.http
      .get<any>(
        `v2/forms/submissions/${submissionID}/files/${fileID}/download-url`
      )
      .pipe(map((resp) => resp.url));
  }

  public submitForm(
    formID: number,
    submission: IAPIFormSubmissionRequest
  ): Observable<IAPIFormSubmission> {
    return this.http
      .post<any>(`v2/forms/${formID}/submissions`, submission)
      .pipe(map(mapAPISubmission));
  }

  public getUploadUrl(
    fileName: string,
    contentType: string
  ): Observable<IAPIFormUploadUrl> {
    return this.http
      .post<any>('v2/forms/submissions/upload-url', { fileName, contentType })
      .pipe(map((resp) => ({ url: resp.url, objectKey: resp.objectKey })));
  }

  public getDownloadUrl(objectKey: string): Observable<string> {
    return this.http
      .get<any>('v2/forms/submissions/download-url', { params: { objectKey } })
      .pipe(map((resp) => resp.url));
  }
}
