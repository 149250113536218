import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { of } from 'rxjs';

const submitShellDisplay: IShellDisplay = {
  route: '/pages/visitor-access/create',
  navTitleKey: l10n('VISITOR_ACCESS.CREATE.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:pencil-write-2',
  hideNavBar$: of(true),
};

const editShellDisplay: IShellDisplay = {
  route: '/pages/visitor-access/edit',
  navTitleKey: l10n('VISITOR_ACCESS.EDIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:pencil-write-2',
  hideNavBar$: of(true),
};

const cloneShellDisplay: IShellDisplay = {
  route: '/pages/visitor-access/clone',
  navTitleKey: l10n('VISITOR_ACCESS.CLONE.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:pencil-write-2',
  hideNavBar$: of(true),
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: submitShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: editShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: cloneShellDisplay, multi: true },
  ],
})
export class VisitorAccessMetadataModule {}
