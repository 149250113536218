import { NgModule } from '@angular/core';
import {
  IChildNavMenuItem,
  NavMenuItem,
  NavMenuItemType,
  NavMenuSection,
  NAV_MENU_ITEM,
} from '@shared/metadata/INavMenuItem';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { OperationCode } from '@core/enum';
import { AlertType } from '@model/notifications';
import { MatIconModule } from '@angular/material/icon';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';

const newRequestNavMenuItem: IChildNavMenuItem = {
  route: '/pages/forms',
  titleKey: l10n('REQUESTS.NAV_TITLE.NEW_REQUESTS'),
  operationCode: [
    OperationCode.AccessWriteACRequests,
    OperationCode.AccessWriteEForms,
    OperationCode.AccessWriteFacilityMaintenance,
    OperationCode.AccessWriteForms,
    OperationCode.AccessReadForms,
    OperationCode.AccessWriteStaffAccess,
    OperationCode.AccessWriteStaffAccessLeaseFloor,
    OperationCode.AccessWriteStandardFitting,
    OperationCode.AccessWriteVisitorAccess,
    OperationCode.AccessWriteVisitorAccessLeaseFloor,
  ],
  alertType: AlertType.FORMS,
};
const requestHistoryNavMenuItem: IChildNavMenuItem = {
  route: '/pages/submission-history',
  titleKey: l10n('REQUESTS.NAV_TITLE.REQUEST_SUBMISSIONS'),
  operationCode: [
    OperationCode.AccessReadVisitorAccess,
    OperationCode.AccessReadVisitorAccessLeaseFloor,
    OperationCode.AccessReadStaffAccess,
    OperationCode.AccessReadStaffAccessLeaseFloor,
    OperationCode.AccessReadACRequests,
    OperationCode.AccessReadStandardFitting,
    OperationCode.AccessReadForms,
    OperationCode.AccessReadFacilityMaintenance,
  ],
};

const requestNavItem: NavMenuItem = {
  type: NavMenuItemType.ExpandableItem,
  section: NavMenuSection.SelfServices,
  svgIcon: 'streamline:paper-write',
  titleKey: l10n('REQUESTS.NAV_TITLE.INDEX'),
  items: [newRequestNavMenuItem, requestHistoryNavMenuItem],
  operationCode: [
    OperationCode.AccessReadVisitorAccess,
    OperationCode.AccessReadVisitorAccessLeaseFloor,
    OperationCode.AccessWriteVisitorAccess,
    OperationCode.AccessWriteVisitorAccessLeaseFloor,
    OperationCode.AccessReadStaffAccess,
    OperationCode.AccessReadStaffAccessLeaseFloor,
    OperationCode.AccessWriteStaffAccess,
    OperationCode.AccessWriteStaffAccessLeaseFloor,
    OperationCode.AccessReadACRequests,
    OperationCode.AccessWriteACRequests,
    OperationCode.AccessReadStandardFitting,
    OperationCode.AccessWriteStandardFitting,
    OperationCode.AccessReadFacilityMaintenance,
    OperationCode.AccessWriteFacilityMaintenance,
    OperationCode.AccessReadForms,
    OperationCode.AccessWriteForms,
    OperationCode.AccessWriteEForms,
  ],
  order: 30,
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: NAV_MENU_ITEM, useValue: requestNavItem, multi: true },
  ],
})
export class RequestsMetadataModule {}
