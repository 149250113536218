import { IAPICodeDescription } from '@service/mappers/common';

export interface IAPIServiceLiftRequestOperatingHour {
  buildingID: number;
  dayOfWeek: string;
  startTime: string;
  endTime: string;
}

export type APIEFormSubmissionDetail =
  | IAPIEFormSubmissionDetailGeneric
  | IAPIEFormSubmissionDetailUnknown
  | IAPIEFormSubmissionDetailServiceLiftBooking
  | IAPIEFormSubmissionDetailMonthlyCarParking;

export interface IAPIEFormSubmissionDetailServiceLiftBooking {
  submissionType: 'SERVICE_LIFT_REQUEST';

  firstName: string;
  lastName: string;
  contactNo: string;
  from: string;
  to: string;
  transportationCompany: string;
  inOut: ('IN' | 'OUT')[];
  specialRequirements: string;
}

export interface IAPIEFormSubmissionDetailGeneric {
  submissionType: 'GENERIC';
}

export interface IAPIEFormSubmissionDetailUnknown {
  submissionType: 'UNKNOWN';
}

export interface IAPIEFormSubmissionDetailMonthlyCarParking {
  submissionType: 'MONTHLY_CAR_PARKING_REQUEST';

  firstName: string;
  lastName: string;
  contactNo: string;

  parkingSpaces: IAPIEFormSubmissionDetailMonthlyCarParkingParkingSpace[];
}

export interface IAPIEFormSubmissionDetailMonthlyCarParkingParkingSpaceDetail {
  userName: string;
  gender: IAPICodeDescription;
  userType: IAPICodeDescription;
  carPlateNo: string;
  electricVehicle: boolean;
  electricVehicleModel: string;
  octopusCardNo: string;
}

export interface IAPIEFormSubmissionDetailMonthlyCarParkingParkingSpace {
  effectiveDate: Date;
  parkingSpaceType: IAPICodeDescription;
  optInToContactlessParkingSystem: boolean;
  parkingSpaceDetails: IAPIEFormSubmissionDetailMonthlyCarParkingParkingSpaceDetail[];
  remarks?: string;
}

export enum APIDayOfWeek {
  MONDAYS = 'MONDAYS',
  TUESDAYS = 'TUESDAYS',
  WEDNESDAYS = 'WEDNESDAYS',
  THURSDAYS = 'THURSDAYS',
  FRIDAYS = 'FRIDAYS',
  SATURDAYS = 'SATURDAYS',
  SUNDAYS = 'SUNDAYS',
  PUBLIC_HOLIDAYS = 'PUBLIC_HOLIDAYS',
  MONDAYS_TO_FRIDAYS = 'MONDAYS_TO_FRIDAYS',
}
