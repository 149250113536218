import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import {
  NavMenuItem,
  NavMenuItemType,
  NavMenuSection,
  NAV_MENU_ITEM,
} from '@shared/metadata/INavMenuItem';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { of } from 'rxjs';
import { OperationCode } from '@core/enum';
import { AlertType } from '@model/notifications';

const indexShellDisplay: IShellDisplay = {
  route: '/pages/turnover-sales',
  navTitleKey: l10n('TURNOVER_SALES.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:common-file-text-upload',
};

const submitAuditedSalesShellDisplay: IShellDisplay = {
  route: '/pages/turnover-sales/submit/audited',
  navTitleKey: l10n('TURNOVER_SALES.SUBMISSION.AUDITED.TITLE'),
  navTitleSvgIcon: 'streamline:multiple-neutral-1',
  hideNavBar$: of(true),
};

const submitUnauditedSalesShellDisplay: IShellDisplay = {
  route: '/pages/turnover-sales/submit/unaudited',
  navTitleKey: l10n('TURNOVER_SALES.SUBMISSION.UNAUDITED.TITLE'),
  navTitleSvgIcon: 'streamline:multiple-neutral-1',
  hideNavBar$: of(true),
};

const navMenuItem: NavMenuItem = {
  type: NavMenuItemType.SingleItem,
  route: '/pages/turnover-sales',
  section: NavMenuSection.SelfServices,
  titleKey: l10n('TURNOVER_SALES.NAV_TITLE'),
  svgIcon: 'streamline:common-file-text-upload',
  operationCode: OperationCode.AccessReadTurnover,
  alertType: AlertType.SALES,
  badgeIconStyle: 'dot',
  order: 20,
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: indexShellDisplay, multi: true },
    {
      provide: SHELL_DISPLAY,
      useValue: submitAuditedSalesShellDisplay,
      multi: true,
    },
    {
      provide: SHELL_DISPLAY,
      useValue: submitUnauditedSalesShellDisplay,
      multi: true,
    },
    { provide: NAV_MENU_ITEM, useValue: navMenuItem, multi: true },
  ],
})
export class TurnoverMetadataModule {}
