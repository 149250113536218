import { SupportedLanguage } from '@core/enum';
import { ITypedPhone } from '@model/common';
import { IUserSupportContact, IUserSupportContactType } from '@model/user';
import { IBuildingDefaultFloor } from '@model/user/IBuildingDefaultFloor';
import { IBuildingFloor } from '@model/user/IBuildingFloor';
import { ILeaseFloor } from '@model/user/ILeaseFloor';
import {
  APILeaseFloor,
  IAPIUserPhone,
  IAPIUserSupportContact,
} from '@service/api/models/auth';
import {
  APIBuildingFloor,
  APIBuildingFloorDefaultFloor,
} from '@service/api/models/common';
import { parsePhone } from '@shared/utils/phone';

export function mapSupportedLanguage(
  language: SupportedLanguage
): 'en' | 'zh_hk' | 'zh_cn' {
  switch (language) {
    case SupportedLanguage.EN:
      return 'en';
    case SupportedLanguage.ZH_HANS:
      return 'zh_cn';
    case SupportedLanguage.ZH_HANT_HK:
      return 'zh_hk';
    default:
      throw Error('Unsupported language');
  }
}

export function mapSupportContact(
  support: IAPIUserSupportContact,
  contactTypes: IUserSupportContactType[]
): IUserSupportContact {
  const type = contactTypes.find((ct) => ct.code === support.contactType);
  const phone = parsePhone(support.telNo);
  return {
    buildingId: support.buildingID,
    contactType: type,
    phone: phone ?? null,
    email: support.email,
  };
}

export function mapUserPhone(p: IAPIUserPhone): ITypedPhone {
  const parsedPhoneNumber = parsePhone(p.telNo);

  return {
    phoneType: p.phoneType,
    areaCode: parsedPhoneNumber.areaCode,
    phoneNumber: parsedPhoneNumber.phoneNumber,
  };
}

export function mapLeaseFloor(dto: APILeaseFloor): ILeaseFloor {
  return {
    roleCode: dto.rodeCode,
    leaseIdentifier: dto.leaseIdentifier,
    buildingFloors: dto.buildingFloors.map(mapBuildingFloor),
  };
}

export function mapBuildingFloor(dto: APIBuildingFloor): IBuildingFloor {
  return {
    id: dto.buildingFloorID,
    buildingID: dto.buildingID,
    defaultFloor: dto.defaultFloor ? mapDefaultFloor(dto.defaultFloor) : null,
    name: dto.floor,
    sequence: dto.floorSequence,
    active: dto.active,
  };
}

export function mapDefaultFloor(
  dto: APIBuildingFloorDefaultFloor
): IBuildingDefaultFloor {
  return {
    id: dto.buildingFloorID,
    name: dto.floor,
    sequence: dto.floorSequence,
  };
}
