import * as t from 'io-ts';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { optional, nullable } from './iots';

export const APIAuditableCodec = t.type({
  createdById: t.string,
  createdByName: t.string,
  createdDate: optional(t.string),
  lastUpdatedById: optional(t.string),
  lastUpdatedByName: optional(t.string),
  lastUpdatedDate: optional(t.string),
});
export type IAPIAuditable = t.TypeOf<typeof APIAuditableCodec>;

export interface IListResponse<T> {
  list: T[];
  count: number;
  limit: number;
  page: number;
}

export interface IAPICategory {
  code: string;
  description: string;
  descriptionZhHK: string;
  descriptionZhCN: string;
  sequence: number;
  labelColour: string;
}

export interface IAPIBuilding {
  buildingID: number;
  localBuildingName: string;
}

export interface IAPIEditableBuilding extends IAPIBuilding {
  isEditable: boolean;
}

export interface IAPILease {
  leaseID?: number; // NOTE: leaseID is not present in user related APIs
  leaseIdentifier: string;
  leaseNo: string;
  building: IAPIBuilding;
  currentShopList: string | null;
  currentTenantName: string;
  unitName: string;
  portfolioType: string;
  tradeCategoryGroupID: number | null;
  status: string;
  editable?: boolean; // NOTE: editable is only meaningful in some APIs
  additionalInfo?: { carParkDescription: string | null };
}

export interface IAPILeaseWithPeriod extends IAPILease {
  commencementDate: Date;
  expiryDate: Date;
}

export enum APISupportedLanguage {
  EN = 'en',
  ZH_HANT_HK = 'zh_hk',
  ZH_HANS = 'zh_cn',
}

export type IGetBuildingsResponse = IListResponse<IAPIBuilding>;

export interface IOperationResponse {
  success: boolean;
  message: string | null;
}

export interface IAPISubmissionLeaseAssociation {
  leaseIdentifier: string;
}

export enum APIFieldState {
  HIDDEN = 'HIDDEN',
  MANDATORY = 'MANDATORY',
  DISPLAY = 'DISPLAY',
}

export const APIFileCodec = t.type({
  fileName: t.string,
  fileExtension: t.string,
  fileSize: t.number,
  objectKey: t.string,
  downloadURL: t.string,
});
export type IAPIFile = t.TypeOf<typeof APIFileCodec>;

export interface IAPIPresignedURL {
  url: string;
  objectKey: string;
}

export const APIExportDownloadStatusCodec = t.partial({
  processID: t.string,
  errorCode: optional(nullable(t.string)),
  isFinished: t.boolean,
  objectKey: optional(nullable(t.string)),
  isAborted: t.boolean,
});
export type IAPIExportDownloadStatus = t.TypeOf<
  typeof APIExportDownloadStatusCodec
>;

export const APILocalizableStringCodec = t.partial({
  enUS: optional(nullable(t.string)),
  zhHans: optional(nullable(t.string)),
  zhHant: optional(nullable(t.string)),
});
export type APILocalizableString = t.TypeOf<typeof APILocalizableStringCodec>;

export interface APICodeDescriptionDTO {
  codeKey: string | null;
  code: string;
  color: string | null;
  description: APILocalizableString;
  sequence: number;
  default: boolean;
  isActive: boolean;
}

const APICodeDescriptionDTO1Codec = t.type({
  codeKey: t.string,
  code: t.string,
  description: APILocalizableStringCodec,
  color: nullable(t.string),
  isActive: nullable(t.boolean),
});
const APICodeDescriptionDTO2Codec = t.type({
  codeKey: optional(nullable(t.string)),
  code: nullable(t.string),
  description: optional(nullable(t.string)),
  descriptionZhCN: optional(nullable(t.string)),
  descriptionZhHK: optional(nullable(t.string)),
  labelColour: nullable(t.string),
  active: nullable(t.boolean),
  sequence: nullable(t.number),
});
export const APICodeDescriptionDTOCodec = new t.Type<
  APICodeDescriptionDTO,
  string
>(
  'APICodeDescriptionDTOCodec',
  (u): u is APICodeDescriptionDTO => {
    return (
      typeof (u as any).code === 'string' &&
      typeof (u as any).codeKey === 'string' &&
      APILocalizableStringCodec.is((u as any).description)
    );
  },
  (u) => {
    const DTO1DecodePipe = pipe(
      APICodeDescriptionDTO1Codec.decode(u),
      E.map((cd) => ({
        codeKey: cd.codeKey,
        code: cd.code,
        color: cd.color,
        description: cd.description,
        sequence: 0,
        default: true,
        isActive: cd.isActive ?? true,
      }))
    );
    const DTO2DecodePipe = pipe(
      APICodeDescriptionDTO2Codec.decode(u),
      E.map((cd) => ({
        codeKey: cd.codeKey ?? null,
        code: cd.code,
        color: cd.labelColour,
        description: {
          enUS: cd.description,
          zhHans: cd.descriptionZhCN ?? cd.description,
          zhHant: cd.descriptionZhHK ?? cd.description,
        },
        isActive: cd.active ?? true,
        sequence: cd.sequence ?? 0,
        default: true,
      }))
    );
    return pipe(
      DTO1DecodePipe,
      E.alt(() => DTO2DecodePipe)
    );
  },
  (c) => JSON.stringify(c)
);

export const APIBuildingFloorDefaultFloorCodec = t.type({
  buildingFloorID: t.number,
  floorSequence: t.number,
  floor: t.string,
});
export type APIBuildingFloorDefaultFloor = t.TypeOf<
  typeof APIBuildingFloorDefaultFloorCodec
>;

export const APIBuildingFloorCodec = t.type({
  buildingID: t.number,
  buildingFloorID: t.number,
  floorSequence: t.number,
  floor: t.string,
  defaultFloor: nullable(APIBuildingFloorDefaultFloorCodec),
  active: t.boolean,
});
export type APIBuildingFloor = t.TypeOf<typeof APIBuildingFloorCodec>;

export const APIBuildingFacilityDTOCodec = t.type({
  facilityType: t.string,
  description: t.string,
  descriptionZhHK: t.string,
  descriptionZhCN: t.string,
  sequence: t.number,
  status: t.string,
});
export type APIBuildingFacilityDTO = t.TypeOf<
  typeof APIBuildingFacilityDTOCodec
>;

export const APIBuildingCommonFacilityDTOCodec = t.type({
  buildingCommonFacilityID: t.number,
  status: t.string,
  facilityFloors: t.array(t.number),
  applicableAccessFloorIDs: t.array(t.number),
  buildingFacility: APIBuildingFacilityDTOCodec,
  defaultAutoAssign: t.boolean,
  specificFloorsForAutoAssign: t.array(t.number),
  version: t.number,
});
export type APIBuildingCommonFacilityDTO = t.TypeOf<
  typeof APIBuildingCommonFacilityDTOCodec
>;
