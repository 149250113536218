import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { of } from 'rxjs';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';

const submitShellDisplay: IShellDisplay = {
  route: '/pages/monthly-car-parking/submit',
  navTitleKey: l10n('MONTHLY_CAR_PARKING.SUBMIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:pencil-write-2',
  hideNavBar$: of(true),
};

const editShellDisplay: IShellDisplay = {
  route: '/pages/monthly-car-parking/:submissionID/edit',
  navTitleKey: l10n('MONTHLY_CAR_PARKING.EDIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:pencil-write-2',
  hideNavBar$: of(true),
};

const viewShellDisplay: IShellDisplay = {
  route: '/pages/monthly-car-parking/:submissionID',
  navTitleKey: l10n('MONTHLY_CAR_PARKING.VIEW.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:common-file-text-edit',
  hideNavBar$: of(true),
};

@NgModule({
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: submitShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: editShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: viewShellDisplay, multi: true },
  ],
})
export class MonthlyCarParkingMetadataModule {}
