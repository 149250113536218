import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import {
  NavMenuItem,
  NavMenuItemType,
  NavMenuSection,
  NAV_MENU_ITEM,
} from '@shared/metadata/INavMenuItem';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { OperationCode } from '@core/enum';
import { AlertType } from '@model/notifications';

const shellDisplay: IShellDisplay = {
  route: '/pages/documents',
  navTitleKey: l10n('DOCUMENT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:paragraph-bullets',
};

const navMenuItem: NavMenuItem = {
  type: NavMenuItemType.SingleItem,
  route: '/pages/documents',
  section: NavMenuSection.Communication,
  titleKey: l10n('DOCUMENT.NAV_TITLE'),
  svgIcon: 'streamline:paragraph-bullets',
  operationCode: OperationCode.AccessDocuments,
  alertType: AlertType.DOCUMENT,
  order: 200,
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: shellDisplay, multi: true },
    { provide: NAV_MENU_ITEM, useValue: navMenuItem, multi: true },
  ],
})
export class DocumentMetadataModule {}
