import { HttpEvent } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const API_ERROR_HANDLER = new InjectionToken<IAPIErrorHandler>(
  'API_ERROR_HANDLER'
);

export interface IAPIErrorHandler {
  handleError(stream: Observable<HttpEvent<any>>): Observable<HttpEvent<any>>;
}
