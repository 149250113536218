import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, groupBy, map, mergeMap, switchMap } from 'rxjs/operators';
import { logError } from './log';

type OperationResult<K, V> =
  | { ok: true; key: K; value: V }
  | { ok: false; key: K };

export class OptimisticState<K, V> {
  private commitedState: Map<K, V>;
  private readonly subscription: Subscription;
  private readonly uncommitedState = new Map<K, V>();
  private readonly operation$ = new Subject<[K, V]>();
  private readonly stateChange = new Subject<K>();
  private readonly reset$ = new Subject<void>();

  get stateChange$(): Observable<K> {
    return this.stateChange;
  }

  constructor(
    initialState: Map<K, V>,
    operation: (key: K, value: V) => Observable<V>
  ) {
    this.commitedState = new Map(initialState);

    const op = (key: K, value: V): Observable<OperationResult<K, V>> =>
      operation(key, value).pipe(
        map((v) => ({ ok: true, key, value: v } as const)),
        catchError((error) => {
          logError(error);
          return of({ ok: false, key } as const);
        })
      );

    this.subscription = this.reset$
      .pipe(
        switchMap(() =>
          this.operation$.pipe(
            groupBy(([key]) => key),
            mergeMap((keyOps$) => {
              const key = keyOps$.key;
              return keyOps$.pipe(switchMap(([_, value]) => op(key, value)));
            })
          )
        )
      )
      .subscribe((result) => {
        if (result.ok) {
          this.commit(result.key, result.value);
        } else {
          this.rollback(result.key);
        }
      });

    this.reset$.next();
  }

  private rollback(key: K): void {
    this.uncommitedState.delete(key);
    this.stateChange.next(key);
  }

  private commit(key: K, value: V): void {
    this.commitedState.set(key, value);
    this.uncommitedState.delete(key);
    this.stateChange.next(key);
  }

  clear(): void {
    this.commitedState.clear();
    this.uncommitedState.clear();
    this.reset$.next();
  }

  get(key: K): V | null {
    if (this.uncommitedState.has(key)) {
      return this.uncommitedState.get(key);
    }
    return this.commitedState.get(key);
  }

  set(key: K, value: V): void {
    this.uncommitedState.set(key, value);
    this.operation$.next([key, value]);
    this.stateChange.next(key);
  }

  setCommited(state: Map<K, V>): void {
    this.commitedState = new Map([...this.commitedState, ...state]);
  }

  destroy(): void {
    this.subscription.unsubscribe();
  }
}
