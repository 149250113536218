import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const UNTIL_DESTROYED_INIT = Symbol('UNTIL_DESTROYED_INIT');
const UNTIL_DESTROYED_EVENT = Symbol('UNTIL_DESTROYED_EVENT');
export function UntilDestroy(): ClassDecorator {
  return (klass: { prototype: any }) => {
    klass.prototype[UNTIL_DESTROYED_INIT] = true;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const ngOnDestroy = klass.prototype.ngOnDestroy;
    klass.prototype.ngOnDestroy = function () {
      ngOnDestroy?.apply(this);
      this[UNTIL_DESTROYED_EVENT]?.next();
    };
  };
}

export function untilDestroyed<T>(
  instance: any
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) => {
    const proto = instance.constructor.prototype;
    const ngOnDestroy: unknown = proto.ngOnDestroy;
    if (typeof ngOnDestroy !== 'function') {
      throw new Error(
        `${instance.constructor.name} is using untilDestroyed but doesn't implement ngOnDestroy`
      );
    }

    if (!proto[UNTIL_DESTROYED_INIT]) {
      throw new Error(
        `${instance.constructor.name} is using untilDestroyed but doesn't have an @UntilDestroy() decorator attached`
      );
    }

    if (!instance[UNTIL_DESTROYED_EVENT]) {
      instance[UNTIL_DESTROYED_EVENT] = new ReplaySubject();
    }

    return source.pipe(takeUntil<T>(instance[UNTIL_DESTROYED_EVENT]));
  };
}
