declare const require: {
  context(
    path: string,
    deep?: boolean,
    filter?: RegExp
  ): {
    keys(): string[];
    <T>(id: string): T;
  };
};
const context = require.context('!raw-loader!./icons', true, /\.svg$/);

export const STREAMLINE_ICONS = Object.fromEntries(
  context.keys().map((key) => [
    // './icon.svg' -> 'icon'
    key.substring(2, key.length - 4),
    context<any>(key).default as string,
  ])
);
