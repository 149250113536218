import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';

const shellDisplay: IShellDisplay = {
  route: '/pages/notifications',
  navTitleKey: l10n('NOTIFICATION.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:megaphone',
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [{ provide: SHELL_DISPLAY, useValue: shellDisplay, multi: true }],
})
export class NotificationMetadataModule {}
