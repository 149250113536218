<app-scaffold
  icon="streamline:construction-cone"
  [title]="'ERROR.SERVICE_UNAVAILABLE.TITLE' | translate"
  [content]="'ERROR.SERVICE_UNAVAILABLE.CONTENT' | translate"
>
  <p
    *ngIf="status && status.type === 'MAINTENANCE'"
    class="mt-3 text-sm tracking-wide5 leading-tight whitespace-pre-line"
  >
    {{
      'ERROR.SERVICE_UNAVAILABLE.DESCRIPTION'
        | translate : { period: localizePeriod(status.start, status.end) }
    }}
  </p>
</app-scaffold>
