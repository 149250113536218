import { SupportedLanguage } from '@core/enum';
import { APISupportedLanguage } from '../models/common';

export function mapLanguagePreference(lang?: string): SupportedLanguage {
  switch (lang) {
    case APISupportedLanguage.EN:
      return SupportedLanguage.EN;
    case APISupportedLanguage.ZH_HANS:
      return SupportedLanguage.ZH_HANS;
    case APISupportedLanguage.ZH_HANT_HK:
      return SupportedLanguage.ZH_HANT_HK;
    default:
      return SupportedLanguage.EN;
  }
}
