import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';

import { of } from 'rxjs';

import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';

const submitShellDisplay: IShellDisplay = {
  route:
    '/pages/gpp/company/:companyID/submission-group/:submissionGroupID/baseline-settings/submit',
  navTitleKey: l10n('GPP.BASELINE_SETTINGS.SUBMIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:ecology-leaf',
  hideNavBar$: of(true),
};

const editShellDisplay: IShellDisplay = {
  route:
    '/pages/gpp/company/:companyID/submission-group/:submissionGroupID/baseline-settings/edit',
  navTitleKey: l10n('GPP.BASELINE_SETTINGS.EDIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:ecology-leaf',
  hideNavBar$: of(true),
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: submitShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: editShellDisplay, multi: true },
  ],
})
export class GPPBaselineSettingsMetadataModule {}
