import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';

const shellDisplayIndex: IShellDisplay = {
  route: '/pages/submission-history',
  navTitleKey: l10n('SUBMISSION_HISTORY.NAV_TITLE.INDEX'),
  navTitleSvgIcon: 'streamline:common-file-text-clock',
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: shellDisplayIndex, multi: true },
  ],
})
export class SubmissionHistoryMetadataModule {}
