import moment, { Moment } from 'moment';
import { IPublicHoliday } from '@model/common';

export function isSameDay(a: Date, b: Date): boolean {
  return (
    a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
  );
}

export function dayStartOf(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function addWorkingDays(
  fromDate: Moment,
  workingDays: number,
  publicHolidays: IPublicHoliday[] = []
): Moment {
  const newDate = fromDate.clone();
  let currWorkingDays = 0;

  while (currWorkingDays < workingDays) {
    newDate.add(1, 'day');

    const isWeekend = newDate.day() === 0 || newDate.day() === 6;
    const isPublicHoliday = publicHolidays.some(({ date }) =>
      newDate.isSame(moment(date), 'D')
    );

    if (!isWeekend && !isPublicHoliday) {
      currWorkingDays++;
    }
  }

  return newDate;
}
