import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BaseRouteGuard } from './base-route-guard';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UnsavedFormDialog } from '@shared/component/common/unsaved-form-dialog/unsaved-form-dialog.service';

const ERROR_PAGES = [
  '/pages/login', // When token expired
  '/pages/system/',
];

@Injectable({ providedIn: 'root' })
export class UnsavedFormRouteGuard extends BaseRouteGuard<unknown> {
  constructor(
    location: Location,
    router: Router,
    private readonly unsavedDialog: UnsavedFormDialog
  ) {
    super(location, router);
  }

  async shouldBlockNavigation(
    _: unknown,
    __: ActivatedRouteSnapshot,
    ___: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Promise<boolean> {
    if (ERROR_PAGES.some((path) => nextState.url.startsWith(path))) {
      return false;
    }

    const leave = await this.unsavedDialog.tryLeaveForm();
    return !leave;
  }
}
