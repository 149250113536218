import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import {
  NavMenuItem,
  NavMenuItemType,
  NavMenuSection,
  NAV_MENU_ITEM,
} from '@shared/metadata/INavMenuItem';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { OperationCode } from '@core/enum';
import { of } from 'rxjs';

const indexShellDisplay: IShellDisplay = {
  route: '/pages/user-management',
  navTitleKey: l10n('USER_MANAGEMENT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:multiple-neutral-1',
};

const createShellDisplay: IShellDisplay = {
  route: '/pages/user-management/new-user',
  navTitleKey: l10n('USER_MANAGEMENT.NEW_USER.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:multiple-neutral-1',
  hideNavBar$: of(true),
};
const editShellDisplay: IShellDisplay = {
  route: '/pages/user-management/edit-user',
  navTitleKey: l10n('USER_MANAGEMENT.EDIT_USER.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:multiple-neutral-1',
  hideNavBar$: of(true),
};

const navMenuItem: NavMenuItem = {
  type: NavMenuItemType.SingleItem,
  route: '/pages/user-management',
  section: NavMenuSection.Account,
  titleKey: l10n('USER_MANAGEMENT.NAV_TITLE'),
  svgIcon: 'streamline:multiple-neutral-1',
  order: 200,
  operationCode: [
    OperationCode.AccessReadUserManagement,
    OperationCode.AccessReadGPPUserManangement,
  ],
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: indexShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: createShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: editShellDisplay, multi: true },
    { provide: NAV_MENU_ITEM, useValue: navMenuItem, multi: true },
  ],
})
export class UserManagmentMetadataModule {}
