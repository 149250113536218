import { pipe } from 'fp-ts/lib/function';
import * as Eq from 'fp-ts/Eq';
import * as Ord from 'fp-ts/Ord';
import * as S from 'fp-ts/string';

export interface ILocalizedString {
  enUS?: string;
  zhHant?: string;
  zhHans?: string;
}

export const eqLocalizableString = pipe(
  Eq.tuple(S.Eq, S.Eq, S.Eq),
  Eq.contramap(
    (s: ILocalizedString) =>
      [s.enUS, s.zhHans, s.zhHant] as [string, string, string]
  )
);

export const ordLocalizableString = pipe(
  S.Ord,
  Ord.contramap((l: ILocalizedString) => l.enUS)
);
