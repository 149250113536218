import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  IForm,
  compareForm,
  IFormCategory,
  IFormSubmission,
  compareSubmission,
  IFormSubmissionStatus,
  IFormSubmissionRequest,
} from '@model/forms/v2';
import {
  compareBuilding,
  compareLease,
  IBuilding,
  IEditableLease,
  ILease,
  ISort,
  IUploadedFile,
  LeaseStatus,
} from '@model/common';
import { FormsV2APIService } from '@service/api/forms-v2-api.service';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, map, mergeMap, switchMap, timeout } from 'rxjs/operators';
import {
  mapForm,
  mapSubmission,
  mapSubmissionStatus,
} from '../mappers/forms-v2';
import { mapEditableLease } from '../mappers/common';
import { intersection } from 'lodash';
import { includesIgnoreCase } from '@core/util/includesIgnoreCase';
import {
  extractExtension,
  removeExtension,
} from '@shared/utils/file-extensions';
import { reverseMapEFormRequestDetails } from '@service/mappers/e-forms';
import { IAPIFormSubmissionQuery } from '@service/api/models/forms-v2';
import { RequestsAPIService } from '@service/api/requests-api.service';
import { IFormsFilterOptions } from './forms.service';

const uploadTimeout = 60 * 1000;

export interface IFormSubmissionsFilterOptions {
  buildingNames?: string[] | null;
  leases?: ILease[] | null;
  statusCode?: string | null;
  hideExpired?: boolean;
  mySubmissionsOnly?: boolean;
  search?: string | null;
}

export interface ISubmissionResult {
  id: number;
  referenceNo: string;
}

export enum QuerySortField {
  Tenancy = 'tenancy',
  Form = 'form',
  Remarks = 'remarks',
  ReferenceNo = 'reference-no',
  SubmittedOn = 'submitted-on',
  Status = 'status',
  ProcessedBy = 'processed-by',
}

export interface ISubmissionHistoryQuery {
  buildingIDs?: number[];
  leaseIdentifiers?: string[];
  statuses?: string;
  mySubmission?: boolean;
  hideExpiredTenancy?: boolean;
  search?: string;
  page?: number;
  limit?: number;
  sort?: ISort<QuerySortField>;
}

export interface ISubmissionHistoryResult {
  items: IFormSubmission[];
  totalCount: number;
}

// Fixme: Status ==> form submission type ?
const apiSortFieldMap: Record<
  QuerySortField,
  IAPIFormSubmissionQuery['sortBy']
> = {
  [QuerySortField.Tenancy]: 'LEASE_IDENTIFIER',
  [QuerySortField.Form]: 'FORM_NAME',
  [QuerySortField.Remarks]: 'REMARKS',
  [QuerySortField.ReferenceNo]: 'REFERENCE_NO',
  [QuerySortField.SubmittedOn]: 'CREATED_DATE',
  [QuerySortField.ProcessedBy]: 'PROCESSED_BY',
  [QuerySortField.Status]: 'STATUS',
};

const apiSortOrderMap: Record<
  'asc' | 'desc',
  IAPIFormSubmissionQuery['order']
> = {
  asc: 'ASC',
  desc: 'DESC',
};

@Injectable({
  providedIn: 'root',
})
export class FormsV2Service {
  constructor(
    private readonly api: FormsV2APIService,
    private readonly http: HttpClient,
    private readonly requestApi: RequestsAPIService
  ) {}

  public getForms(): Observable<IForm[]> {
    return combineLatest([
      this.api.getForms(),
      this.requestApi.getBuildings(),
    ]).pipe(
      map(([apiForms, apiBuildings]) => {
        const buildings = new Map(
          Object.values(apiBuildings)
            .flat()
            .map((b) => [b.buildingID, b.localBuildingName])
        );
        return apiForms
          .map((f) => mapForm(f, buildings))
          .filter((f) => f.buildings.length > 0)
          .sort(compareForm);
      })
    );
  }

  public getCategories(): Observable<IFormCategory[]> {
    return this.api.getCategories().pipe(
      map((categories) =>
        categories.map((c) => ({
          code: c.code,
          description: c.description,
        }))
      )
    );
  }

  public getBuildings(): Observable<IBuilding[]> {
    return this.api
      .getBuildings()
      .pipe(map((building) => building.sort(compareBuilding)));
  }

  public getLeases(): Observable<IEditableLease[]> {
    return this.api
      .getLeases()
      .pipe(map((leases) => leases.map(mapEditableLease).sort(compareLease)));
  }

  public getFormLeases(formID: number): Observable<IEditableLease[]> {
    return this.api
      .getFormLeases(formID)
      .pipe(map((leases) => leases.map(mapEditableLease).sort(compareLease)));
  }

  public setStar(id: number, starred: boolean): Observable<boolean> {
    return this.api.setStar(id, starred);
  }

  public filterForms(
    forms: IForm[],
    options: IFormsFilterOptions
  ): Observable<IForm[]> {
    let filteredForms = forms;
    if (options.starredOnly) {
      filteredForms = filteredForms.filter((f) => f.starred);
    }

    if (options.search != null) {
      filteredForms = filteredForms.filter(
        (f) =>
          includesIgnoreCase(f.name, options.search) ||
          includesIgnoreCase(f.description, options.search)
      );
    }

    return of(filteredForms.sort(compareForm));
  }

  public filterSubmissions(
    submissions: IFormSubmission[],
    options: IFormSubmissionsFilterOptions
  ): Observable<IFormSubmission[]> {
    let result = submissions;
    if (options.buildingNames) {
      result = result.filter(
        (s) =>
          intersection(
            s.leases.map((l) => l.buildingName),
            options.buildingNames
          ).length > 0
      );
    }
    if (options.leases) {
      const leaseIDs = options.leases.map((l) => l.leaseIdentifier);
      result = result.filter(
        (s) =>
          intersection(
            s.leases.map((l) => l.leaseIdentifier),
            leaseIDs
          ).length > 0
      );
    }
    if (options.statusCode) {
      result = result.filter((s) => s.statusCode === options.statusCode);
    }
    if (options.hideExpired) {
      result = result.filter((s) =>
        s.leases.some((l) => l.status !== LeaseStatus.Expired)
      );
    }
    if (options.mySubmissionsOnly) {
      result = result.filter((s) => s.isMySubmission);
    }
    if (options.search != null) {
      if (options.search.startsWith('id:')) {
        const searchId = Number(options.search.slice(3));
        result = result.filter((f) => f.id === searchId);
      } else {
        result = result.filter(
          (f) =>
            includesIgnoreCase(f.form.name, options.search) ||
            includesIgnoreCase(f.refNo, options.search)
        );
      }
    }

    return of(result.sort(compareSubmission));
  }

  public getSubmissionStatuses(): Observable<IFormSubmissionStatus[]> {
    return this.api
      .getStatuses()
      .pipe(map((statuses) => statuses.map(mapSubmissionStatus)));
  }

  public getSubmissions(
    leases: ILease[],
    query: ISubmissionHistoryQuery
  ): Observable<ISubmissionHistoryResult> {
    return this.api
      .getSubmissions({
        buildingIDs: query.buildingIDs,
        status: query.statuses,
        leaseIdentifiers: query.leaseIdentifiers,

        hideExpiredTenancy: query.hideExpiredTenancy,
        mySubmission: query.mySubmission,

        searchTerm: query.search,
        searchFields: query.search
          ? [
              'LEASE_IDENTIFIER',
              'FORM_NAME',
              'REMARKS',
              'REFERENCE_NO',
              'CREATED_DATE',
              'PROCESSED_BY',
              'FORM_SUBMISSION_TYPE',
              'STATUS',
            ]
          : undefined,

        page: query.page,
        limit: query.limit,

        sortBy: query.sort?.field
          ? apiSortFieldMap[query.sort.field]
          : undefined,
        order: query.sort?.order
          ? apiSortOrderMap[query.sort.order]
          : undefined,
      })
      .pipe(
        map((resp) => {
          const leaseMap = new Map(leases.map((l) => [l.leaseIdentifier, l]));
          const submissions = resp.list
            .map((s) => mapSubmission(s, leaseMap))
            // FIXME: missing buildings field from API
            // .filter((s) => s.form.buildings.length > 0)
            .sort(compareSubmission);
          return {
            items: submissions,
            totalCount: resp.count,
          };
        })
      );
  }

  public getSubmission(
    leases: ILease[],
    id: number
  ): Observable<IFormSubmission> {
    const leaseMap = new Map(leases.map((l) => [l.leaseIdentifier, l]));
    return this.api
      .getSubmission(id)
      .pipe(map((s) => mapSubmission(s, leaseMap)));
  }

  public downloadSubmissionFile(
    submissionID: number,
    fileID: number
  ): Observable<Blob> {
    return this.api.getSubmissionFileDownloadUrl(submissionID, fileID).pipe(
      switchMap((url) => {
        return this.http.get(url, { responseType: 'blob' });
      })
    );
  }

  public submitForm(
    formID: number,
    submission: IFormSubmissionRequest
  ): Observable<ISubmissionResult> {
    if (submission.detail.submissionType === 'MONTHLY_CAR_PARKING_REQUEST') {
      return of({ id: 1, referenceNo: 'XX2e13SDF' }).pipe(delay(1500));
    }

    return this.api
      .submitForm(formID, {
        leaseIdentifiers: submission.leases.map((l) => l.leaseIdentifier),
        files: submission.documents.map((d) => ({
          fileName: removeExtension(d.fileName),
          fileExtension: extractExtension(d.fileName),
          fileSize: d.fileSize,
          objectKey: d.objectKey,
        })),
        remarks: submission.remarks,
        detail: reverseMapEFormRequestDetails(submission.detail),
      })
      .pipe(
        map((s) => ({ id: s.formSubmissionID, referenceNo: s.submissionRefNo }))
      );
  }

  public uploadFile(file: File): Observable<IUploadedFile> {
    return this.api.getUploadUrl(file.name, file.type).pipe(
      mergeMap((resp) =>
        this.http.put(resp.url, file).pipe(
          timeout(uploadTimeout),
          mergeMap(() => this.api.getDownloadUrl(resp.objectKey)),
          map(
            (url): IUploadedFile => ({
              fileName: file.name,
              fileSize: file.size,
              contentType: file.type,
              objectKey: resp.objectKey,
              url,
            })
          )
        )
      )
    );
  }
}
