import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { isSameDay } from '@core/util/date';
import { SystemService, SystemStatus } from '@service/system/system.service';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss'],
})
export class ServiceUnavailableComponent implements OnInit {
  status: SystemStatus | null = null;

  constructor(private readonly system: SystemService) {}

  ngOnInit(): void {
    const status = window.history.state.status;
    if (status) {
      this.status = status;
    } else {
      this.system.getStatus().subscribe((s) => {
        this.status = s;
      });
    }
  }

  localizePeriod(start: Date, end: Date): string {
    if (isSameDay(start, end)) {
      return [
        formatDate(start, 'dd-MM-YYYY', 'en'),
        formatDate(start, 'HH:mm', 'en'),
        '-',
        formatDate(end, 'HH:mm', 'en'),
      ].join(' ');
    }
    return [
      formatDate(start, 'dd-MM-YYYY HH:mm', 'en'),
      '-',
      formatDate(end, 'dd-MM-YYYY HH:mm', 'en'),
    ].join(' ');
  }
}
