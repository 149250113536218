import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsAPIService } from '@service/api/notifications-api.service';
import { mapNotificationAlerts } from '@service/mappers/notifications';
import { INotificationAlerts } from '@model/notifications';

@Injectable({
  providedIn: 'root',
})
export class ShellService {
  constructor(private readonly notificationsApi: NotificationsAPIService) {}

  public getAlerts(): Observable<INotificationAlerts> {
    return this.notificationsApi
      .getAlerts()
      .pipe(map((alerts) => mapNotificationAlerts(alerts)));
  }
}
