<div class="h-full flex items-center justify-center">
  <div class="flex flex-col items-center text-center sm:w-96 w-full sm:p-0 p-4">
    <div class="w-18 h-18 p-4 mb-5 rounded-full bg-primary_50 text-primary_900">
      <mat-icon class="icon w-10 h-10" [svgIcon]="icon"></mat-icon>
    </div>
    <h1 class="text-3xl tracking-wide5 leading-tight mb-3">
      {{ title }}
    </h1>
    <p class="text-sm tracking-wide5 leading-tight whitespace-pre-wrap">
      {{ content }}
    </p>
    <ng-content></ng-content>
    <a
      *ngIf="actionLink"
      mat-flat-button
      color="primary"
      class="sm:w-auto w-full text-sm font-normal tracking-wide5 leading-9 mt-6"
      [href]="actionLink"
    >
      {{ actionLabel }}
    </a>
  </div>
</div>
