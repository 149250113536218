import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error.component';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BackgroundModule } from '@shared/component/common/background/background.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ErrorComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
    BackgroundModule,
    TranslateModule,
  ],
})
export class ErrorModule {}
