import { ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export function findParentDialogRef(
  host: ElementRef<HTMLElement>,
  dialog: MatDialog
): MatDialogRef<unknown> {
  let parent: HTMLElement | null = host.nativeElement.parentElement;
  while (parent && !parent.classList.contains('mat-dialog-container')) {
    parent = parent.parentElement;
  }
  return dialog.openDialogs.find((d) => d.id === parent?.id);
}
