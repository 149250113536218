export const imageFileExtensions: string[] = [
  'png',
  'jpg',
  'jpeg',
  'tiff',
  'bmp',
  'gif',
];

export const documentFileExtensions: string[] = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
];

export function removeExtension(fileName: string): string {
  const dot = fileName.lastIndexOf('.');
  if (dot < 0) {
    return fileName;
  }
  return fileName.substring(0, dot);
}

export function extractExtension(fileName: string): string {
  return fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
}
