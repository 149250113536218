import { ILocalizedString } from './ILocalizedString';
import { IMaybeSequenceComparable } from './ISequenceComparable';

export interface ICodeDescription extends IMaybeSequenceComparable {
  code: string;
  description: ILocalizedString;
  isActive?: boolean;
}

export interface IColorCodeDescription extends ICodeDescription {
  color: string;
}

export function sortCodeDescriptionByEnUs(
  a: ICodeDescription,
  b: ICodeDescription
): number {
  if (a.description.enUS != null && b.description.enUS != null) {
    return a.description.enUS.localeCompare(b.description.enUS);
  }
  if (a.description.enUS != null) {
    return -1;
  }
  if (b.description.enUS != null) {
    return 1;
  }
  return 0;
}
