import { ILease, ILocalizedString, IUploadedFile } from '@model/common';
import { EFormRequestDetails } from './e-form';

export enum FormType {
  EForm = 'e-form',
  PDF = 'pdf',
}

export enum EFormType {
  ACRequest = 'ac-request',
  DirectorySignage = 'directory-signage',
  StandardFitting = 'standard-fitting',
  FacilityMaintenance = 'facility-maintenance',
  VisitorAccess = 'visitor-access',
  StaffAccess = 'staff-access',
  ServiceLiftRequest = 'service-lift-request',
  MonthlyCarParking = 'monthly-car-parking',
  Generic = 'generic',
}

export function parseEFormType(formItemID: string): EFormType | null {
  if (formItemID.startsWith('AC_REQUEST')) {
    return EFormType.ACRequest;
  }
  if (formItemID.startsWith('DIRECTORY_SIGNAGE')) {
    return EFormType.DirectorySignage;
  }
  if (formItemID.startsWith('FACILITY_MAINTENANCE')) {
    return EFormType.FacilityMaintenance;
  }
  if (formItemID.startsWith('STANDARD_FITTING')) {
    return EFormType.StandardFitting;
  }
  if (formItemID.startsWith('VISITOR_ACCESS')) {
    return EFormType.VisitorAccess;
  }
  if (formItemID.startsWith('STAFF_ACCESS')) {
    return EFormType.StaffAccess;
  }
  if (formItemID.startsWith('SERVICE_LIFT_REQUEST')) {
    return EFormType.ServiceLiftRequest;
  }
  if (formItemID.startsWith('MONTHLY_CAR_PARKING')) {
    return EFormType.MonthlyCarParking;
  }
  return null;
}

export interface IFormBuilding {
  name: string;
  canSubmit: boolean;
}

export interface IForm {
  id: number;
  buildings: IFormBuilding[];
  name: string;
  description: string;
  starred: boolean;
  type: FormType;
  categoryCode: string;
  isMultipleLease: boolean;
  fileURL: string;
  operationCodes: string[];

  formItemID: string | null;
  descriptionTAC: ILocalizedString;
}

export interface IFormCategory {
  code: string;
  description: ILocalizedString;
}

export interface IFormSubmissionStatus {
  code: string;
  description: ILocalizedString;
  color: string;
}

export interface IFormSubmissionFile {
  id: number;
  fileName: string;
  fileExtension: string;
  fileSize: number;
}

export interface IFormSubmission {
  id: number;
  refNo: string;
  leases: ILease[];
  documents: IFormSubmissionFile[];
  remarks: string;
  submittedBy: string;
  submittedAt: Date;
  lastUpdatedAt: Date;
  lastUpdatedByName: string;
  isMySubmission: boolean;
  statusCode: string;
  reviewedBy: string | null;
  rejectReason: string | null;
  form: IFormSubmissionFormInfo;
  detail: EFormRequestDetails;
}

export interface IFormSubmissionFormInfo {
  id: number;
  name: string;
  type: FormType;
  categoryCode: string;
  formItemID: string | null;
  descriptionTAC: ILocalizedString;
}

export interface IFormSubmissionRequest {
  leases: ILease[];
  documents: IUploadedFile[];
  remarks: string;
  detail: EFormRequestDetails;
}

export interface IDuplicatedSubmissionErrorParams {
  duplicatedSubmissions: IFormSubmission[];
}

export function compareForm(a: IForm, b: IForm): number {
  return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
}

export function compareSubmission(
  a: IFormSubmission,
  b: IFormSubmission
): number {
  return b.submittedAt.getTime() - a.submittedAt.getTime();
}
