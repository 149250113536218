import { OperationCode } from '@core/enum';
import { OperationCodeExpression } from '@core/util/opCodeExpression';
import { requestOpcodes } from '@model/auth/models';

export const checkCanPerformaOperation = (
  expr: OperationCodeExpression,
  exisitingOpCodes: string[],
  opCodeBuildings: Map<OperationCode, Set<number>> | null,
  operatingBuildingID: number | null
): boolean => {
  if (typeof expr === 'string') {
    if (!requestOpcodes.has(expr)) {
      return exisitingOpCodes.includes(expr);
    }
    const opCodeBuilding = opCodeBuildings?.get(expr);
    if (opCodeBuilding == null) {
      return false;
    }
    return operatingBuildingID != null
      ? opCodeBuilding.has(operatingBuildingID)
      : opCodeBuilding.size > 0;
  }

  switch (expr.operator) {
    case 'or': {
      return expr.subExprArray.some((e) =>
        checkCanPerformaOperation(
          e,
          exisitingOpCodes,
          opCodeBuildings,
          operatingBuildingID
        )
      );
    }
    case 'and': {
      return expr.subExprArray.every((e) =>
        checkCanPerformaOperation(
          e,
          exisitingOpCodes,
          opCodeBuildings,
          operatingBuildingID
        )
      );
    }
    case 'not': {
      const result = checkCanPerformaOperation(
        expr.subExpr,
        exisitingOpCodes,
        opCodeBuildings,
        operatingBuildingID
      );
      return !result;
    }
  }
  return false;
};
