import { Injectable } from '@angular/core';
import { ApiClient } from '@core/http/api-client';
import { parseDate } from '@service/api/utils/parser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment, { Moment } from 'moment';

import { IListResponse } from './models/common';
import {
  IAPIAreaCode,
  IAPILoginHandler,
  IAPIPublicHoliday,
  IAPISystemDateTime,
  IAPISystemStatus,
} from './models/system';
import { IAPICodeDescription } from './../mappers/common';

@Injectable({
  providedIn: 'root',
})
export class SystemAPIService {
  constructor(private readonly http: ApiClient) {}

  public getAllowedAreaCodes(): Observable<IAPIAreaCode[]> {
    return this.http.get<IListResponse<any>>('system/area-codes').pipe(
      map((resp) =>
        resp.list.map(
          (a): IAPIAreaCode => ({
            tpShow: a.tpShow,
            active: a.active,
            code: a.code,
            description: a.description,
            descriptionZhCN: a.descriptionZhCN,
            descriptionZhHK: a.descriptionZhHK,
          })
        )
      )
    );
  }

  public getStatus(): Observable<IAPISystemStatus> {
    return this.http.get<any>('system/status').pipe(
      map((resp) => ({
        maintenanceStart: resp.maintenanceStart
          ? new Date(resp.maintenanceStart)
          : null,
        maintenanceEnd: resp.maintenanceEnd
          ? new Date(resp.maintenanceEnd)
          : null,
        status: resp.status,
      }))
    );
  }

  public getLoginHandler(): Observable<IAPILoginHandler> {
    return this.http.get<any>('system/login/handler').pipe(
      map((resp) => ({
        shouldRedirect: resp.shouldRedirect,
        redirectURL: resp.redirectURL,
      }))
    );
  }

  public getPublicHolidays(): Observable<IAPIPublicHoliday[]> {
    return this.http.get<IListResponse<any>>('system/public-holidays').pipe(
      map((resp) =>
        resp.list.map((h) => ({
          date: parseDate(h.date),
          description: {
            enUS: h.description,
            zhHans: h.descriptionZhCN,
            zhHant: h.descriptionZhHK,
          },
        }))
      )
    );
  }

  getRawCodeDescriptions(
    codeKey?: string
  ): Observable<IListResponse<IAPICodeDescription>> {
    return this.http.get<IListResponse<IAPICodeDescription>>(
      'system/code-descriptions',
      { params: { codeKey } }
    );
  }

  public getSystemNow(): Observable<Moment> {
    return this.http.get<IAPISystemDateTime>('system/systemDateTime').pipe(
      map((systemDateTime) => {
        const { localDate, localTime } = systemDateTime;
        const serverNow = moment(`${localDate} ${localTime}`);
        return serverNow;
      })
    );
  }
}
