import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  IToolbarButton,
  TOOLBAR_BUTTON,
} from '@shared/metadata/IToolbarButton';
import { Observable, of } from 'rxjs';
import { AuthService } from '@service/auth/auth.service';
import { OperationCode } from '@core/enum';
import { INotificationAlerts } from '@model/notifications';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input()
  notificationAlerts: INotificationAlerts;

  constructor(
    @Inject(TOOLBAR_BUTTON) private readonly buttonDefs: IToolbarButton[],
    private readonly authService: AuthService
  ) {}

  buttons: Observable<IToolbarButton[]>;

  ngOnInit(): void {
    this.buttons = of(
      this.buttonDefs.slice().sort((a, b) => a.order - b.order)
    );
  }

  checkIfHasAccessRight(
    operationCode: OperationCode | null
  ): Observable<boolean> {
    if (operationCode) {
      return this.authService.canPerformOperation(operationCode);
    }
    return of(true);
  }
}
