import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { from, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_ERROR_HANDLER, IAPIErrorHandler } from './error-handler';
import {
  APIError,
  ForbiddenError,
  isAPIErrorResponse,
  NetworkError,
  ServiceUnavailableError,
  UnauthorizedError,
} from './errors';

export interface IAPIEndpointConfig {
  endpoint: string;
  apiKey?: string;
}

export const API_ENDPOINT_CONFIG = new InjectionToken<IAPIEndpointConfig>(
  'API_ENDPOINT_CONFIG'
);

class ApiHttpHandler extends HttpHandler {
  constructor(
    private readonly next: HttpHandler,
    private readonly errorHandler: IAPIErrorHandler | null,
    private readonly config: IAPIEndpointConfig | null
  ) {
    super();
  }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    let headers = req.headers;
    if (this.config?.apiKey) {
      headers = req.headers.append('x-api-key', this.config.apiKey);
    }
    req = req.clone({
      url: `${this.config?.endpoint ?? ''}/${req.url}`,
      headers,
    });
    const result = this.next.handle(req).pipe(
      catchError((err) => {
        return from(
          // eslint-disable-next-line complexity
          (async () => {
            let error = err;

            if (err instanceof HttpErrorResponse) {
              let resp = err.error;
              if (typeof resp === 'string') {
                try {
                  resp = JSON.parse(resp);
                } catch {}
              }
              if (resp instanceof Blob) {
                try {
                  resp = JSON.parse(await resp.text());
                } catch {}
              }

              if (isAPIErrorResponse(resp)) {
                error = new APIError(resp, err.status);
              } else if (err.status === 401) {
                error = new UnauthorizedError(err);
              } else if (err.status === 403) {
                error = new ForbiddenError(err);
              } else if (err.status === 503) {
                error = new ServiceUnavailableError(err);
              } else if (err.status === 0) {
                error = new NetworkError();
              }
            }
            throw error;
          })()
        );
      })
    );
    if (this.errorHandler) {
      return this.errorHandler.handleError(result);
    }
    return result;
  }
}

@Injectable({ providedIn: 'root' })
export class ApiClient extends HttpClient {
  constructor(
    handler: HttpHandler,
    @Optional() @Inject(API_ENDPOINT_CONFIG) config: IAPIEndpointConfig,
    @Optional() @Inject(API_ERROR_HANDLER) errorHandler: IAPIErrorHandler
  ) {
    super(new ApiHttpHandler(handler, errorHandler, config));
  }
}
