import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';

import { of } from 'rxjs';

import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';

const submitShellDisplay: IShellDisplay = {
  route: '/pages/directory-signage/submit',
  navTitleKey: l10n('DIRECTORY_SIGNAGE.SUBMIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:common-file-text-edit',
  hideNavBar$: of(true),
};

const viewShellDisplay: IShellDisplay = {
  route: '/pages/directory-signage/:submissionID',
  navTitleKey: l10n('DIRECTORY_SIGNAGE.VIEW.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:common-file-text',
  hideNavBar$: of(true),
};

const reviseShellDisplay: IShellDisplay = {
  route: '/pages/directory-signage/:submissionID/revise',
  navTitleKey: l10n('DIRECTORY_SIGNAGE.REVISE.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:common-file-text-edit',
  hideNavBar$: of(true),
};

@NgModule({
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: submitShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: viewShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: reviseShellDisplay, multi: true },
  ],
})
export class DirectorySignageMetadataModule {}
