import { isFunction } from 'lodash';
import {
  IAPILease,
  IAPIBuilding,
  IListResponse,
  IAPILeaseWithPeriod,
  IAPIEditableBuilding,
  APIFieldState,
  IAPIFile,
  IAPIPresignedURL,
  APILocalizableStringCodec,
  APIBuildingCommonFacilityDTOCodec,
  APIExportDownloadStatusCodec,
} from '../models/common';
import { parseDatetime } from '../utils/parser';
import { makeImpureDecode } from './iots';

export function mapAPIBuilding(item: any): IAPIBuilding {
  return {
    buildingID: item.buildingID,
    localBuildingName: item.localBuildingName,
  };
}

export function mapAPIEditableBuilding(item: any): IAPIEditableBuilding {
  return {
    ...mapAPIBuilding(item),
    isEditable: item.editable,
  };
}

export function mapAPILease(item: any): IAPILease {
  return {
    leaseID: item.leaseID,
    leaseIdentifier: item.leaseIdentifier,
    leaseNo: item.leaseNo,
    building: mapAPIBuilding(item.building),
    currentShopList: item.currentShopList || null,
    currentTenantName: item.currentTenantName,
    unitName: item.unitName,
    portfolioType: item.portfolioType,
    tradeCategoryGroupID: item.tradeCategoryGroupID,
    status: item.status,
    editable: item.editable,
    additionalInfo: item.additionalInfo,
  };
}

export function mapAPILeaseWithPeriod(item: any): IAPILeaseWithPeriod {
  return {
    ...mapAPILease(item),
    commencementDate: parseDatetime(item.commencementDate),
    expiryDate: parseDatetime(item.expiryDate),
  };
}

export function mapIListResponse<T>(
  raw: unknown,
  itemMapper: (itemRaw: unknown) => T
): IListResponse<Exclude<T, null>>;
export function mapIListResponse<T>(
  fmap: (u: unknown) => T
): (u: unknown) => IListResponse<Exclude<T, null>>;
export function mapIListResponse(firstArg: any, secondArg?: any): any {
  if (isFunction(firstArg) && secondArg == null) {
    return (u: any) => ({
      list: u.list.map(firstArg).filter(Boolean),
      count: Number(u.count),
      limit: Number(u.limit),
      page: Number(u.page),
    });
  }
  return {
    list: firstArg.list.map(secondArg).filter(Boolean),
    count: firstArg.count,
    limit: firstArg.limit,
    page: firstArg.page,
  };
}

export function mapAPIFile(o: any): IAPIFile {
  return o;
}

export function mapFormState(o: any): APIFieldState {
  switch (o) {
    case 'HIDDEN':
      return APIFieldState.HIDDEN;
    case 'MANDATORY':
      return APIFieldState.MANDATORY;
    case 'DISPLAY':
      return APIFieldState.DISPLAY;
  }
  throw new Error('unknown field state');
}

export function mapPresignedURL(o: any): IAPIPresignedURL {
  return o;
}

export const mapExportDownloadStatus = makeImpureDecode(
  APIExportDownloadStatusCodec
);

export const mapAPILocalizableString = makeImpureDecode(
  APILocalizableStringCodec
);

export const mapAPIBuildingCommonFacilityDTO = makeImpureDecode(
  APIBuildingCommonFacilityDTOCodec
);
