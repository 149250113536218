import parseUA from 'ua-parser-js';

export interface IParsedUserAgent {
  name: string;
  version: number;
}

export function parseUserAgent(ua: string): IParsedUserAgent {
  const {
    browser: { name, version },
  } = parseUA(ua);
  const dotIndex = version.indexOf('.');
  const numVersion = parseInt(
    dotIndex >= 0 ? version.substr(0, dotIndex) : version,
    10
  );
  return {
    name,
    version: numVersion,
  };
}
