import { NgModule } from '@angular/core';

import {
  NavMenuItem,
  NavMenuItemType,
  NavMenuSection,
  NAV_MENU_ITEM,
  IChildNavMenuItem,
} from '@shared/metadata/INavMenuItem';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import { OperationCode } from '@core/enum';
import { of } from 'rxjs';
import {
  GPPAllConsumptionTypes,
  GPPConsumptionType,
} from '@model/green-performance-pledge';

const indexShellDisplay: IShellDisplay = {
  route: '/pages/gpp/home',
  navTitleKey: l10n('GREEN_PERFORMANCE_PLEDGE.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:ecology-leaf',
  fullScreen: true,
};

const awardsShellDisplay: IShellDisplay = {
  route: '/pages/gpp/tier-awards',
  navTitleKey: l10n('GREEN_PERFORMANCE_PLEDGE.TIER_AWARDS.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:ecology-leaf',
};

const sdProfileShellDisplay: IShellDisplay = {
  route: '/pages/gpp/sd-profile',
  navTitleKey: l10n('GREEN_PERFORMANCE_PLEDGE.SD_PROFILE.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:ecology-leaf',
  showFullBackground: true,
};

const dashboardShellDisplay: IShellDisplay = {
  route: '/pages/gpp/data-dashboard',
  navTitleKey: l10n('GREEN_PERFORMANCE_PLEDGE.DATA_DASHBOARD.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:ecology-leaf',
};

const submitTitleKeys: Record<GPPConsumptionType, string> = {
  [GPPConsumptionType.Electricity]: l10n(
    'GREEN_PERFORMANCE_PLEDGE.SUBMIT.NAV_TITLE.ELECTRICITY'
  ),
  [GPPConsumptionType.Water]: '', //TODO(Isaac),
  [GPPConsumptionType.Waste]: l10n(
    'GREEN_PERFORMANCE_PLEDGE.SUBMIT.NAV_TITLE.WASTE'
  ),
};

const submitShellDisplays: IShellDisplay[] = GPPAllConsumptionTypes.map(
  (type) => ({
    route: `/pages/gpp/signed-companies/:companyID/submission-groups/:submissionGroupID/submit/${type}/:year/:month`,
    navTitleKey: submitTitleKeys[type],
    navTitleSvgIcon: 'streamline:ecology-leaf',
    hideNavBar$: of(true),
  })
);

const editTitleKeys: Record<GPPConsumptionType, string> = {
  [GPPConsumptionType.Electricity]: l10n(
    'GREEN_PERFORMANCE_PLEDGE.EDIT.NAV_TITLE.ELECTRICITY'
  ),
  [GPPConsumptionType.Water]: '', //TODO(Isaac),
  [GPPConsumptionType.Waste]: l10n(
    'GREEN_PERFORMANCE_PLEDGE.EDIT.NAV_TITLE.WASTE'
  ),
};

const editShellDisplays: IShellDisplay[] = GPPAllConsumptionTypes.map(
  (type) => ({
    route: `/pages/gpp/signed-companies/:companyID/submission-groups/:submissionGroupID/edit/${type}/:year/:month`,
    navTitleKey: editTitleKeys[type],
    navTitleSvgIcon: 'streamline:ecology-leaf',
    hideNavBar$: of(true),
  })
);

const editSDProfileShellDisplay: IShellDisplay = {
  route: '/pages/gpp/sd-profile/company/:companyID/edit',
  navTitleKey: l10n('GREEN_PERFORMANCE_PLEDGE.SD_PROFILE.EDIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:pencil-1',
  hideNavBar$: of(true),
  showFullBackground: true,
};

const datadashboardMenuItem: IChildNavMenuItem = {
  route: '/pages/gpp/data-dashboard',
  titleKey: l10n('GREEN_PERFORMANCE_PLEDGE.NAV_TITLE.DATA_DASHBOARD'),
  operationCode: [
    OperationCode.AccessReadGPPDataDashboardEnergyPerformance,
    OperationCode.AccessReadGPPDataDashboardEnergyBreakdown,
    OperationCode.AccessReadGPPDataDashboardEnergyTrend,
    OperationCode.AccessReadGPPDataDashboardWaterPerformance,
    OperationCode.AccessReadGPPDataDashboardWaterTrend,
    OperationCode.AccessReadGPPDataDashboardWastePerformance,
    OperationCode.AccessReadGPPDataDashboardWasteBreakdown,
    OperationCode.AccessReadGPPDataDashboardWasteTrend,
  ],
};

const sdProfileMenuItem: IChildNavMenuItem = {
  route: '/pages/gpp/sd-profile',
  titleKey: l10n('GREEN_PERFORMANCE_PLEDGE.NAV_TITLE.SD_PROFILE'),
  operationCode: OperationCode.AccessReadGPPSDProfile,
};

const dataCollectionMenuItem: IChildNavMenuItem = {
  route: '/pages/gpp/home/data-collection',
  titleKey: l10n('GREEN_PERFORMANCE_PLEDGE.NAV_TITLE.DATA_COLLECTION'),
  operationCode: OperationCode.AccessReadGPPDataCollection,
};

const gppAwardsMenuItem: IChildNavMenuItem = {
  route: '/pages/gpp/tier-awards',
  titleKey: l10n('GREEN_PERFORMANCE_PLEDGE.NAV_TITLE.GPP_AWARDS'),
  operationCode: OperationCode.AccessReadGPPTierAwards,
};

const gppNavItem: NavMenuItem = {
  type: NavMenuItemType.ExpandableItem,
  section: NavMenuSection.SelfServices,
  svgIcon: 'streamline:ecology-leaf',
  titleKey: l10n('GREEN_PERFORMANCE_PLEDGE.NAV_TITLE'),
  items: [
    datadashboardMenuItem,
    sdProfileMenuItem,
    dataCollectionMenuItem,
    gppAwardsMenuItem,
  ],
  operationCode: [
    OperationCode.AccessReadGPPDataDashboardEnergyPerformance,
    OperationCode.AccessReadGPPDataDashboardEnergyBreakdown,
    OperationCode.AccessReadGPPDataDashboardEnergyTrend,
    OperationCode.AccessReadGPPDataDashboardWaterPerformance,
    OperationCode.AccessReadGPPDataDashboardWaterTrend,
    OperationCode.AccessReadGPPDataDashboardWastePerformance,
    OperationCode.AccessReadGPPDataDashboardWasteBreakdown,
    OperationCode.AccessReadGPPDataDashboardWasteTrend,
    OperationCode.AccessReadGPPDataCollection,
    OperationCode.AccessReadGPPSDProfile,
    OperationCode.AccessReadGPPTierAwards,
  ],
  order: 40,
};

@NgModule({
  declarations: [],
  providers: [
    { provide: SHELL_DISPLAY, useValue: indexShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: awardsShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: sdProfileShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: dashboardShellDisplay, multi: true },
    ...submitShellDisplays.map((value) => ({
      provide: SHELL_DISPLAY,
      useValue: value,
      multi: true,
    })),
    ...editShellDisplays.map((value) => ({
      provide: SHELL_DISPLAY,
      useValue: value,
      multi: true,
    })),
    {
      provide: NAV_MENU_ITEM,
      useValue: gppNavItem,
      multi: true,
    },
    {
      provide: SHELL_DISPLAY,
      useValue: editSDProfileShellDisplay,
      multi: true,
    },
  ],
})
export class GreenPerformancePledgeMetadataModule {}
