import { ISort } from '@model/common';

export enum INotificationSortField {
  type = 'TYPE',
  description = 'DESCRIPTION',
  building = 'BUILDING',
  date = 'DATE',
}

export interface IAPIBuilding {
  buildingID: number;
  localBuildingName: string;
}

export interface IAPINotificationLease {
  leaseIdentifier: string;
  currentTenantName: string;
  currentShopList: string;
  unitName: string;
  portfolioType: string;
}

export interface IAPINotificationGppSubmissionGroup {
  companyID: number;
  submissionGroupID: number;
  submissionGroupName: string;
}

export interface IAPINotification {
  notificationID: number;
  notificationType: string;
  description: string;
  date: Date;
  buildings: IAPIBuilding[];
  leases: IAPINotificationLease[];
  submissionGroupInfo?: IAPINotificationGppSubmissionGroup;
  unread: boolean;
  linkType: string | null;
  linkID: string | null;
}

export interface IAPINotificationType {
  code: string;
  description: string;
  descriptionZhHK: string;
  descriptionZhCN: string;
  sequence: number;
  labelColour: string;
}

export interface IAPINotificationResponse {
  notifications: IAPINotification[];
  totalCount: number;
}

export interface IAPINotificationRequestParams {
  limit: number;
  page: number;
  types?: string[];
  buildingIds?: number[];
  unread?: boolean;
  search?: string;
  sort?: ISort<INotificationSortField>;
}

export interface IAPINotificationMarkReadRequestParams {
  types?: string[];
  buildingIds?: number[];
  unread?: boolean;
}

export interface IAPINotificationAlert {
  count: number | null;
  type: string;
}
