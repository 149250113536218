import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { registerStreamlineSvgIconLiteral } from '@shared/utils/register-svg-icon';
import { STREAMLINE_ICONS } from './streamline-icons';

@NgModule({
  declarations: [],
  imports: [MatIconModule],
  exports: [],
})
export class StreamlineIconsModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    Object.entries(STREAMLINE_ICONS).forEach(([key, literal]) => {
      const safeHtml = sanitizer.bypassSecurityTrustHtml(literal);
      registerStreamlineSvgIconLiteral(iconRegistry, key, safeHtml);
    });
  }
}
