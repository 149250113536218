import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { of } from 'rxjs';

const submitShellDisplay: IShellDisplay = {
  route: '/pages/staff-access/create',
  navTitleKey: l10n('STAFF_ACCESS.CREATE.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:multiple-actions-add',
  hideNavBar$: of(true),
};

const editShellDisplay: IShellDisplay = {
  route: '/pages/staff-access/edit',
  navTitleKey: l10n('STAFF_ACCESS.EDIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:single-neutral-actions-edit-1',
  hideNavBar$: of(true),
};

const cloneShellDisplay: IShellDisplay = {
  route: '/pages/staff-access/clone',
  navTitleKey: l10n('STAFF_ACCESS.CLONE.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:multiple-actions-add',
  hideNavBar$: of(true),
};

const regrantShellDisplay: IShellDisplay = {
  route: '/pages/staff-access/regrant',
  navTitleKey: l10n('STAFF_ACCESS.REGRANT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:multiple-actions-add',
  hideNavBar$: of(true),
};

const massEditShellDisplay: IShellDisplay = {
  route: '/pages/staff-access/mass-edit',
  navTitleKey: l10n('STAFF_ACCESS.MASS_EDIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:ic-close-up-multiple-users-action',
  hideNavBar$: of(true),
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: submitShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: editShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: cloneShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: regrantShellDisplay, multi: true },
    { provide: SHELL_DISPLAY, useValue: massEditShellDisplay, multi: true },
  ],
})
export class StaffAccessMetadataModule {}
