import { Routes, Route } from '@angular/router';
import { DeactivateChildGuard } from '@shared/guards/deactivate-child.guard';
import { ShellComponent } from './shell.component';

export class Shell {
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      runGuardsAndResolvers: 'always',
      canActivateChild: [DeactivateChildGuard],
    };
  }
}
