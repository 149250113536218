import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector:
    'button[mat-flat-button][appIsLoading],button[mat-stroked-button][appIsLoading]',
})
export class LoadingButtonDirective implements OnInit, OnChanges {
  @Input()
  appIsLoading: boolean;

  spinner: HTMLElement;

  constructor(
    private readonly host: ElementRef<HTMLElement>,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnChanges(): void {
    if (this.appIsLoading) {
      this.show();
    } else {
      this.hide();
    }
  }

  show(): void {
    this.renderer.addClass(
      this.host.nativeElement,
      'app-loading-button--loading'
    );
  }

  hide(): void {
    this.renderer.removeClass(
      this.host.nativeElement,
      'app-loading-button--loading'
    );
  }

  ngOnInit(): void {
    this.renderer.addClass(this.host.nativeElement, 'app-loading-button');

    const spinnerFactory =
      this.componentFactoryResolver.resolveComponentFactory(MatSpinner);
    const spinnerRef = this.viewContainerRef.createComponent(spinnerFactory);
    spinnerRef.instance.diameter = 24;
    this.spinner =
      spinnerRef.injector.get(MatSpinner)._elementRef.nativeElement;
    this.renderer.addClass(this.spinner, 'app-loading-button-spinner');
    this.renderer.appendChild(this.host.nativeElement, this.spinner);

    this.host.nativeElement.addEventListener(
      'click',
      (e) => {
        if (this.appIsLoading) {
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      },
      { capture: true }
    );
  }
}
