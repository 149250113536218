import { InjectionToken } from '@angular/core';
import { OperationCode } from '@core/enum';
import { AlertType } from '@model/notifications';

export interface IToolbarButton {
  svgIcon: string;
  titleKey: string;
  link: string;
  order: number;
  operationCode?: OperationCode;
  alertType?: AlertType;
}
export const TOOLBAR_BUTTON = new InjectionToken<IToolbarButton>(
  'TOOLBAR_BUTTON'
);
