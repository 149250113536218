import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { SupportedLanguage } from '@core/enum/supported-language.enum';
import { UntilDestroy, untilDestroyed } from '@core/util/untilDestroyed';
import { INotificationAlerts } from '@model/notifications';
import { AuthService } from '@service/auth/auth.service';
import {
  NAV_MENU_ITEM,
  NavMenuSection,
  NavMenuItem,
  NavMenuItemType,
  IChildNavMenuItem,
} from '@shared/metadata/INavMenuItem';
import { AppTranslateService } from '@shared/services/translate.service';
import { groupBy } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input()
  notificationAlerts: INotificationAlerts;

  @Output() readonly closeMenu = new EventEmitter<void>();

  readonly NavMenuSection = NavMenuSection;
  readonly sections = new Map<NavMenuSection, NavMenuItem[]>();

  readonly NavMenuItemType = NavMenuItemType;

  readonly SupportedLanguage = SupportedLanguage;

  constructor(
    @Inject(NAV_MENU_ITEM) private readonly items: NavMenuItem[],
    private readonly authService: AuthService,
    readonly translate: AppTranslateService
  ) {}

  ngOnInit(): void {
    combineLatest(
      this.items.map((i) => {
        if (i.type === NavMenuItemType.SingleItem) {
          return this.filterVisibleNavItems([i]);
        }
        return this.filterVisibleNavItems([i]).pipe(
          mergeMap((expandableNavItem) => {
            if (expandableNavItem.length === 0) {
              return of([]);
            }

            return this.filterVisibleNavItems(expandableNavItem[0].items).pipe(
              map((expandableItems) => {
                return {
                  ...expandableNavItem[0],
                  items: expandableItems,
                };
              })
            );
          })
        );
      })
    )
      .pipe(
        map((l) => l.flat()),
        map((l) => groupBy(l.filter(Boolean), (s) => s.section)),
        untilDestroyed(this)
      )
      .subscribe((sections) => {
        for (const [section, sectionItems] of Object.entries(sections)) {
          sectionItems.sort((a, b) => a.order - b.order);
          this.sections.set(section as NavMenuSection, sectionItems);
        }
      });
  }

  onLinkClicked(): void {
    this.closeMenu.emit();
  }

  sectionTitleKey(section: NavMenuSection): string {
    switch (section) {
      case NavMenuSection.Communication:
        return l10n('MENU.SECTIONS.COMMUNICATION');
      case NavMenuSection.SelfServices:
        return l10n('MENU.SECTIONS.SELF_SERVICES');
      case NavMenuSection.Account:
        return l10n('MENU.SECTIONS.ACCOUNT');
      default:
        return '';
    }
  }

  canShowItem(item: NavMenuItem | IChildNavMenuItem): Observable<boolean> {
    if (!item.operationCode) {
      return of(true);
    }
    const opCodes = Array.isArray(item.operationCode)
      ? item.operationCode
      : [item.operationCode];
    return combineLatest(
      opCodes.map((opCode) => {
        return this.authService.canPerformOperation(opCode);
      })
    ).pipe(map((results) => results.some((ok) => ok)));
  }

  filterVisibleNavItems<T extends NavMenuItem | IChildNavMenuItem>(
    items: T[]
  ): Observable<T[]> {
    return combineLatest(
      items.map((i) => this.canShowItem(i).pipe(map((res) => (res ? i : null))))
    ).pipe(map((l) => l.filter(Boolean)));
  }
}
