<mat-sidenav-container class="w-full h-full" [class.hidden]="!isInitialized">
  <div class="flex flex-col w-full h-full">
    <header
      class="sm:h-28 h-14 shadow-md-bottom block relative"
      pattern="error"
    >
      <app-background
        class="absolute top-0 left-0 right-0 bottom-0 overflow-hidden z-[-99999]"
      ></app-background>
      <nav class="h-full flex items-center sm:pl-12 pl-5">
        <a class="flex items-center" href="/">
          <div
            class="flex items-center"
            [ngSwitch]="translate.language$ | async"
          >
            <ng-container *ngSwitchCase="SupportedLanguage.EN">
              <img
                class="w-44 object-contain"
                src="assets/swire_logo-white_en.png"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
              <span class="mx-4 h-7 border-l border-white border-solid"></span>
              <img
                class="w-24"
                src="assets/logo-white.svg"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
            </ng-container>
            <ng-container *ngSwitchCase="SupportedLanguage.ZH_HANT_HK">
              <img
                class="w-24"
                src="assets/swire_logo-white-zh-hant-hk.png"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
              <span class="mx-4 h-7 border-l border-white border-solid"></span>
              <img
                class="w-28"
                src="assets/logo-white-zh-hant-hk.svg"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
            </ng-container>
            <ng-container *ngSwitchCase="SupportedLanguage.ZH_HANS">
              <img
                class="w-24"
                src="assets/swire_logo-white-zh-hans.png"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
              <span class="mx-4 h-7 border-l border-white border-solid"></span>
              <img
                class="w-28"
                src="assets/logo-white-zh-hans.svg"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
            </ng-container>
          </div>
        </a>
      </nav>
    </header>
    <main class="flex-1">
      <router-outlet></router-outlet>
    </main>
  </div>
</mat-sidenav-container>
