import {
  DayType,
  EFormRequestDetails,
  IMonthlyCarParkingRequestOctopusCard,
  IMonthlyCarParkingRequestSmartCard,
  IMonthlyCarParkingRequestSpace,
  IServiceLiftRequestOperatingHour,
  ServiceLiftRequestInOut,
} from '@model/forms/e-form';
import {
  APIDayOfWeek,
  APIEFormSubmissionDetail,
  IAPIEFormSubmissionDetailMonthlyCarParkingParkingSpace,
  IAPIEFormSubmissionDetailMonthlyCarParkingParkingSpaceDetail,
  IAPIServiceLiftRequestOperatingHour,
} from '@service/api/models/e-forms';
import { formatPhone, parsePhone } from '@shared/utils/phone';
import { mapCodeDescription } from './common';

export function mapDay(dayOfWeek: string): DayType {
  switch (dayOfWeek) {
    case APIDayOfWeek.MONDAYS:
      return { type: 'weekday', dayOfWeek: 1 };
    case APIDayOfWeek.TUESDAYS:
      return { type: 'weekday', dayOfWeek: 2 };
    case APIDayOfWeek.WEDNESDAYS:
      return { type: 'weekday', dayOfWeek: 3 };
    case APIDayOfWeek.THURSDAYS:
      return { type: 'weekday', dayOfWeek: 4 };
    case APIDayOfWeek.FRIDAYS:
      return { type: 'weekday', dayOfWeek: 5 };
    case APIDayOfWeek.SATURDAYS:
      return { type: 'weekday', dayOfWeek: 6 };
    case APIDayOfWeek.SUNDAYS:
      return { type: 'weekday', dayOfWeek: 0 };
    case APIDayOfWeek.PUBLIC_HOLIDAYS:
      return { type: 'public-holiday' };
  }
  throw new Error(`Unrecognized day of week ${dayOfWeek}`);
}

export function mapAPIServiceLiftRequestOperatingHour(
  h: IAPIServiceLiftRequestOperatingHour
): IServiceLiftRequestOperatingHour[] {
  if (h.dayOfWeek === APIDayOfWeek.MONDAYS_TO_FRIDAYS) {
    const operatingHours: IServiceLiftRequestOperatingHour[] = [];
    for (let i = 1; i <= 5; i++) {
      operatingHours.push({
        ...h,
        day: { type: 'weekday', dayOfWeek: i },
      });
    }
    return operatingHours;
  }
  return [
    {
      buildingID: h.buildingID,
      day: mapDay(h.dayOfWeek),
      startTime: h.startTime,
      endTime: h.endTime,
    },
  ];
}

export function reverseMapEFormRequestDetails(
  detail: EFormRequestDetails
): APIEFormSubmissionDetail {
  switch (detail.submissionType) {
    case 'SERVICE_LIFT_REQUEST':
      return {
        submissionType: 'SERVICE_LIFT_REQUEST',

        firstName: detail.firstName,
        lastName: detail.lastName,
        contactNo: formatPhone(
          detail.contactNo.areaCode,
          detail.contactNo.phoneNumber
        ),
        from: detail.from.toISOString(),
        to: detail.to.toISOString(),
        transportationCompany: detail.transportationCompany,
        inOut: detail.inOut,
        specialRequirements: detail.specialRequirements,
      };
    case 'GENERIC':
      return { submissionType: 'GENERIC' };
    case 'MONTHLY_CAR_PARKING_REQUEST':
      return {
        submissionType: 'MONTHLY_CAR_PARKING_REQUEST',

        firstName: detail.firstName,
        lastName: detail.lastName,
        contactNo: formatPhone(
          detail.contactNo.areaCode,
          detail.contactNo.phoneNumber
        ),

        // TODO(newman): Add mapper for submit monthly car parking
        parkingSpaces: [],
      };
    default:
      throw new Error('Unknown e-form type');
  }
}

function mapSmartCard(
  apiParkingSpaceDetail: IAPIEFormSubmissionDetailMonthlyCarParkingParkingSpaceDetail
): IMonthlyCarParkingRequestSmartCard {
  return {
    name: apiParkingSpaceDetail.userName,
    gender: mapCodeDescription(apiParkingSpaceDetail.gender),
    userType: mapCodeDescription(apiParkingSpaceDetail.userType),
    carplateNo: apiParkingSpaceDetail.carPlateNo,
    electricVehicleModel: apiParkingSpaceDetail.electricVehicleModel,
  };
}

function mapOctopusCard(
  apiParkingSpaceDetail: IAPIEFormSubmissionDetailMonthlyCarParkingParkingSpaceDetail
): IMonthlyCarParkingRequestOctopusCard {
  return {
    name: apiParkingSpaceDetail.userName,
    gender: mapCodeDescription(apiParkingSpaceDetail.gender),
    userType: mapCodeDescription(apiParkingSpaceDetail.userType),
    carplateNo: apiParkingSpaceDetail.carPlateNo,
    electricVehicleModel: apiParkingSpaceDetail.electricVehicleModel,
    cardNo: apiParkingSpaceDetail.octopusCardNo,
  };
}

// TODO(newman): Remove export when API ready
export function mapParkingSpace(
  apiParkingSpace: IAPIEFormSubmissionDetailMonthlyCarParkingParkingSpace
): IMonthlyCarParkingRequestSpace {
  return {
    optInContactlessParking: apiParkingSpace.optInToContactlessParkingSystem,
    effectiveDate: apiParkingSpace.effectiveDate,
    carType: mapCodeDescription(apiParkingSpace.parkingSpaceType),
    octopusCards: apiParkingSpace.parkingSpaceDetails.map(mapOctopusCard),
    smartCards: apiParkingSpace.parkingSpaceDetails.map(mapSmartCard),
    remarks: apiParkingSpace.remarks,
  };
}

export function mapEFormRequestDetail(
  detail: APIEFormSubmissionDetail
): EFormRequestDetails {
  switch (detail.submissionType) {
    case 'SERVICE_LIFT_REQUEST':
      return {
        submissionType: 'SERVICE_LIFT_REQUEST',
        firstName: detail.firstName,
        lastName: detail.lastName,
        contactNo: parsePhone(detail.contactNo),
        from: new Date(detail.from),
        to: new Date(detail.to),
        inOut: detail.inOut?.map((v) => {
          return v === ServiceLiftRequestInOut.In
            ? ServiceLiftRequestInOut.In
            : ServiceLiftRequestInOut.Out;
        }),
        specialRequirements: detail.specialRequirements,
        transportationCompany: detail.transportationCompany,
      };
    case 'GENERIC':
      return {
        submissionType: 'GENERIC',
      };
    case 'MONTHLY_CAR_PARKING_REQUEST':
      return {
        submissionType: 'MONTHLY_CAR_PARKING_REQUEST',

        firstName: detail.firstName,
        lastName: detail.lastName,
        contactNo: parsePhone(detail.contactNo),

        spaces: detail.parkingSpaces.map(mapParkingSpace),
      };
    default:
      throw new Error('Unknown e-form type');
  }
}
