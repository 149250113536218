import { MatIconRegistry } from '@angular/material/icon';
import { SafeHtml } from '@angular/platform-browser';

function registerSvgIconLiteral(
  registry: MatIconRegistry,
  namespace: string,
  name: string,
  literal: SafeHtml
): void {
  registry.addSvgIconLiteralInNamespace(namespace, name, literal);
}

export function registerStreamlineSvgIconLiteral(
  registry: MatIconRegistry,
  name: string,
  literal: SafeHtml
): void {
  registerSvgIconLiteral(registry, 'streamline', name, literal);
}

export function registerAppSvgIconLiteral(
  registry: MatIconRegistry,
  name: string,
  literal: SafeHtml
): void {
  registerSvgIconLiteral(registry, 'app', name, literal);
}
