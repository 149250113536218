import { FormGroup } from '@angular/forms';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';

const unsavedFormDialogTitleKey = Symbol('UnsavedFormDialog.title');
const unsavedFormDialogContentKey = Symbol('UnsavedFormDialog.content');

export function setUnsavedFormDialogTitleKey(
  form: FormGroup,
  titleKey: string
): void {
  Object.assign(form, { [unsavedFormDialogTitleKey]: titleKey });
}

export function getUnsavedFormDialogTitleKey(form?: FormGroup): string {
  const titleKey =
    form != null ? (form as any)[unsavedFormDialogTitleKey] : null;
  return titleKey || l10n('COMMON.UNSAVED_FORM_CONFIRM.TITLE');
}

export function setUnsavedFormDialogContentKey(
  form: FormGroup,
  titleKey: string
): void {
  Object.assign(form, { [unsavedFormDialogContentKey]: titleKey });
}

export function getUnsavedFormDialogContentKey(form?: FormGroup): string {
  const contentKey =
    form != null ? (form as any)[unsavedFormDialogContentKey] : null;
  return contentKey || l10n('COMMON.UNSAVED_FORM_CONFIRM.CONTENT');
}
