import 'moment/locale/zh-hk';
import 'moment/locale/zh-cn';
import { AppTranslateService } from '@shared/services/translate.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Injectable } from '@angular/core';
import { MatDateFormats } from '@angular/material/core';
import { SupportedLanguage } from '@core/enum';
import moment, { Moment } from 'moment';

// Patch zh locales

// @ts-expect-error
moment.updateLocale('zh-hk', { longDateFormat: { LT: 'A h:mm' } });
// @ts-expect-error
moment.updateLocale('zh-cn', { longDateFormat: { LT: 'A h:mm' } });

export const dateFormat: MatDateFormats = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Injectable({ providedIn: 'root' })
export class AppMomentDateAdaptor extends MomentDateAdapter {
  constructor(translate: AppTranslateService) {
    super(translate.language, {});
    translate.language$.subscribe((lang) => {
      this.setLocale(toMomentLocale(lang));
    });
  }
}

export function toMomentLocale(lang: SupportedLanguage): string {
  switch (lang) {
    case SupportedLanguage.EN:
      return 'en';
    case SupportedLanguage.ZH_HANS:
      return 'zh-cn';
    case SupportedLanguage.ZH_HANT_HK:
      return 'zh-hk';
  }
  throw new Error(`Unrecongized language ${lang}`);
}

export function mergeDateTime(date: Moment, time: string | null): Moment {
  if (!time) {
    return date.clone();
  }
  const dateString = moment(date).format('YYYY-MM-DD');
  return moment(`${dateString}T${time}`, 'YYYY-MM-DD[T]HH:mm:ss');
}

export function roundUpMinutes(value: Moment, intervalMinutes: number): Moment {
  let minutes = value.minutes();
  minutes = Math.ceil(minutes / intervalMinutes) * intervalMinutes;
  return value.clone().minutes(minutes).startOf('minute');
}
