export function safeMapArray<T>(
  listRaw: unknown,
  mapper: (raw: unknown) => T
): T[] {
  if (!Array.isArray(listRaw)) {
    console.error('safeParseArray got non array as argument', listRaw);
    return [];
  }
  const items: T[] = [];
  for (const itemRaw of listRaw) {
    try {
      const mappedItem = mapper(itemRaw);
      items.push(mappedItem);
    } catch (e: unknown) {
      console.error('safeParseArray failed on item', itemRaw, e);
      continue;
    }
  }
  return items;
}

export function mapNullable<T>(
  raw: unknown,
  mapper: (raw: unknown) => T
): T | null {
  if (raw == null) {
    return null;
  }

  const mappedItem = mapper(raw);
  return mappedItem;
}

export function mapNullableArray<T>(
  listRaw: unknown,
  mapper: (raw: unknown) => T
): T[] | null {
  if (listRaw == null) {
    return null;
  }

  const mappedItem = safeMapArray(listRaw, mapper);
  return mappedItem;
}
