import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { UntilDestroy, untilDestroyed } from '@core/util/untilDestroyed';
import { AlertType, INotificationAlerts } from '@model/notifications';
import { ShellService } from '@service/shell/shell.service';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import { AppTranslateService } from '@shared/services/translate.service';
import { logError } from '@shared/utils/log';
import { pathToRegexp } from 'path-to-regexp';
import { BehaviorSubject, combineLatest, merge, EMPTY, Subject } from 'rxjs';
import {
  catchError,
  endWith,
  filter,
  map,
  startWith,
  throttleTime,
} from 'rxjs/operators';

interface IShellDisplayBox {
  display: IShellDisplay | null;
}

@UntilDestroy()
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  readonly ALERTS_API_CALL_DEBOUNCE_TIME = 10000; // 10s
  readonly display$ = new BehaviorSubject<IShellDisplayBox>({ display: null });
  isMenuOpened = false;
  readonly onMenuOpened$ = new Subject<void>();
  readonly notificationAlerts$ = new BehaviorSubject<INotificationAlerts>({
    [AlertType.SALES]: 0,
    [AlertType.BILLING]: 0,
    [AlertType.DOCUMENT]: 0,
    [AlertType.CIRCULAR]: 0,
    [AlertType.FORMS]: 0,
    // FIXME: use correct GPP key
    [AlertType.GPP]: 0,
  });

  constructor(
    private readonly translate: AppTranslateService,
    private readonly router: Router,
    private readonly titleService: Title,
    private readonly shellService: ShellService,
    @Inject(SHELL_DISPLAY) private readonly shellDisplays: IShellDisplay[]
  ) {}

  getActiveDisplay(
    router: Router,
    shellDisplays: IShellDisplay[]
  ): IShellDisplay | null {
    let activeDisplay: IShellDisplay | null = null;
    for (const item of shellDisplays) {
      const regexp: RegExp = pathToRegexp(`${item.route}(/?.*)`);
      if (
        regexp.exec(router.url) &&
        (!activeDisplay || item.route.length > activeDisplay.route.length)
      ) {
        activeDisplay = item;

        if (item.matchAs) {
          const returnTo = item.matchAs(router);
          const overrideItem = shellDisplays.find(
            (sd) => sd.route === returnTo
          );
          if (!overrideItem) {
            continue;
          }

          activeDisplay = {
            route: item.route,
            navTitleKey: overrideItem.navTitleKey,
            navTitleSvgIcon: overrideItem.navTitleSvgIcon,
          };
        }
      }
    }
    return activeDisplay;
  }

  fetchAlerts(): void {
    this.shellService
      .getAlerts()
      .pipe(
        catchError((err) => {
          logError(err);
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((alerts) => {
        this.notificationAlerts$.next(alerts);
      });
  }

  refetchAlerts(): void {
    this.fetchAlerts();
  }

  ngOnInit(): void {
    this.fetchAlerts();

    merge(
      this.onMenuOpened$,
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd))
    )
      .pipe(throttleTime(this.ALERTS_API_CALL_DEBOUNCE_TIME))
      .subscribe(() => {
        this.refetchAlerts();
      });

    this.router.events
      .pipe(
        startWith(0),
        map(() => {
          const activeDisplay = this.getActiveDisplay(
            this.router,
            this.shellDisplays
          );
          return { display: activeDisplay };
        }),
        untilDestroyed(this)
      )
      .subscribe((displayBox) => {
        this.display$.next(displayBox);
      });

    combineLatest([this.display$, this.translate.language$])
      .pipe(untilDestroyed(this), endWith([{ display: null }]))
      .subscribe(([activeDisplayBox]) => {
        const activeDisplay = activeDisplayBox.display;
        const pageTitle = activeDisplay?.navTitleKey
          ? this.translate.instant(activeDisplay.navTitleKey)
          : '';
        const fullTitle =
          pageTitle.length > 0
            ? this.translate.instant(l10n('NAV.TAB_TITLE'), { pageTitle })
            : this.translate.instant(l10n('NAV.DEFAULT_TAB_TITLE'));
        this.titleService.setTitle(fullTitle);
      });
  }

  onCloseMenu(): void {
    this.isMenuOpened = false;
    this.updateBodyScroll();
  }

  onOpenMenu(): void {
    // Prevent restoring focus to button when closing menu.
    (document.activeElement as HTMLElement).blur();
    this.onMenuOpened$.next();
    this.isMenuOpened = true;
    this.updateBodyScroll();
  }

  updateBodyScroll(): void {
    document.body.style.overflow = this.isMenuOpened ? 'hidden' : '';
  }
}
