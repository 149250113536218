import { NgModule } from '@angular/core';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AvatarModule } from 'ngx-avatar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DirectivesModule } from '@shared/directives/directives.module';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MenuComponent } from './menu/menu.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { BackgroundModule } from '@shared/component/common/background/background.module';
import { UnsavedFormDialogModule } from '@shared/component/common/unsaved-form-dialog/unsaved-form-dialog.module';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { HomeMetadataModule } from '@pages/home/home-metadata.module';
import { DocumentMetadataModule } from '@pages/document/document-metadata.module';
import { CircularMetadataModule } from '@pages/circular/circular-metadata.module';
import { TurnoverMetadataModule } from '@pages/turnover/turnover-metadata.module';
import { OTPMetadataModule } from '@pages/otp/otp-metadata.module';
import { ProfileMetadataModule } from '@pages/profile/profile-metadata.module';
import { UserManagmentMetadataModule } from '@pages/user-management/user-management-metadata.module';
import { BillingMetadataModule } from '@pages/billing/billing-metadata.module';
import { NewUserMetadataModule } from '@pages/user-management/new-user/new-user-metadata.module';
import { FormsMetadataModule } from '@pages/forms/forms-metadata.module';
import { EditUserMetadataModule } from '@pages/user-management/edit-user/edit-user-metadata.module';
import { ContactsMetadataModule } from '@pages/contacts/contacts-metadata.module';
import { DialogModule } from '@shared/component/common/dialog/dialog.module';
import { NotificationMetadataModule } from '@pages/notification/notification-metadata.module';
import { BadgeIconComponent } from './badge-icon/badge-icon.component';
import { AcRequestMetadataModule } from '@pages/ac-request/ac-request-metadata.module';
import { SubmissionHistoryMetadataModule } from '@pages/submission-history/submission-history-metadata.module';
import { VisitorAccessMetadataModule } from '@pages/visitor-access/visitor-access-metadata.module';
import { StaffAccessMetadataModule } from '@pages/staff-access/staff-access-metadata.module';
import { StandardFittingMetadataModule } from '@pages/standard-fitting/standard-fitting-metadata.module';
import { FacilityMaintenanceMetadataModule } from '@pages/facility-maintenance/facility-maintenance-metadata.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { RequestsMetadataModule } from '@pages/requests/requests-metadata.module';
import { GreenPerformancePledgeMetadataModule } from '@pages/green-performance-pledge/green-performance-pledge-metadata.module';
import { GPPBaselineSettingsMetadataModule } from '@pages/green-performance-pledge/baseline-settings/baseline-settings-metadata.module';
import { ServiceLiftMetadataModule } from '@pages/service-lift/service-lift-metadata.module';
import { MonthlyCarParkingMetadataModule } from '@pages/monthly-car-parking/monthly-car-parking-metadata.module';
import { DirectorySignageMetadataModule } from '@pages/directory-signage/directory-signage-metadata.module';

@NgModule({
  declarations: [
    ShellComponent,
    HeaderComponent,
    MenuComponent,
    UserMenuComponent,
    ToolbarComponent,
    BadgeIconComponent,
  ],
  imports: [
    LayoutModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    RouterModule,
    TranslateModule,
    BrowserAnimationsModule,
    UnsavedFormDialogModule,
    AvatarModule,
    DirectivesModule,
    HomeMetadataModule,
    DocumentMetadataModule,
    CircularMetadataModule,
    TurnoverMetadataModule,
    OTPMetadataModule,
    ProfileMetadataModule,
    UserManagmentMetadataModule,
    BillingMetadataModule,
    NewUserMetadataModule,
    EditUserMetadataModule,
    FormsMetadataModule,
    ContactsMetadataModule,
    StreamlineIconsModule,
    NotificationMetadataModule,
    AcRequestMetadataModule,
    SubmissionHistoryMetadataModule,
    VisitorAccessMetadataModule,
    ServiceLiftMetadataModule,
    MonthlyCarParkingMetadataModule,
    StaffAccessMetadataModule,
    StandardFittingMetadataModule,
    FacilityMaintenanceMetadataModule,
    GreenPerformancePledgeMetadataModule,
    GPPBaselineSettingsMetadataModule,
    DirectorySignageMetadataModule,
    RequestsMetadataModule,
    DialogModule,
    BackgroundModule,
    MatExpansionModule,
  ],
  providers: [Title],
})
export class ShellModule {}
