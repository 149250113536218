import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from '../dialog/dialog.module';
import { LazyLoadFailDialogComponent } from './lazy-load-fail-dialog.component';

@NgModule({
  declarations: [LazyLoadFailDialogComponent],
  imports: [CommonModule, DialogModule, TranslateModule],
  exports: [LazyLoadFailDialogComponent],
})
export class LazyLoadFailDialogModule {}
