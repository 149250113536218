<app-scaffold
  icon="streamline:app-window-warning"
  [title]="'ERROR.SERVER_ERROR.TITLE' | translate"
  [content]="'ERROR.SERVER_ERROR.CONTENT' | translate"
  [actionLabel]="actionLabel || ('ERROR.GO_HOME' | translate)"
  actionLink="/"
>
  <p
    *ngIf="error"
    class="mt-3 text-xxs tracking-wide8 leading-tight text-placeholder"
  >
    {{ error.code }}
    <ng-container *ngIf="error.traceID">
      <br />
      {{ error.traceID }}
    </ng-container>
    <ng-container *ngIf="error.datetime">
      <br />
      {{ error.datetime | formatDate : 'DD-MM-YYYY HH:mm:ss' }}
    </ng-container>
  </p>
  <p
    *ngIf="showDetail"
    class="max-h-44 bg-gray_light text-secondary_dark text-sm leading-tight tracking-wide5 overflow-y-scroll text-left mt-3 p-4"
  >
    {{ generalError }}
    <br />
    {{ currentTime }}
  </p>

  <button
    *ngIf="generalError"
    class="mt-3 text-sm tracking-wide8 leading-tight text-primary"
    (click)="showHideDetail()"
  >
    {{
      showDetail
        ? ('ERROR.GENERIC_ERROR.LOG.HIDE_DETAIL' | translate)
        : ('ERROR.GENERIC_ERROR.LOG.SHOW_DETAIL' | translate)
    }}
  </button>
</app-scaffold>
