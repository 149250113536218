import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { OperationCode } from '@core/enum';
import { AlertType } from '@model/notifications';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import {
  IToolbarButton,
  TOOLBAR_BUTTON,
} from '@shared/metadata/IToolbarButton';
import { AppTranslateService } from '@shared/services/translate.service';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormsStateService } from './state.service';

const shellDisplayIndex: IShellDisplay = {
  route: '/pages/forms',
  navTitleKey: l10n('FORMS.NAV_TITLE.INDEX'),
  navTitleSvgIcon: 'streamline:paper-write',
};

const toolBarButton: IToolbarButton = {
  svgIcon: 'streamline:paper-write',
  titleKey: l10n('FORMS.TOOLBAR_BUTTON'),
  link: '/pages/forms',
  order: 300,
  operationCode: OperationCode.AccessReadForms,
  alertType: AlertType.FORMS,
};

function shellDisplaySubmitFactory(
  state: FormsStateService,
  translate: AppTranslateService
): IShellDisplay {
  return {
    route: '/pages/forms/submit',
    navTitle$: combineLatest([
      translate.translate(l10n('FORMS.NAV_TITLE.SUBMIT')),
      state.submitNavTitle$,
    ]).pipe(map(([defaultTitle, navTitle]) => navTitle ?? defaultTitle)),
    navTitleKey: l10n('FORMS.NAV_TITLE.SUBMIT'),
    navTitleSvgIcon: 'streamline:paper-write',
    hideNavBar$: of(true),
  };
}

const shellDisplaySubmissionHistory: IShellDisplay = {
  route: '/pages/forms/submission-history',
  navTitleKey: l10n('FORMS.NAV_TITLE.SUBMISSION_HISTORY'),
  navTitleSvgIcon: 'streamline:paper-write',
};

const shellDisplaySubmissionDetail: IShellDisplay = {
  route: 'pages/forms/submission-detail',
  navTitleKey: l10n('FORMS.NAV_TITLE.SUBMISSION_DETAIL'),
  navTitleSvgIcon: 'streamline:common-file-text',
  hideNavBar$: of(true),
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: shellDisplayIndex, multi: true },
    {
      provide: SHELL_DISPLAY,
      useFactory: shellDisplaySubmitFactory,
      deps: [FormsStateService, AppTranslateService],
      multi: true,
    },
    {
      provide: SHELL_DISPLAY,
      useValue: shellDisplaySubmissionHistory,
      multi: true,
    },
    {
      provide: SHELL_DISPLAY,
      useValue: shellDisplaySubmissionDetail,
      multi: true,
    },
    { provide: TOOLBAR_BUTTON, useValue: toolBarButton, multi: true },
  ],
})
export class FormsMetadataModule {}
