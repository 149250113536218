import { BehaviorSubject, Observable, race } from 'rxjs';
import { delay, filter, share, tap } from 'rxjs/operators';

export class Lazy<T> {
  private readonly cachedValue$ = new BehaviorSubject<T | null>(null);
  readonly value$: Observable<T | null>;

  constructor(private readonly load: () => Observable<T>) {
    this.value$ = race(
      this.cachedValue$.pipe(filter((x) => x != null)),
      load().pipe(
        delay(0), // Prevent simultaneous emission of two sync observable
        tap((value) => this.cachedValue$.next(value)),
        share()
      )
    );
  }

  reset(): void {
    this.cachedValue$.next(null);
  }
}
