import { Injectable } from '@angular/core';
import { AuthAPIService, OTPResponse } from '@service/api/auth-api.service';
import { FeatureFlagMap } from '@shared/services/feature-flag.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
class AlertAuthAPIService extends AuthAPIService {
  public requestOTP(): Observable<OTPResponse> {
    return super.requestOTP().pipe(
      tap((resp: any) => {
        if (!resp.otpGenerationWait) {
          alert(`OTP: ${resp.otp}`);
        }
      })
    );
  }
}

export const environment = {
  production: true,
  login_background: [
    '/assets/login/sw-tp-login-img-1.jpg',
    '/assets/login/sw-tp-login-img-2.jpg',
    '/assets/login/sw-tp-login-img-3.jpg',
    '/assets/login/sw-tp-login-img-4.jpg',
    '/assets/login/sw-tp-login-img-5.jpg',
    '/assets/login/sw-tp-login-img-6.jpg',
    '/assets/login/sw-tp-login-img-7.jpg',
    '/assets/login/sw-tp-login-img-8.jpg',
    '/assets/login/sw-tp-login-img-9.jpg',
  ],
  apiEndpoint: 'https://api-dev.swireproperties.com/tenant-portal-tenant-api',
  apiKey: '3TLVZ9uiJJa0qH1wxDvQ32kWPyz1nL2u7qGa1tPs',
  authentication: {
    issuer:
      'https://cognito-idp.ap-southeast-1.amazonaws.com/ap-southeast-1_rUPMHmf4F',
    clientId: '2bm5p3blu9e4eo3ivctmgfjpfj',
    redirectUri: 'https://dev-tenant.swireproperties.com/',
    responseType: 'code',
    scope: 'openid profile email',
    dummyClientSecret: '9jm7guttks9b8t7v028de0f0ekt93bjjd6v2l8pb1f05739ofvp',
  },
  dynatrace: {
    scriptUri:
      'https://js-cdn.dynatrace.com/jstag/1547c029d8c/ruxitagent_ICA2Vfqru_10247220811100421.js',
    config:
      'app=92825f0b0b094266|cors=1|rcdec=1209600000|featureHash=ICA2Vfqru|vcv=2|reportUrl=https://bf50319fdz.bf.dynatrace.com/bf|rdnt=1|uxrgce=1|bp=3|srmcrv=10|cuc=f8jnks00|mel=100000|dpvc=1|ssv=4|lastModification=1663135440178|dtVersion=10247220811100421|srmcrl=1|tp=500,50,0,1|uxdcw=1500|agentUri=https://js-cdn.dynatrace.com/jstag/1547c029d8c/ruxitagent_ICA2Vfqru_10247220811100421.js',
  },
  dpsp: {
    enUS: 'https://www.swireproperties.com/en/data-privacy-and-security-policy/',
    zhHant:
      'https://www.swireproperties.com/zh-hk/data-privacy-and-security-policy/',
    zhHans:
      'https://www.swireproperties.com/zh-cn/data-privacy-and-security-policy/',
  },
  pics: {
    enUS: 'https://www.swireproperties.com/en/pics/',
    zhHant: 'https://www.swireproperties.com/zh-hk/pics/',
    zhHans: 'https://www.swireproperties.com/zh-cn/pics/',
  },
  env: 'DEV',
  providers: [{ provide: AuthAPIService, useClass: AlertAuthAPIService }],
  idleLogout: {
    enabled: true,
    idleSeconds: 5 * 60,
    timeoutSeconds: 10 * 60,
  },
  featureFlags: {
    'gpp-baseline-setting': true,
  } as FeatureFlagMap,
  supportedBrowsers: {
    Edge: 86,
    Firefox: 81,
    Chrome: 80,
    Safari: 13,
    Opera: 71,
  },
};
