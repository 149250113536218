export interface ILease {
  leaseID?: number;
  leaseIdentifier: string;
  leaseNo: string;
  buildingID: number;
  buildingName: string;
  tenantName: string;
  shopName: string | null;
  unitName: string;
  portfolioType: string;
  tenancyType: 'office' | 'f-and-b' | 'retail' | 'other';
  status: LeaseStatus;
  additionalInfo?: { carParkDescription: string | null };
}

export const FAndBTradeCategoryGroupID = 6;

export interface IEditableLease extends ILease {
  editable: boolean;
}

export interface ILeaseWithPeriod extends ILease {
  commencementDate: Date;
  expiryDate: Date;
}

export enum LeaseStatus {
  Active = 'A',
  Expired = 'E',
  New = 'N',
}

export interface ILeaseLike {
  shopName: string | null;
  tenantName: string;
  buildingName: string;
  unitName: string;
  status: LeaseStatus;
}

const statusPriority = {
  [LeaseStatus.Active]: '1',
  [LeaseStatus.New]: '2',
  [LeaseStatus.Expired]: '3',
};

function getLeaseComparisonFields(l: ILeaseLike) {
  return [
    statusPriority[l.status] ?? '',
    l.shopName || l.tenantName,
    l.buildingName,
    l.unitName,
  ];
}

export function getLeaseSortKey(l: ILeaseLike): string {
  return getLeaseComparisonFields(l).join(':');
}

export function compareLease(a: ILeaseLike, b: ILeaseLike): number {
  const aFields = getLeaseComparisonFields(a);
  const bFields = getLeaseComparisonFields(b);
  for (let i = 0; i < aFields.length; i++) {
    const cmp = aFields[i].localeCompare(bFields[i]);
    if (cmp !== 0) {
      return cmp;
    }
  }
  return 0;
}
