import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@core/util/untilDestroyed';
import { findParentDialogRef } from '@shared/utils/dialog';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  labelConfirm: string | null = null;

  @Input()
  labelCancel: string | null = null;

  @Input()
  disableSubmitButton: boolean = false;

  @Input()
  confirmAction: (() => Observable<boolean>) | null = null;

  private dialogRef?: MatDialogRef<unknown>;
  isLoading = false;

  constructor(
    private readonly host: ElementRef<HTMLElement>,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dialogRef = findParentDialogRef(this.host, this.dialog);
  }

  onConfirm(): void {
    if (!this.dialogRef) {
      return;
    }

    if (!this.confirmAction) {
      this.dialogRef.close(true);
      return;
    }

    this.dialogRef.disableClose = true;
    this.isLoading = true;
    this.confirmAction()
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => this.dialogRef?.close(result),
        (_) => this.dialogRef?.close()
      );
  }
}
