import { Component, OnInit } from '@angular/core';
import { SupportedLanguage } from '@core/enum/supported-language.enum';
import { UntilDestroy, untilDestroyed } from '@core/util/untilDestroyed';
import { AuthService } from '@service/auth/auth.service';
import { AppTranslateService } from '@shared/services/translate.service';

@UntilDestroy()
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
  readonly SupportedLanguage = SupportedLanguage;

  isInitialized: boolean = false;

  constructor(
    private readonly auth: AuthService,
    readonly translate: AppTranslateService
  ) {}

  ngOnInit(): void {
    this.auth.isInitialized$
      .pipe(untilDestroyed(this))
      .subscribe((isInitialized) => {
        this.isInitialized = isInitialized;
      });
  }
}
