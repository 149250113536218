import { ILocalizedString } from '@model/common';
import { APIEFormSubmissionDetail } from './e-forms';

export enum APIFormType {
  EForm = 'E_FORM',
  PDF = 'PDF',
}

export interface IAPIFormBuildingAssociation {
  formBuildingID: number;
  buildingID: number;
  editable: boolean;
}

export interface IAPIFormFile {
  version: number;
  formFileID: number;
  fileName: string;
  fileExtension: string;
  fileSize: number;
  fileUrl: string;
}

export interface IAPIForm {
  formID: number;
  name: string;
  category: string;
  description: string;
  starred: boolean;
  isMultipleLease: boolean;
  formType: APIFormType;
  buildings: IAPIFormBuildingAssociation[];
  file: IAPIFormFile | null;
  allowOnlineSubmission: boolean;
  operations: IAPIOperationCodeRef[];

  formItemID: string | null;
  tandCDescription: string | null;
  tandCDescriptionZhHK: string | null;
  tandCDescriptionZhCN: string | null;
}

export interface IAPIOperationCodeRef {
  operationCode: string;
}

export interface IAPIFormCategory {
  code: string;
  description: ILocalizedString;
}

export interface IAPIFormSubmissionStatus {
  code: string;
  description: ILocalizedString;
  labelColour: string;
}

export interface IAPIFormSubmissionLeaseAssociation {
  leaseIdentifier: string;
}

export interface IAPIFormSubmissionFile {
  formSubmissionFileID: number;
  fileName: string;
  fileExtension: string;
  fileSize: number;
}

export interface IAPIFormSubmissionQuery {
  buildingIDs?: number[];
  status?: string;
  leaseIdentifiers?: string[];

  mySubmission?: boolean;
  hideExpiredTenancy?: boolean;

  searchTerm?: string;
  searchFields?: Array<
    | 'LEASE_IDENTIFIER'
    | 'FORM_NAME'
    | 'REMARKS'
    | 'REFERENCE_NO'
    | 'CREATED_DATE'
    | 'PROCESSED_BY'
    | 'FORM_SUBMISSION_TYPE'
    | 'STATUS'
  >;

  sortBy?:
    | 'LEASE_IDENTIFIER'
    | 'FORM_NAME'
    | 'REMARKS'
    | 'REFERENCE_NO'
    | 'CREATED_DATE'
    | 'PROCESSED_BY'
    | 'FORM_SUBMISSION_TYPE'
    | 'STATUS';
  order?: string;

  page?: number;
  limit?: number;
}

export interface IAPIFormSubmission {
  formSubmissionID: number;
  leases: IAPIFormSubmissionLeaseAssociation[];
  files: IAPIFormSubmissionFile[];
  remarks: string;
  createdByName: string;
  createdDate: Date;
  lastUpdatedAt: Date;
  lastUpdatedByName: string;
  isByCurrentUser: boolean;
  status: string;
  reviewedBy: string | null;
  rejectReason: string | null;
  submissionRefNo: string;
  form: IAPIFormSubmissionFormInfo;
  detail: APIEFormSubmissionDetail;
}

export interface IAPIFormSubmissionFormInfo {
  formID: number;
  name: string;
  category: string;
  formType: APIFormType;
  formItemID: string | null;
  tandCDescription: string | null;
  tandCDescriptionZhHK: string | null;
  tandCDescriptionZhCN: string | null;
}

export interface IAPIFormSubmissionRequestFile {
  fileName: string;
  fileSize: number;
  fileExtension: string;
  objectKey: string;
}

export interface IAPIFormSubmissionRequest {
  leaseIdentifiers: string[];
  files: IAPIFormSubmissionRequestFile[];
  remarks: string;
  detail: APIEFormSubmissionDetail;
}

export interface IAPIFormUploadUrl {
  url: string;
  objectKey: string;
}
