<h1 class="text-base font-bold leading-tight tracking-wide10 text-primary_dark">
  {{ title }}
</h1>
<div
  class="content flex-grow font-normal text-sm leading-tight tracking-wide5 text-primary_dark whitespace-pre-line overflow-visible"
  mat-dialog-content
>
  <ng-content></ng-content>
</div>

<div mat-dialog-actions class="justify-end p-0 m-0 min-h-0 mt-8">
  <button
    mat-flat-button
    color="primary"
    (click)="onConfirm()"
    [appIsLoading]="isLoading"
    [disabled]="disableSubmitButton"
  >
    {{ labelConfirm || ('COMMON.DIALOG.CONFIRM' | translate) }}
  </button>
  <button
    *ngIf="labelCancel"
    mat-stroked-button
    class="ml-2"
    [disabled]="isLoading"
    [mat-dialog-close]="false"
  >
    {{ labelCancel }}
  </button>
</div>
