import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge-icon',
  templateUrl: './badge-icon.component.html',
  styleUrls: ['./badge-icon.component.scss'],
})
export class BadgeIconComponent {
  _count: number | null = null;
  displayNumber: number;
  shouldAppendPlusSign: boolean = false;

  @Input()
  maxDisplayNum: 999 | 99 = 99;

  @Input()
  shouldShowDot: boolean = false;

  @Input()
  set count(value: number | null) {
    this._count = value;
    if (!value) {
      return;
    }
    this.shouldAppendPlusSign = value > this.maxDisplayNum;
    this.displayNumber =
      value > this.maxDisplayNum ? this.maxDisplayNum : value;
  }

  get count(): number | null {
    return this._count;
  }
}
