import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Location } from '@angular/common';

export abstract class BaseRouteGuard<T> implements CanDeactivate<T> {
  constructor(readonly location: Location, readonly router: Router) {}

  abstract shouldBlockNavigation(
    component: T,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Promise<boolean> | boolean;

  async canDeactivate(
    component: T,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Promise<boolean> {
    const blockNav = await this.shouldBlockNavigation(
      component,
      currentRoute,
      currentState,
      nextState
    );
    if (blockNav) {
      this.location.go(this.router.url); // workaround: angular#13586
      return false;
    }
    return true;
  }
}
