import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppTranslateService } from '@shared/services/translate.service';
import { MatDialog } from '@angular/material/dialog';
import { UnsavedFormDialogComponent } from './unsaved-form-dialog.component';
import { tap } from 'rxjs/operators';
import {
  getUnsavedFormDialogTitleKey,
  setUnsavedFormDialogTitleKey,
  getUnsavedFormDialogContentKey,
  setUnsavedFormDialogContentKey,
} from './unsaved-form-dialog-content';

export { setUnsavedFormDialogTitleKey, setUnsavedFormDialogContentKey };

@Injectable({ providedIn: 'root' })
export class UnsavedFormDialog {
  private readonly forms = new Set<FormGroup>();
  constructor(
    private readonly translate: AppTranslateService,
    private readonly dialog: MatDialog
  ) {}

  registerForm(form: FormGroup): void {
    this.forms.add(form);
  }

  unregisterForm(form: FormGroup): void {
    this.forms.delete(form);
  }

  async tryLeaveForm(form?: FormGroup): Promise<boolean> {
    if (this.shouldShowAlert(form)) {
      return this.showAlertDialog(form);
    }
    return true;
  }

  shouldShowAlert(form?: FormGroup): boolean {
    for (const f of form ? [form] : this.forms) {
      if (!f.disabled && f.dirty) {
        return true;
      }
    }
    return false;
  }

  async showAlertDialog(target?: FormGroup): Promise<boolean> {
    const dialogRef = this.dialog.open(UnsavedFormDialogComponent);
    dialogRef.componentInstance.form =
      target ?? Array.from(this.forms.values())[0] ?? null;

    return dialogRef
      .afterClosed()
      .pipe(
        tap((leave) => {
          if (leave) {
            for (const form of target ? [target] : this.forms) {
              form.markAsPristine();
            }
          }
        })
      )
      .toPromise();
  }

  alertMessage(target?: FormGroup): string {
    return [
      this.translate.instant(getUnsavedFormDialogTitleKey(target)),
      this.translate.instant(getUnsavedFormDialogContentKey(target)),
    ].join(' ');
  }
}
