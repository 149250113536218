import {
  HttpInterceptor,
  HttpParameterCodec,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class EncodeURIComponentInterceptor
  implements HttpInterceptor, HttpParameterCodec
{
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const params = new HttpParams({
      fromObject: Object.fromEntries(
        req.params.keys().map((key) => [key, req.params.getAll(key)])
      ),
      encoder: this,
    });

    return next.handle(req.clone({ params }));
  }

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
