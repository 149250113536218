import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-scaffold',
  templateUrl: './scaffold.component.html',
  styleUrls: ['./scaffold.component.scss'],
})
export class ScaffoldComponent {
  @Input()
  icon: string;

  @Input()
  title: string;

  @Input()
  content: string;

  @Input()
  actionLabel: string;

  @Input()
  actionLink: string;
}
