import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import {
  IToolbarButton,
  TOOLBAR_BUTTON,
} from '@shared/metadata/IToolbarButton';

const toolBarSupportButton: IToolbarButton = {
  svgIcon: 'streamline:headphones-customer-support-human',
  titleKey: l10n('PROFILE.SUPPORT.TOOLBAR_BUTTON'),
  link: '/pages/profile/support',
  order: 400,
};

const shellDisplay: IShellDisplay = {
  route: '/pages/profile',
  navTitleKey: l10n('PROFILE.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:single-neutral-profile-picture',
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: shellDisplay, multi: true },
    { provide: TOOLBAR_BUTTON, useValue: toolBarSupportButton, multi: true },
  ],
})
export class ProfileMetadataModule {}
