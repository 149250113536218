import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  getUnsavedFormDialogContentKey,
  getUnsavedFormDialogTitleKey,
} from './unsaved-form-dialog-content';

@Component({
  selector: 'app-unsaved-form-dialog',
  templateUrl: './unsaved-form-dialog.component.html',
  styleUrls: ['./unsaved-form-dialog.component.scss'],
})
export class UnsavedFormDialogComponent {
  @Input()
  form: FormGroup | null = null;

  get titleKey(): string {
    return getUnsavedFormDialogTitleKey(this.form);
  }

  get contentKey(): string {
    return getUnsavedFormDialogContentKey(this.form);
  }
}
