import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import {
  NavMenuItem,
  NavMenuItemType,
  NavMenuSection,
  NAV_MENU_ITEM,
} from '@shared/metadata/INavMenuItem';
import {
  IToolbarButton,
  TOOLBAR_BUTTON,
} from '@shared/metadata/IToolbarButton';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { CircularNavService } from './nav.service';
import { OperationCode } from '@core/enum';
import { AlertType } from '@model/notifications';

const toolBarButton: IToolbarButton = {
  svgIcon: 'streamline:envelope',
  titleKey: l10n('CIRCULAR.TOOLBAR_BUTTON'),
  link: '/pages/circulars',
  order: 200,
  operationCode: OperationCode.AccessCircular,
  alertType: AlertType.CIRCULAR,
};

function shellDisplayFactory(nav: CircularNavService): IShellDisplay {
  return {
    route: '/pages/circulars',
    navTitleKey: l10n('CIRCULAR.NAV_TITLE'),
    navTitleSvgIcon: 'streamline:envelope',
    hideNavBar$: nav.hideNavBar$,
  };
}

const navMenuItem: NavMenuItem = {
  type: NavMenuItemType.SingleItem,
  route: '/pages/circulars',
  section: NavMenuSection.Communication,
  titleKey: l10n('CIRCULAR.NAV_TITLE'),
  svgIcon: 'streamline:envelope',
  operationCode: OperationCode.AccessCircular,
  alertType: AlertType.CIRCULAR,
  order: 100,
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: TOOLBAR_BUTTON, useValue: toolBarButton, multi: true },
    {
      provide: SHELL_DISPLAY,
      useFactory: shellDisplayFactory,
      deps: [CircularNavService],
      multi: true,
    },
    { provide: NAV_MENU_ITEM, useValue: navMenuItem, multi: true },
  ],
})
export class CircularMetadataModule {}
