import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsavedFormDialogComponent } from './unsaved-form-dialog.component';
import { DialogModule } from '../dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UnsavedFormDialogComponent],
  imports: [CommonModule, DialogModule, TranslateModule],
  exports: [UnsavedFormDialogComponent],
})
export class UnsavedFormDialogModule {}
