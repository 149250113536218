import { Injectable } from '@angular/core';
import { OperationCode } from '@core/enum';
import { ApiClient } from '@core/http/api-client';
import { RequireResetPasswordError } from '@core/http/errors';
import { IBuilding } from '@model/common';
import { IUserAccessRight } from '@model/user';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import {
  flatMapUserAccessRight,
  mapUserPhoneType,
  mapAPIAuthSubmissionGroup,
  mapAPIAuthSubmissionGroupRole,
  mapAPILeaseFloor,
  mapAPIUser,
} from './mappers/auth-api';
import { mapAPIBuilding } from './mappers/common-api';
import {
  IAPIUserSupportContactType,
  IAuthResponse,
  IGetTenancyListReponse,
  IOTPRequestResponse,
  IOTPValidationRequestParams,
  IOTPValidationResponse,
  IResetPasswordRequestParams,
  IResetPasswordResponse,
  ISignUpRequestParams,
  ISignUpResponse,
  IUserInfoUpdateRequestParams,
  IUserPasswordUpdateRequestParams,
  IUserPreferenceLanguageUpdateRequestParams,
  IUserSupportContactTypeResponse,
  IAPIUserSupportContact,
  IUserSupportContactResponse,
  SignInParams,
  IAPIUserPhoneType,
  IAPIRegisterRequest,
  IAPIAuthSubmissionGroup,
  IAPIAuthSubmissionGroupRole,
  IAPIUserOpCodesResponse,
  APILeaseFloor,
  IAPIUser,
} from './models/auth';
import {
  IAPIBuilding,
  IGetBuildingsResponse,
  IListResponse,
} from './models/common';
import { IAPIAreaCode } from './models/system';

export interface OTPResponse {
  uuid: string;
  expiryMinutes: number;
  otpGenerationWait: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthAPIService {
  constructor(protected readonly http: ApiClient) {}

  public signIn(params: SignInParams): Observable<IAuthResponse> {
    return this.http.post<IAuthResponse>(`login/sign-in`, params).pipe(
      map((res) => {
        if (res.requiredPasswordReset) {
          throw new RequireResetPasswordError();
        }
        return res;
      })
    );
  }

  public signOut(): Observable<any> {
    return this.http.post<any>(`access-control/logout`, {});
  }

  public validateToken(token: string): Observable<boolean> {
    return this.http.get<boolean>('login/validate-token', {
      params: { token },
    });
  }

  public getUserInfoByToken(token: string): Observable<IAPIUser> {
    return this.http
      .get<IAPIUser>('login/user-info', {
        params: { token },
      })
      .pipe(map(mapAPIUser));
  }

  public signUp(params: ISignUpRequestParams): Observable<ISignUpResponse> {
    return this.http.post<ISignUpResponse>('login/sign-up', {
      email: params.email,
      password: params.password,
      token: params.token,
    });
  }

  public requestForgotPassword(email: string): Observable<void> {
    return this.http.post<void>('login/forget-password', {
      email,
    });
  }

  public resetPassword(
    params: IResetPasswordRequestParams
  ): Observable<IResetPasswordResponse> {
    return this.http.post<IResetPasswordResponse>('login/reset-password', {
      email: params.email,
      password: params.password,
      token: params.token,
    });
  }

  public getAccountInfo(): Observable<IAPIUser> {
    return this.http
      .get<any>('access-control/my-account')
      .pipe(map(mapAPIUser));
  }

  public getMyAccountOpCodeBuildings(
    opCodes: (string | OperationCode)[]
  ): Observable<IAPIUserOpCodesResponse> {
    return this.http.get<IAPIUserOpCodesResponse>(
      'access-control/my-account/operation-codes',
      {
        params: {
          operationCodes: opCodes.join(','),
        },
      }
    );
  }

  public requestOTP(): Observable<OTPResponse> {
    return this.http.post('access-control/otp', {}).pipe(
      map((resp: IOTPRequestResponse) => ({
        ...resp,
        expiryMinutes: resp.expiryMinutes,
        uuid: resp.uuid,
      }))
    );
  }

  public validateOTP(
    params: IOTPValidationRequestParams
  ): Observable<string | null> {
    return this.http
      .post<IOTPValidationResponse>('access-control/otp/validate', {
        otp: params.otp,
        uuid: params.uuid,
      })
      .pipe(map((resp) => (resp.success ? resp.token : null)));
  }

  public updateAccountInfo(
    params: IUserInfoUpdateRequestParams
  ): Observable<void> {
    return this.http.put<void>('access-control/my-account', params);
  }

  public changePassword(
    params: IUserPasswordUpdateRequestParams
  ): Observable<void> {
    return this.http.put<void>(
      'access-control/my-account/change-password',
      params
    );
  }

  public updateLanguagePreference(
    params: IUserPreferenceLanguageUpdateRequestParams
  ): Observable<void> {
    return this.http.put<void>(
      'access-control/my-account/preference/language',
      params
    );
  }

  public getUserContacts(): Observable<IAPIUserSupportContact[]> {
    return this.http
      .get<IUserSupportContactResponse>('access-control/my-account/contacts')
      .pipe(
        map((resp) =>
          resp.list.map<IAPIUserSupportContact>((contact) => ({
            buildingID: contact.buildingID,
            contactType: contact.contactType,
            telNo: contact.telNo,
            email: contact.email,
          }))
        )
      );
  }

  public getUserContactTypes(): Observable<IAPIUserSupportContactType[]> {
    return this.http
      .get<IUserSupportContactTypeResponse>(
        'access-control/my-account/contacts/types'
      )
      .pipe(
        map((resp) =>
          resp.list.map<IAPIUserSupportContactType>((type) => ({
            code: type.code,
            description: type.description,
            descriptionZhCN: type.descriptionZhCN,
            descriptionZhHK: type.descriptionZhHK,
          }))
        )
      );
  }

  public getUserBuildings(): Observable<IBuilding[]> {
    return this.http
      .get<IGetBuildingsResponse>('access-control/my-account/buildings')
      .pipe(
        map((resp) =>
          resp.list.map<IBuilding>((building) => ({
            id: building.buildingID,
            name: building.localBuildingName,
          }))
        )
      );
  }

  public getUserTenancies(): Observable<IUserAccessRight[]> {
    return this.http
      .get<IGetTenancyListReponse>('access-control/users/tenancies')
      .pipe(map((resp) => resp.list.flatMap(flatMapUserAccessRight)));
  }

  public getUserPhoneTypes(): Observable<IAPIUserPhoneType[]> {
    return this.http
      .get<IListResponse<any>>('access-control/users/phone-types')
      .pipe(map((resp) => resp.list.map(mapUserPhoneType)));
  }

  public getMasterAccountBuildings(): Observable<IAPIBuilding[]> {
    return this.http
      .get<IListResponse<any>>('login/master-accounts/buildings')
      .pipe(map((resp) => resp.list.map(mapAPIBuilding)));
  }

  public getMasterAccountPhoneTypes(): Observable<IAPIUserPhoneType[]> {
    return this.http
      .get<IListResponse<any>>('login/master-accounts/phone-types')
      .pipe(map((resp) => resp.list.map(mapUserPhoneType)));
  }

  public getMasterAccountAreaCodes(): Observable<IAPIAreaCode[]> {
    return this.http
      .get<IListResponse<any>>('login/master-accounts/area-codes')
      .pipe(
        map((resp) =>
          resp.list.map<IAPIAreaCode>((a) => ({
            tpShow: a.tpShow,
            active: a.active,
            code: a.code,
            description: a.description,
            descriptionZhCN: a.descriptionZhCN,
            descriptionZhHK: a.descriptionZhHK,
          }))
        )
      );
  }

  public registerRequest(request: IAPIRegisterRequest): Observable<any> {
    return this.http.post<any>('login/master-accounts', request);
  }

  public getAuthSubmissionGroupRoles(): Observable<
    IAPIAuthSubmissionGroupRole[]
  > {
    return this.http
      .get<{
        accessRights: any[];
      }>('access-control/my-account')
      .pipe(map((raw) => raw.accessRights.map(mapAPIAuthSubmissionGroupRole)));
  }

  public getAuthSubmissionGroups(): Observable<IAPIAuthSubmissionGroup[]> {
    return this.http
      .get<any>('access-control/my-account/submission-groups')
      .pipe(map((raw) => raw.list.map(mapAPIAuthSubmissionGroup)));
  }

  resendInvitation(userID: number): Observable<void> {
    return this.http
      .post(`login/users/${userID}/invitation`, null)
      .pipe(mapTo(null));
  }

  getLeaseFloors(leaseIdentifiers: string[]): Observable<APILeaseFloor[]> {
    return this.http
      .post<any>('access-control/lease/floors', leaseIdentifiers)
      .pipe(map((raw) => raw.map(mapAPILeaseFloor)));
  }
}
