import { HttpEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NEVER, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  ForbiddenError,
  RequireOTPError,
  ServiceUnavailableError,
  UnauthorizedError,
} from '@core/http/errors';
import { IAPIErrorHandler } from '@core/http/error-handler';
import { AuthService } from '@service/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class APIErrorHandler implements IAPIErrorHandler {
  constructor(
    private readonly router: Router,
    private readonly injector: Injector
  ) {}

  handleError(stream: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {
    const currentUrl = this.router.url;
    return stream.pipe(
      catchError((err) => {
        if (err instanceof UnauthorizedError) {
          // Use injector to avoid circular dependency
          const auth = this.injector.get(AuthService);
          auth.onLoggedOut();
        } else if (err instanceof ForbiddenError) {
          this.router.navigate(['/pages/system/403']);
        } else if (err instanceof ServiceUnavailableError) {
          this.router.navigate(['/pages/system/503']);
        } else if (err instanceof RequireOTPError) {
          sessionStorage.removeItem('otp_token');
          this.router.navigate(['/pages/otp'], {
            queryParams: { returnTo: currentUrl },
            replaceUrl: true,
          });
        } else {
          throw err;
        }

        // Prevent error state from showing while redirecting.
        return NEVER;
      })
    );
  }
}
