import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { OperationCodeExpression } from '@core/util/opCodeExpression';
import { firstValueFrom } from '@core/util/firstValueFrom';
import { AuthService } from '@service/auth/auth.service';

interface IRouteData {
  requiresUser?: boolean;
  requiresOpCode?: OperationCodeExpression;
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.check(route, state);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.check(route, state);
  }

  private async checkAuthn(
    requiresUser: boolean,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isAuthenticated = await firstValueFrom(
      this.authService.isAuthenticated$
    );
    if (!isAuthenticated && requiresUser) {
      this.router.navigate(['pages/login'], {
        queryParams: { returnTo: state.url },
      });
      return false;
    } else if (isAuthenticated && !requiresUser) {
      const url = route.queryParams.returnTo ?? 'pages/home';
      this.router.navigateByUrl(url);
      return false;
    }
    return true;
  }

  private async check(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const data: IRouteData = route.data;

    if (data.requiresUser != null || data.requiresOpCode) {
      const requiresUser = data.requiresUser || data.requiresOpCode != null;
      const authnOk = await this.checkAuthn(requiresUser, route, state);
      if (!authnOk) {
        return false;
      }
    }

    if (data.requiresOpCode) {
      const authzOk = await this.authService.checkAuthz(data.requiresOpCode);
      if (!authzOk) {
        this.router.navigateByUrl('pages/system/403');
        return false;
      }
    }

    return true;
  }
}
