import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiClient } from '@core/http/api-client';
import { APIError } from '@core/http/errors';
import { AuthService } from '@service/auth/auth.service';
import { HandledError } from '@shared/utils/log';
import { toPlainObject } from 'lodash';
import { LazyLoadFailDialogComponent } from '@shared/component/common/lazy-load-fail-dialog/lazy-load-fail-dialog.component';

const errorPageURL = '/pages/system/error';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler extends ErrorHandler {
  private readonly loggedErrors = new Set<string>();

  constructor(
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly auth: AuthService,
    private readonly api: ApiClient,
    private readonly dialog: MatDialog
  ) {
    super();
  }

  handleError(error: any): void {
    if (error[HandledError]) {
      this.logError(error);
      return;
    }

    const chunkFailedMessage = /Loading chunk .* failed/;
    if (error.message.match(chunkFailedMessage)) {
      this.ngZone.run(() => {
        this.dialog.open(LazyLoadFailDialogComponent, {
          disableClose: true,
          closeOnNavigation: false,
        });
      });
      return;
    }

    super.handleError(error);
    if (this.router.url === errorPageURL) {
      return;
    }

    this.logError(error);

    let stateError: unknown = null;
    if (error instanceof APIError) {
      stateError = toPlainObject(error);
    } else {
      stateError = error;
    }
    setTimeout(() => {
      this.ngZone.run(() => {
        this.router.navigateByUrl('pages/system/error', {
          state: { error: stateError },
        });
      });
    }, 0);
  }

  private logError(error: any) {
    const userID = this.auth.getUserIDSync();
    if (userID == null) {
      return;
    }

    interface IErrorLog {
      message: string;
      stack: string;
      userID: string;
      pageUrl: string;
      userAgent: string;
    }
    const log: IErrorLog = {
      message: String(error?.message),
      stack: String(error?.stack),
      userID: String(userID),
      pageUrl: this.router.url,
      userAgent: navigator.userAgent,
    };

    const logKey = `${log.pageUrl}-${log.stack}`;
    if (this.loggedErrors.has(logKey)) {
      return;
    }
    this.loggedErrors.add(logKey);

    this.api.post('system/errors', log).subscribe({
      error: (err) => console.error(err),
    });
  }
}
