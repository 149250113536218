import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseRouteGuard } from './base-route-guard';
import { DismissableModalService } from '@shared/directives/dismissable-modal';

@Injectable({ providedIn: 'root' })
export class ModalRouteGuard extends BaseRouteGuard<unknown> {
  constructor(
    location: Location,
    router: Router,
    private readonly dialog: MatDialog,
    private readonly modals: DismissableModalService
  ) {
    super(location, router);
  }

  private tryDismissModals(isUserNav: boolean) {
    const dismissableModals = Array.from(this.modals.dismissableModals).filter(
      (m) => m.isActive
    );
    if (isUserNav && dismissableModals.length > 0) {
      const topModal = dismissableModals[dismissableModals.length - 1];
      topModal.dismiss.emit();
      return true;
    }
    for (const modal of dismissableModals) {
      modal.dismiss.emit();
    }
    return false;
  }

  private tryDismissDialogs(isUserNav: boolean) {
    if (
      isUserNav &&
      this.dialog.openDialogs.some((dialog) => dialog.disableClose)
    ) {
      return true;
    }
    for (const dialog of this.dialog.openDialogs) {
      dialog.close();
    }
    return false;
  }

  shouldBlockNavigation(): boolean {
    const isUserNav =
      this.router.getCurrentNavigation()?.trigger !== 'imperative';

    if (this.tryDismissModals(isUserNav)) {
      return true;
    }
    if (this.tryDismissDialogs(isUserNav)) {
      return true;
    }
    return false;
  }
}
