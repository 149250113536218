import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { Injectable } from '@angular/core';

export interface OnInitFromRouteCache {
  ngOnInitFromRouteCache(): void;
}

@Injectable()
export class CacheRouteReuseStrategy extends RouteReuseStrategy {
  private storedRouteHandles = new Map<string, DetachedRouteHandle>();

  private getCacheKey(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((el: ActivatedRouteSnapshot) => el.routeConfig?.path ?? '')
      .filter((str) => str.length !== 0)
      .join('');
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.shouldCacheRoute;
  }

  public store(
    route: ActivatedRouteSnapshot,
    detachedTree: DetachedRouteHandle | null
  ): void {
    this.storedRouteHandles.set(this.getCacheKey(route), detachedTree);

    // HACK: Dismiss tooltips manually on route reuse
    // source: https://github.com/angular/components/issues/11478
    document
      .querySelectorAll('mat-tooltip-component')
      .forEach((el) => el.remove());
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.data.shouldCacheRoute) {
      return this.storedRouteHandles.has(this.getCacheKey(route));
    }

    return false;
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const routeHandle = this.storedRouteHandles.get(this.getCacheKey(route));

    const instance = (routeHandle as any)?.componentRef?.instance;
    const routeCacheHitFn = instance?.ngOnInitFromRouteCache;
    if (routeHandle && typeof routeCacheHitFn === 'function') {
      routeCacheHitFn.apply(instance);
    }
    return routeHandle;
  }

  public shouldReuseRoute(
    before: ActivatedRouteSnapshot,
    after: ActivatedRouteSnapshot
  ): boolean {
    return before.routeConfig === after.routeConfig;
  }
}
