export enum RequestModule {
  ACRequest = 'ac-request',
  DirectorySignage = 'directory-signage',
  FacilityMaintenance = 'facility-maintenance',
  MonthlyCarParking = 'monthly-car-parking',
  ServiceLift = 'service-lift',
  StandardFitting = 'standard-fitting',
  StaffAccess = 'staff-access',
  VisitorAccess = 'visitor-access',
  OtherForm = 'form',
}
