import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';

export interface APIErrorResponse {
  timestamp: string;
  errorCode: string;
  params: unknown | null;
  traceID: string | null;
}

export function isAPIErrorResponse(resp: unknown): resp is APIErrorResponse {
  return (
    resp &&
    typeof resp === 'object' &&
    'timestamp' in resp &&
    'errorCode' in resp
  );
}

export class APIError extends Error {
  status: number;
  datetime: Date | null = null;
  get code(): string {
    return this.resp.errorCode;
  }

  get params(): unknown {
    return this.resp.params;
  }

  get traceID(): string | null {
    return this.resp.traceID != null &&
      !this.resp.traceID.split('').every((c) => c === '0')
      ? this.resp.traceID
      : null;
  }

  get isGeneric(): boolean {
    return this.resp.errorCode.endsWith('0000');
  }

  constructor(readonly resp: APIErrorResponse, status?: number) {
    super(resp.errorCode);
    this.name = 'APIError';
    this.status = status;
    if (this.resp.timestamp) {
      const datetime = moment(this.resp.timestamp);
      if (datetime.isValid()) {
        this.datetime = datetime.toDate();
      }
    }
  }
}

export class RequireResetPasswordError extends APIError {
  constructor() {
    super({
      timestamp: '',
      errorCode: 'CUSTOM0000',
      params: null,
      traceID: null,
    });
  }
}

export class UnauthorizedError extends Error {
  status: number;
  constructor(readonly resp: HttpErrorResponse) {
    super(resp.message);
    this.name = 'UnauthorizedError';
    this.status = resp.status;
  }
}

export class ForbiddenError extends Error {
  status: number;
  constructor(readonly resp: HttpErrorResponse) {
    super(resp.message);
    this.name = 'ForbiddenError';
    this.status = resp.status;
  }
}

export class ServiceUnavailableError extends Error {
  status: number;
  constructor(readonly resp: HttpErrorResponse) {
    super(resp.message);
    this.name = 'ServiceUnavailableError';
    this.status = resp.status;
  }
}

export class RequireOTPError extends Error {
  constructor(message: string, readonly status: number) {
    super(message);
    this.name = 'RequireOTPError';
  }
}

export class NetworkError extends Error {
  constructor() {
    super('NetworkError');
  }
}

export class AccessCardBatchUploadTimeoutError extends Error {
  constructor() {
    super('AccessCardBatchUploadTimeoutError');
  }
}
