import { SupportedLanguage } from '@core/enum';
import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';

export class TranslationLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case SupportedLanguage.EN:
        return from(import('@assets/i18n/en.json'));
      case SupportedLanguage.ZH_HANT_HK:
        return from(import('@assets/i18n/zh-hant-hk.json'));
      case SupportedLanguage.ZH_HANS:
        return from(import('@assets/i18n/zh-hans.json'));
    }
    return of({});
  }
}
