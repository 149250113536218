<div
  *ngIf="user$ | async as user"
  class="relative text-white flex items-center"
>
  <button
    class="flex items-center w-full"
    (click)="isMenuActive = !isMenuActive"
  >
    <div
      class="flex sm:text-sm text-xs tracking-wide5 select-none pointer-events-none"
    >
      <ngx-avatar
        [size]="isSmallerScreen ? 24 : 30"
        [style]="{
          font: undefined,
          fontFamily: 'inherit',
          fontSize: 'inherit'
        }"
        [initialsSize]="3"
        [name]="user.displayName"
      ></ngx-avatar>
    </div>

    <div class="user-info">
      <span
        class="text-sm tracking-wide5 leading-tight break-all line-clamp-1"
        >{{ user.displayName }}</span
      >
      <span
        class="text-xs tracking-wide8 leading-tight break-all line-clamp-1"
        >{{ user.email }}</span
      >
    </div>
    <mat-icon class="select-none w-7 h-7 flex items-center justify-center"
      >arrow_drop_down</mat-icon
    >
  </button>
  <ul [ngClass]="{ hidden: !isMenuActive }" class="user-menu">
    <li class="sm:hidden text-primary_dark flex items-center mb-4">
      <div class="flex text-sm tracking-wide5 select-none pointer-events-none">
        <ngx-avatar
          [size]="30"
          [style]="{ 'font-family': 'inherit', 'font-size': 'inherit' }"
          [name]="user.displayName"
        ></ngx-avatar>
      </div>

      <div class="flex flex-col text-left ml-3 mr-2">
        <span
          class="text-sm tracking-wide5 leading-tight break-all line-clamp-1"
          >{{ user.displayName }}</span
        >
        <span
          class="text-xs tracking-wide8 leading-tight break-all line-clamp-1"
          >{{ user.email }}</span
        >
      </div>
    </li>
    <li>
      <a
        mat-button
        class="item"
        routerLink="/pages/profile"
        (click)="isMenuActive = !isMenuActive"
      >
        <mat-icon
          svgIcon="streamline:single-neutral-actions-edit-1"
          [inline]="true"
          class="icon"
        ></mat-icon>
        <span>{{ 'USER_MENU.MY_PROFILE' | translate }}</span>
      </a>
    </li>
    <li>
      <button mat-button class="item" (click)="onLogOut()">
        <mat-icon
          svgIcon="streamline:logout-2"
          [inline]="true"
          class="icon"
        ></mat-icon>
        <span>{{ 'USER_MENU.LOGOUT.TITLE' | translate }}</span>
      </button>
    </li>
  </ul>
</div>

<ng-template #confirmDialog>
  <app-confirm-dialog
    [title]="'USER_MENU.LOGOUT.CONFIRM.TITLE' | translate"
    [labelConfirm]="'ACTIONS.CONFIRM' | translate"
    [labelCancel]="'ACTIONS.CANCEL' | translate"
    [confirmAction]="doLogOut"
  >
  </app-confirm-dialog>
</ng-template>
