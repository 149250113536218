import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { AppTranslateService } from '@shared/services/translate.service';
import { toMomentLocale } from '@shared/utils/moment';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';

l10n('DATE_FORMAT.YEAR_MONTH');
l10n('DATE_FORMAT.YEAR_MONTH_DAY');
l10n('DATE_FORMAT.YEAR_MONTH_DAY_HOUR_MIN');

@Pipe({ name: 'formatDate', pure: false })
export class FormatDatePipe implements PipeTransform, OnDestroy {
  subscription?: Subscription;

  constructor(
    private readonly translate: AppTranslateService,
    private readonly changes: ChangeDetectorRef
  ) {}

  transform(value: Date | Moment | null | undefined, format: string): string {
    if (value == null) {
      return null;
    }

    if (!this.subscription) {
      this.subscription = this.translate.language$.subscribe(() =>
        this.changes.markForCheck()
      );
    }

    return moment(value)
      .locale(toMomentLocale(this.translate.language))
      .format(format);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
