import moment from 'moment';

/**
 * Parse date-time according to https://swagger.io/specification/#data-types
 */
export function parseDatetime(datetimeString: string): Date {
  return new Date(datetimeString);
}

/**
 * Parse date according to https://swagger.io/specification/#data-types
 */
export function parseDate(date: string): Date {
  return moment(date, 'YYYY-MM-DD').toDate();
}
