import {
  IAPIBuilding,
  IAPINotification,
  IAPINotificationAlert,
  IAPINotificationLease,
  IAPINotificationType,
} from '../models/notifications';
import { parseDatetime } from '../utils/parser';

export function mapAPINotificationBuilding(building: any): IAPIBuilding {
  return {
    buildingID: building.buildingID,
    localBuildingName: building.localBuildingName,
  };
}

export function mapAPINotificationLease(lease: any): IAPINotificationLease {
  return {
    leaseIdentifier: lease.leaseIdentifier,
    currentTenantName: lease.currentTenantName,
    currentShopList: lease.currentShopList,
    unitName: lease.unitName,
    portfolioType: lease.portfolioType,
  };
}

export function mapAPINotification(notification: any): IAPINotification {
  return {
    notificationID: notification.notificationID,
    notificationType: notification.notificationType,
    description: notification.description,
    date: parseDatetime(notification.date),
    buildings: notification.buildings?.map(mapAPINotificationBuilding) ?? [],
    leases: notification.leases?.map(mapAPINotificationLease) ?? [],
    unread: notification.unread,
    linkType: notification.linkType,
    linkID: notification.linkID,
    submissionGroupInfo: notification.submissionGroupInfo,
  };
}

export function mapAPINotificationType(data: any): IAPINotificationType {
  return {
    code: data.code,
    description: data.description,
    descriptionZhHK: data.descriptionZhHK,
    descriptionZhCN: data.descriptionZhCN,
    sequence: data.sequence,
    labelColour: data.labelColour,
  };
}

export function mapAPINotificationAlert(alert: any): IAPINotificationAlert {
  return {
    count: alert.count,
    type: alert.type,
  };
}
