import { APIError } from '@core/http/errors';
import { compareLease, ILease } from '@model/common';
import {
  FormType,
  IDuplicatedSubmissionErrorParams,
  IForm,
  IFormSubmission,
  IFormSubmissionFormInfo,
  IFormSubmissionStatus,
} from '@model/forms/v2';
import {
  APIFormType,
  IAPIForm,
  IAPIFormSubmission,
  IAPIFormSubmissionFormInfo,
  IAPIFormSubmissionStatus,
} from '@service/api/models/forms-v2';
import { mustGet } from '@shared/utils/map';
import { uniq } from 'lodash';
import { mapEFormRequestDetail } from './e-forms';

export function mapForm(form: IAPIForm, buildings: Map<number, string>): IForm {
  const editableBuildings = form.buildings
    .filter((b) => b.editable)
    .map((b) => buildings.get(b.buildingID));

  const buildingNames = uniq(
    form.buildings
      .map((b) => buildings.get(b.buildingID))
      .filter((name) => !!name)
  ).sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));

  const formBuildings = buildingNames.map((name) => ({
    name,
    canSubmit: editableBuildings.includes(name) && form.allowOnlineSubmission,
  }));

  return {
    id: form.formID,
    buildings: formBuildings,
    name: form.name,
    description: form.description,
    starred: form.starred,
    type: mapFormType(form.formType),
    categoryCode: form.category,
    isMultipleLease: form.isMultipleLease,
    fileURL: form.file?.fileUrl ?? '',
    operationCodes: form.operations.map((o) => o.operationCode),

    formItemID: form.formItemID ?? '',
    descriptionTAC: {
      enUS: form.tandCDescription,
      zhHant: form.tandCDescriptionZhHK,
      zhHans: form.tandCDescriptionZhCN,
    },
  };
}

export function mapFormType(type: APIFormType): FormType {
  switch (type) {
    case APIFormType.EForm:
      return FormType.EForm;
    case APIFormType.PDF:
      return FormType.PDF;
    default:
      throw new Error(`Unknown form type: ${type}`);
  }
}

export function mapSubmissionStatus(
  apiStatus: IAPIFormSubmissionStatus
): IFormSubmissionStatus {
  return {
    code: apiStatus.code,
    description: apiStatus.description,
    color: apiStatus.labelColour,
  };
}

export function mapSubmission(
  apiSubmission: IAPIFormSubmission,
  leases: Map<string, ILease>
): IFormSubmission {
  return {
    id: apiSubmission.formSubmissionID,
    refNo: apiSubmission.submissionRefNo,
    leases: apiSubmission.leases
      .map((l) => mustGet('lease', leases, l.leaseIdentifier))
      .sort(compareLease),
    documents: apiSubmission.files.map((f) => ({
      id: f.formSubmissionFileID,
      fileName: f.fileName,
      fileExtension: f.fileExtension,
      fileSize: f.fileSize,
    })),
    remarks: apiSubmission.remarks,
    submittedBy: apiSubmission.createdByName,
    submittedAt: apiSubmission.createdDate,
    lastUpdatedAt: apiSubmission.createdDate ?? apiSubmission.lastUpdatedAt,
    lastUpdatedByName:
      apiSubmission.lastUpdatedByName ?? apiSubmission.createdByName,
    isMySubmission: apiSubmission.isByCurrentUser,
    statusCode: apiSubmission.status,
    reviewedBy: apiSubmission.reviewedBy,
    rejectReason: apiSubmission.rejectReason,
    form: mapFormSubmissionInfo(apiSubmission.form),
    detail: mapEFormRequestDetail(apiSubmission.detail),
  };
}

function mapFormSubmissionInfo(
  form: IAPIFormSubmissionFormInfo
): IFormSubmissionFormInfo {
  return {
    id: form.formID,
    name: form.name,
    type: mapFormType(form.formType),
    categoryCode: form.category,

    formItemID: form.formItemID ?? '',
    descriptionTAC: {
      enUS: form.tandCDescription,
      zhHans: form.tandCDescriptionZhCN,
      zhHant: form.tandCDescriptionZhHK,
    },
  };
}

export function mapDuplicatedSubmissionErrorParams(
  error: APIError,
  leases: ILease[]
): IDuplicatedSubmissionErrorParams {
  const params = error.params as {
    duplicatedSubmissions: IAPIFormSubmission[];
  };
  const leaseMap = new Map(leases.map((l) => [l.leaseIdentifier, l]));
  const duplicatedSubmissions = params.duplicatedSubmissions.map((s) =>
    mapSubmission(s, leaseMap)
  );
  return { duplicatedSubmissions };
}
