import { HttpParams } from '@angular/common/http';

// eslint-disable-next-line @typescript-eslint/ban-types
export function createHttpParams(params: object): HttpParams {
  const validParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value != null)
  );

  return new HttpParams({
    fromObject: validParams,
  });
}
