import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SupportedLanguage } from '@core/enum/supported-language.enum';
import {
  AccessCardBatchUploadTimeoutError,
  APIError,
  NetworkError,
} from '@core/http/errors';
import { ILocalizedString } from '@model/common';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';

const errorCodeMessageKeys: Record<string, string> = {
  ECMA0000: l10n('ERROR.CODE.ECMA0000'),
  ECMA0001: l10n('ERROR.CODE.ECMA0001'),
  ECMA0002: l10n('ERROR.CODE.ECMA0002'),
  ECMA0003: l10n('ERROR.CODE.ECMA0003'),
  ECMA0004: l10n('ERROR.CODE.ECMA0004'),
  ECMA0005: l10n('ERROR.CODE.ECMA0005'),
  ECMA0006: l10n('ERROR.CODE.ECMA0006'),
  ECMA0007: l10n('ERROR.CODE.ECMA0007'),
  ECMA0008: l10n('ERROR.CODE.ECMA0008'),
  ECMA0009: l10n('ERROR.CODE.ECMA0009'),
  ECMA0010: l10n('ERROR.CODE.ECMA0010'),
  ECMA0011: l10n('ERROR.CODE.ECMA0011'),
  ECMA0012: l10n('ERROR.CODE.ECMA0012'),
  ECMA0013: l10n('ERROR.CODE.ECMA0013'),
  ECMA0014: l10n('ERROR.CODE.ECMA0014'),
  ECMA0015: l10n('ERROR.CODE.ECMA0015'),
  ECMA0016: l10n('ERROR.CODE.ECMA0016'),
  ECMA0017: l10n('ERROR.CODE.ECMA0017'),
  ECMA0018: l10n('ERROR.CODE.ECMA0018'),
  ECMA0019: l10n('ERROR.CODE.ECMA0019'),
  ECMA0020: l10n('ERROR.CODE.ECMA0020'),
  ECMA0021: l10n('ERROR.CODE.ECMA0021'),
  ECMA0022: l10n('ERROR.CODE.ECMA0022'),
  ECMA0023: l10n('ERROR.CODE.ECMA0023'),
  ECMA0024: l10n('ERROR.CODE.ECMA0024'),
  ECMA0025: l10n('ERROR.CODE.ECMA0025'),
  ECMA0026: l10n('ERROR.CODE.ECMA0026'),
  ECMA0027: l10n('ERROR.CODE.ECMA0027'),
  ECMA0028: l10n('ERROR.CODE.ECMA0028'),
  ECMA0029: l10n('ERROR.CODE.ECMA0029'),
  ECMA0030: l10n('ERROR.CODE.ECMA0030'),
  ECMA0031: l10n('ERROR.CODE.ECMA0031'),
  ECMA0032: l10n('ERROR.CODE.ECMA0032'),
  ECMA0033: l10n('ERROR.CODE.ECMA0033'),
  ECMA0034: l10n('ERROR.CODE.ECMA0034'),
  ECMA0035: l10n('ERROR.CODE.ECMA0035'),
  ECMA0036: l10n('ERROR.CODE.ECMA0036'),
  ECMA0037: l10n('ERROR.CODE.ECMA0037'),
  ECMA0038: l10n('ERROR.CODE.ECMA0038'),
  ECMA0039: l10n('ERROR.CODE.ECMA0039'),
  ECMA0040: l10n('ERROR.CODE.ECMA0040'),
  ECMA1000: l10n('ERROR.CODE.ECMA1000'),
  ECMA1001: l10n('ERROR.CODE.ECMA1001'),
  ECMA1002: l10n('ERROR.CODE.ECMA1002'),
  ECMA1003: l10n('ERROR.CODE.ECMA1003'),
  ECMA1004: l10n('ERROR.CODE.ECMA1004'),
  ECMA1005: l10n('ERROR.CODE.ECMA1005'),
  ECTE0001: l10n('ERROR.CODE.ECTE0001'),
  ECTE0002: l10n('ERROR.CODE.ECTE0002'),
  ECTE0003: l10n('ERROR.CODE.ECTE0003'),
  ECTE0004: l10n('ERROR.CODE.ECTE0004'),
  ECTE0005: l10n('ERROR.CODE.ECTE0005'),
  ECTE0006: l10n('ERROR.CODE.ECTE0006'),
  ECTE0007: l10n('ERROR.CODE.ECTE0007'),
  ECTE0008: l10n('ERROR.CODE.ECTE0008'),
  ECTE0009: l10n('ERROR.CODE.ECTE0009'),
  ECTE0010: l10n('ERROR.CODE.ECTE0010'),
  ECTE0011: l10n('ERROR.CODE.ECTE0011'),
  ECTE0012: l10n('ERROR.CODE.ECTE0012'),
  ECTE0013: l10n('ERROR.CODE.ECTE0013'),
  ECTE0014: l10n('ERROR.CODE.ECTE0014'),
  ECTE0015: l10n('ERROR.CODE.ECTE0015'),
  ECTE0016: l10n('ERROR.CODE.ECTE0016'),
  ECTE0017: l10n('ERROR.CODE.ECTE0017'),
  ECTE0018: l10n('ERROR.CODE.ECTE0018'),
  ECTE0019: l10n('ERROR.CODE.ECTE0019'),
  ECTE0020: l10n('ERROR.CODE.ECTE0020'),
  ECTE0021: l10n('ERROR.CODE.ECTE0021'),
  ECTE0022: l10n('ERROR.CODE.ECTE0022'),
  ECTE0028: l10n('ERROR.CODE.ECTE0028'),
  ECTE0029: l10n('ERROR.CODE.ECTE0029'),
  ECTE0032: l10n('ERROR.CODE.ECTE0032'),
  ECTE0033: l10n('ERROR.CODE.ECTE0033'),
  ECTE0036: l10n('ERROR.CODE.ECTE0036'),
  ECTE0049: l10n('ERROR.CODE.ECTE0049'),
  ECTE0050: l10n('ERROR.CODE.ECTE0050'),
  ECTE0051: l10n('ERROR.CODE.ECTE0051'),
  ECTE0052: l10n('ERROR.CODE.ECTE0052'),
  ECTE0053: l10n('ERROR.CODE.ECTE0053'),
  ECTE0054: l10n('ERROR.CODE.ECTE0054'),
  ECTE0055: l10n('ERROR.CODE.ECTE0055'),
  ECTE0057: l10n('ERROR.CODE.ECTE0057'),
  ECTE0058: l10n('ERROR.CODE.ECTE0058'),
  ECTE0065: l10n('ERROR.CODE.ECTE0065'),
  ECTE1000: l10n('ERROR.CODE.ECTE1000'),
  ECTE1001: l10n('ERROR.CODE.ECTE1001'),
  EDAC0000: l10n('ERROR.CODE.EDAC0000'),
  EDAC0001: l10n('ERROR.CODE.EDAC0001'),
  EDAC0002: l10n('ERROR.CODE.EDAC0002'),
  EDAC0003: l10n('ERROR.CODE.EDAC0003'),
  EDAC0004: l10n('ERROR.CODE.EDAC0004'),
  EDCO0000: l10n('ERROR.CODE.EDCO0000'),
  EDCO0001: l10n('ERROR.CODE.EDCO0001'),
  EDCO0002: l10n('ERROR.CODE.EDCO0002'),
  EDCO0003: l10n('ERROR.CODE.EDCO0003'),
  EDCO0004: l10n('ERROR.CODE.EDCO0004'),
  EDCO0005: l10n('ERROR.CODE.EDCO0005'),
  EDCO0006: l10n('ERROR.CODE.EDCO0006'),
  EDCO0007: l10n('ERROR.CODE.EDCO0007'),
  EDCO0008: l10n('ERROR.CODE.EDCO0008'),
  EDCO0009: l10n('ERROR.CODE.EDCO0009'),
  EDCO0010: l10n('ERROR.CODE.EDCO0010'),
  EDCO0011: l10n('ERROR.CODE.EDCO0011'),
  EDCO0012: l10n('ERROR.CODE.EDCO0012'),
  EDCO0013: l10n('ERROR.CODE.EDCO0013'),
  EDCO0014: l10n('ERROR.CODE.EDCO0014'),
  EDCO0015: l10n('ERROR.CODE.EDCO0015'),
  EDCO0016: l10n('ERROR.CODE.EDCO0016'),
  EDCO0017: l10n('ERROR.CODE.EDCO0017'),
  EDCO0018: l10n('ERROR.CODE.EDCO0018'),
  EDCO0019: l10n('ERROR.CODE.EDCO0019'),
  EDCO0020: l10n('ERROR.CODE.EDCO0020'),
  EDCO0021: l10n('ERROR.CODE.EDCO0021'),
  EDCO0022: l10n('ERROR.CODE.EDCO0022'),
  EDCO0023: l10n('ERROR.CODE.EDCO0023'),
  EDCO0024: l10n('ERROR.CODE.EDCO0024'),
  EDCO0025: l10n('ERROR.CODE.EDCO0025'),
  EDCO0026: l10n('ERROR.CODE.EDCO0026'),
  EDCO0027: l10n('ERROR.CODE.EDCO0027'),
  EDCO0028: l10n('ERROR.CODE.EDCO0028'),
  EDCO0029: l10n('ERROR.CODE.EDCO0029'),
  EDCO0030: l10n('ERROR.CODE.EDCO0030'),
  EDCO0031: l10n('ERROR.CODE.EDCO0031'),
  EDCO0032: l10n('ERROR.CODE.EDCO0032'),
  EDCO0033: l10n('ERROR.CODE.EDCO0033'),
  EDCO0034: l10n('ERROR.CODE.EDCO0034'),
  EDCO0035: l10n('ERROR.CODE.EDCO0035'),
  EDCO0036: l10n('ERROR.CODE.EDCO0036'),
  EDCO0037: l10n('ERROR.CODE.EDCO0037'),
  EDCO0038: l10n('ERROR.CODE.EDCO0038'),
  EDCO0039: l10n('ERROR.CODE.EDCO0039'),
  EDCO0040: l10n('ERROR.CODE.EDCO0040'),
  EDCO0041: l10n('ERROR.CODE.EDCO0041'),
  EDCO1001: l10n('ERROR.CODE.EDCO1001'),
  EDCO1002: l10n('ERROR.CODE.EDCO1002'),
  EDCO1003: l10n('ERROR.CODE.EDCO1003'),
  EDCO1004: l10n('ERROR.CODE.EDCO1004'),
  EDCO1005: l10n('ERROR.CODE.EDCO1005'),
  EDCO1006: l10n('ERROR.CODE.EDCO1006'),
  ESAC0000: l10n('ERROR.CODE.ESAC0000'),
  ESAC0001: l10n('ERROR.CODE.ESAC0001'),
  ESAC0002: l10n('ERROR.CODE.ESAC0002'),
  ESAC0003: l10n('ERROR.CODE.ESAC0003'),
  ESAC0004: l10n('ERROR.CODE.ESAC0004'),
  ESAC0005: l10n('ERROR.CODE.ESAC0005'),
  ESAC0006: l10n('ERROR.CODE.ESAC0006'),
  ESAC0007: l10n('ERROR.CODE.ESAC0007'),
  ESAC0008: l10n('ERROR.CODE.ESAC0008'),
  ESAC0009: l10n('ERROR.CODE.ESAC0009'),
  ESAC0010: l10n('ERROR.CODE.ESAC0010'),
  ESAC0011: l10n('ERROR.CODE.ESAC0011'),
  ESAC0012: l10n('ERROR.CODE.ESAC0012'),
  ESAC0013: l10n('ERROR.CODE.ESAC0013'),
  ESAC0014: l10n('ERROR.CODE.ESAC0014'),
  ESAC0015: l10n('ERROR.CODE.ESAC0015'),
  ESAC0016: l10n('ERROR.CODE.ESAC0016'),
  ESAC0017: l10n('ERROR.CODE.ESAC0017'),
  ESAC0018: l10n('ERROR.CODE.ESAC0018'),
  ESAC0019: l10n('ERROR.CODE.ESAC0019'),
  ESAC0020: l10n('ERROR.CODE.ESAC0020'),
  ESAC0021: l10n('ERROR.CODE.ESAC0021'),
  ESAC0022: l10n('ERROR.CODE.ESAC0022'),
  ESAC0023: l10n('ERROR.CODE.ESAC0023'),
  ESAC0024: l10n('ERROR.CODE.ESAC0024'),
  ESAC0025: l10n('ERROR.CODE.ESAC0025'),
  ESAC0026: l10n('ERROR.CODE.ESAC0026'),
  ESAC0027: l10n('ERROR.CODE.ESAC0027'),
  ESAC0028: l10n('ERROR.CODE.ESAC0028'),
  ESAC0029: l10n('ERROR.CODE.ESAC0029'),
  ESAC0030: l10n('ERROR.CODE.ESAC0030'),
  ESAC0031: l10n('ERROR.CODE.ESAC0031'),
  ESAC0032: l10n('ERROR.CODE.ESAC0032'),
  ESAC0033: l10n('ERROR.CODE.ESAC0033'),
  ESAC0034: l10n('ERROR.CODE.ESAC0034'),
  ESAC0035: l10n('ERROR.CODE.ESAC0035'),
  ESAC0036: l10n('ERROR.CODE.ESAC0036'),
  ESAC0037: l10n('ERROR.CODE.ESAC0037'),
  ESAC0038: l10n('ERROR.CODE.ESAC0038'),
  ESAC0039: l10n('ERROR.CODE.ESAC0039'),
  ESAC0040: l10n('ERROR.CODE.ESAC0040'),
  ESAC0041: l10n('ERROR.CODE.ESAC0041'),
  ESAC0042: l10n('ERROR.CODE.ESAC0042'),
  ESAC0043: l10n('ERROR.CODE.ESAC0043'),
  ESAC0044: l10n('ERROR.CODE.ESAC0044'),
  ESAC0045: l10n('ERROR.CODE.ESAC0045'),
  ESAC0046: l10n('ERROR.CODE.ESAC0046'),
  ESAC0047: l10n('ERROR.CODE.ESAC0047'),
  ESAC0048: l10n('ERROR.CODE.ESAC0048'),
  ESAC0049: l10n('ERROR.CODE.ESAC0049'),
  ESAC0050: l10n('ERROR.CODE.ESAC0050'),
  ESAC0051: l10n('ERROR.CODE.ESAC0051'),
  ESAC0052: l10n('ERROR.CODE.ESAC0052'),
  ESAC0053: l10n('ERROR.CODE.ESAC0053'),
  ESAC0054: l10n('ERROR.CODE.ESAC0054'),
  ESAC0055: l10n('ERROR.CODE.ESAC0055'),
  ESAC0056: l10n('ERROR.CODE.ESAC0056'),
  ESAC0057: l10n('ERROR.CODE.ESAC0057'),
  ESAC0058: l10n('ERROR.CODE.ESAC0058'),
  ESAC0059: l10n('ERROR.CODE.ESAC0059'),
  ESAC0060: l10n('ERROR.CODE.ESAC0060'),
  ESAC0061: l10n('ERROR.CODE.ESAC0061'),
  ESAC0062: l10n('ERROR.CODE.ESAC0062'),
  ESAC0063: l10n('ERROR.CODE.ESAC0063'),
  ESAC0066: l10n('ERROR.CODE.ESAC0066'),
  ESAD0000: l10n('ERROR.CODE.ESAD0000'),
  ESAD0001: l10n('ERROR.CODE.ESAD0001'),
  ESAD0002: l10n('ERROR.CODE.ESAD0002'),
  ESAD0003: l10n('ERROR.CODE.ESAD0003'),
  ESAD0004: l10n('ERROR.CODE.ESAD0004'),
  ESAD0005: l10n('ERROR.CODE.ESAD0005'),
  ESAD0006: l10n('ERROR.CODE.ESAD0006'),
  ESAD0007: l10n('ERROR.CODE.ESAD0007'),
  ESAD0018: l10n('ERROR.CODE.ESAD0018'),
  ESAD0020: l10n('ERROR.CODE.ESAD0020'),
  ESAE0000: l10n('ERROR.CODE.ESAE0000'),
  ESAE0001: l10n('ERROR.CODE.ESAE0001'),
  ESAE0002: l10n('ERROR.CODE.ESAE0002'),
  ESAE0003: l10n('ERROR.CODE.ESAE0003'),
  ESAE0004: l10n('ERROR.CODE.ESAE0004'),
  ESAE0005: l10n('ERROR.CODE.ESAE0005'),
  ESAE0006: l10n('ERROR.CODE.ESAE0006'),
  ESAE0007: l10n('ERROR.CODE.ESAE0007'),
  ESAE0012: l10n('ERROR.CODE.ESAE0012'),
  ESAE0013: l10n('ERROR.CODE.ESAE0013'),
  ESAE0011: l10n('ERROR.CODE.ESAE0011'),
  ESBI0000: l10n('ERROR.CODE.ESBI0000'),
  ESBI0001: l10n('ERROR.CODE.ESBI0001'),
  ESBI0002: l10n('ERROR.CODE.ESBI0002'),
  ESBI0003: l10n('ERROR.CODE.ESBI0003'),
  ESBI0004: l10n('ERROR.CODE.ESBI0004'),
  ESBI0005: l10n('ERROR.CODE.ESBI0005'),
  ESBI0006: l10n('ERROR.CODE.ESBI0006'),
  ESBU0000: l10n('ERROR.CODE.ESBU0000'),
  ESBU0001: l10n('ERROR.CODE.ESBU0001'),
  ESBU0002: l10n('ERROR.CODE.ESBU0002'),
  ESBU0003: l10n('ERROR.CODE.ESBU0003'),
  ESBU0004: l10n('ERROR.CODE.ESBU0004'),
  ESBU0005: l10n('ERROR.CODE.ESBU0005'),
  ESBU0006: l10n('ERROR.CODE.ESBU0006'),
  ESBU0007: l10n('ERROR.CODE.ESBU0007'),
  ESBU0008: l10n('ERROR.CODE.ESBU0008'),
  ESBU0009: l10n('ERROR.CODE.ESBU0009'),
  ESBU0010: l10n('ERROR.CODE.ESBU0010'),
  ESBU0011: l10n('ERROR.CODE.ESBU0011'),
  ESBU0012: l10n('ERROR.CODE.ESBU0012'),
  ESBU0013: l10n('ERROR.CODE.ESBU0013'),
  ESBU0014: l10n('ERROR.CODE.ESBU0014'),
  ESBU0015: l10n('ERROR.CODE.ESBU0015'),
  ESBU0016: l10n('ERROR.CODE.ESBU0016'),
  ESCI0000: l10n('ERROR.CODE.ESCI0000'),
  ESCI0001: l10n('ERROR.CODE.ESCI0001'),
  ESCI0002: l10n('ERROR.CODE.ESCI0002'),
  ESCI0003: l10n('ERROR.CODE.ESCI0003'),
  ESCI0004: l10n('ERROR.CODE.ESCI0004'),
  ESCI0005: l10n('ERROR.CODE.ESCI0005'),
  ESCI0006: l10n('ERROR.CODE.ESCI0006'),
  ESCI0007: l10n('ERROR.CODE.ESCI0007'),
  ESCI0008: l10n('ERROR.CODE.ESCI0008'),
  ESCI0009: l10n('ERROR.CODE.ESCI0009'),
  ESCI0010: l10n('ERROR.CODE.ESCI0010'),
  ESCI0011: l10n('ERROR.CODE.ESCI0011'),
  ESCI0012: l10n('ERROR.CODE.ESCI0012'),
  ESCI0013: l10n('ERROR.CODE.ESCI0013'),
  ESCI0014: l10n('ERROR.CODE.ESCI0014'),
  ESCI0015: l10n('ERROR.CODE.ESCI0015'),
  ESCI0016: l10n('ERROR.CODE.ESCI0016'),
  ESCI0017: l10n('ERROR.CODE.ESCI0017'),
  ESCI0018: l10n('ERROR.CODE.ESCI0018'),
  ESCI0019: l10n('ERROR.CODE.ESCI0019'),
  ESCI0020: l10n('ERROR.CODE.ESCI0020'),
  ESCI0021: l10n('ERROR.CODE.ESCI0021'),
  ESCI0022: l10n('ERROR.CODE.ESCI0022'),
  ESCI0023: l10n('ERROR.CODE.ESCI0023'),
  ESCI0024: l10n('ERROR.CODE.ESCI0024'),
  ESCI0025: l10n('ERROR.CODE.ESCI0025'),
  ESCI0026: l10n('ERROR.CODE.ESCI0026'),
  ESCI0027: l10n('ERROR.CODE.ESCI0027'),
  ESCI0028: l10n('ERROR.CODE.ESCI0028'),
  ESCI0029: l10n('ERROR.CODE.ESCI0029'),
  ESCI0030: l10n('ERROR.CODE.ESCI0030'),
  ESCI0031: l10n('ERROR.CODE.ESCI0031'),
  ESCI0032: l10n('ERROR.CODE.ESCI0032'),
  ESCO0000: l10n('ERROR.CODE.ESCO0000'),
  ESCO0001: l10n('ERROR.CODE.ESCO0001'),
  ESCO0002: l10n('ERROR.CODE.ESCO0002'),
  ESCO0003: l10n('ERROR.CODE.ESCO0003'),
  ESCO0004: l10n('ERROR.CODE.ESCO0004'),
  ESCT0000: l10n('ERROR.CODE.ESCT0000'),
  ESCT0001: l10n('ERROR.CODE.ESCT0001'),
  ESCT0002: l10n('ERROR.CODE.ESCT0002'),
  ESCT0003: l10n('ERROR.CODE.ESCT0003'),
  ESCT0004: l10n('ERROR.CODE.ESCT0004'),
  ESCT0005: l10n('ERROR.CODE.ESCT0005'),
  ESCT0006: l10n('ERROR.CODE.ESCT0006'),
  ESCT0007: l10n('ERROR.CODE.ESCT0007'),
  ESCT0008: l10n('ERROR.CODE.ESCT0008'),
  ESCT0009: l10n('ERROR.CODE.ESCT0009'),
  ESCT0010: l10n('ERROR.CODE.ESCT0010'),
  ESCT0011: l10n('ERROR.CODE.ESCT0011'),
  ESCT0012: l10n('ERROR.CODE.ESCT0012'),
  ESCT0013: l10n('ERROR.CODE.ESCT0013'),
  ESCT0014: l10n('ERROR.CODE.ESCT0014'),
  ESCT0015: l10n('ERROR.CODE.ESCT0015'),
  ESCT0016: l10n('ERROR.CODE.ESCT0016'),
  ESCT0017: l10n('ERROR.CODE.ESCT0017'),
  ESCT0018: l10n('ERROR.CODE.ESCT0018'),
  ESCT0019: l10n('ERROR.CODE.ESCT0019'),
  ESCT0020: l10n('ERROR.CODE.ESCT0020'),
  ESCT0021: l10n('ERROR.CODE.ESCT0021'),
  ESCT0022: l10n('ERROR.CODE.ESCT0022'),
  ESCT0023: l10n('ERROR.CODE.ESCT0023'),
  ESCT0024: l10n('ERROR.CODE.ESCT0024'),
  ESDO0000: l10n('ERROR.CODE.ESDO0000'),
  ESDO0001: l10n('ERROR.CODE.ESDO0001'),
  ESEV0000: l10n('ERROR.CODE.ESEV0000'),
  ESEV0002: l10n('ERROR.CODE.ESEV0002'),
  ESEV0003: l10n('ERROR.CODE.ESEV0003'),
  ESEV0004: l10n('ERROR.CODE.ESEV0004'),
  ESFO0000: l10n('ERROR.CODE.ESFO0000'),
  ESFO0001: l10n('ERROR.CODE.ESFO0001'),
  ESFO0002: l10n('ERROR.CODE.ESFO0002'),
  ESFO0003: l10n('ERROR.CODE.ESFO0003'),
  ESFO0004: l10n('ERROR.CODE.ESFO0004'),
  ESFO0005: l10n('ERROR.CODE.ESFO0005'),
  ESFO0006: l10n('ERROR.CODE.ESFO0006'),
  ESGP0000: l10n('ERROR.CODE.ESGP0000'),
  ESGP0001: l10n('ERROR.CODE.ESGP0001'),
  ESGP0002: l10n('ERROR.CODE.ESGP0002'),
  ESGP0003: l10n('ERROR.CODE.ESGP0003'),
  ESGP0004: l10n('ERROR.CODE.ESGP0004'),
  ESGP0005: l10n('ERROR.CODE.ESGP0005'),
  ESGP0006: l10n('ERROR.CODE.ESGP0006'),
  ESGP0007: l10n('ERROR.CODE.ESGP0007'),
  ESGP0009: l10n('ERROR.CODE.ESGP0009'),
  ESGP0011: l10n('ERROR.CODE.ESGP0011'),
  ESGP0012: l10n('ERROR.CODE.ESGP0012'),
  ESGP0056: l10n('ERROR.CODE.ESGP0056'),
  ESGP0057: l10n('ERROR.CODE.ESGP0057'),
  ESNO0000: l10n('ERROR.CODE.ESNO0000'),
  ESNO0001: l10n('ERROR.CODE.ESNO0001'),
  ESNO0002: l10n('ERROR.CODE.ESNO0002'),
  ESNO0003: l10n('ERROR.CODE.ESNO0003'),
  ESNO0004: l10n('ERROR.CODE.ESNO0004'),
  ESNO0005: l10n('ERROR.CODE.ESNO0005'),
  ESNO0006: l10n('ERROR.CODE.ESNO0006'),
  ESNO0007: l10n('ERROR.CODE.ESNO0007'),
  ESNO0008: l10n('ERROR.CODE.ESNO0008'),
  ESSA0000: l10n('ERROR.CODE.ESSA0000'),
  ESSA0001: l10n('ERROR.CODE.ESSA0001'),
  ESSA0002: l10n('ERROR.CODE.ESSA0002'),
  ESSA0003: l10n('ERROR.CODE.ESSA0003'),
  ESSA0004: l10n('ERROR.CODE.ESSA0004'),
  ESSA0005: l10n('ERROR.CODE.ESSA0005'),
  ESSA0006: l10n('ERROR.CODE.ESSA0006'),
  ESSA0007: l10n('ERROR.CODE.ESSA0007'),
  ESSA0008: l10n('ERROR.CODE.ESSA0008'),
  ESSA0009: l10n('ERROR.CODE.ESSA0009'),
  ESSA0010: l10n('ERROR.CODE.ESSA0010'),
  ESSF0009: l10n('ERROR.CODE.ESSF0009'),
};

@Injectable({
  providedIn: 'root',
})
export class AppTranslateService {
  private readonly _language = new BehaviorSubject(SupportedLanguage.EN);

  constructor(private readonly ngxTranslate: NgxTranslateService) {
    ngxTranslate.setDefaultLang(SupportedLanguage.EN);
    ngxTranslate.use(this._language.value);
    ngxTranslate.onLangChange.subscribe(() => {
      this._language.next(ngxTranslate.currentLang as SupportedLanguage);
    });
  }

  get language$(): Observable<SupportedLanguage> {
    return this._language;
  }

  get language(): SupportedLanguage {
    return this._language.value;
  }

  setLanguage(language: SupportedLanguage): void {
    this.ngxTranslate.use(language);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  instant(key: string, value?: object): string {
    return this.ngxTranslate.instant(key, value);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  translate(key: string, value?: object): Observable<string> {
    return this.ngxTranslate.stream(key, value);
  }

  localize(s: ILocalizedString): string {
    switch (this.language) {
      case SupportedLanguage.EN:
        return s.enUS ?? '';
      case SupportedLanguage.ZH_HANT_HK:
        return s.zhHant ?? s.enUS ?? '';
      case SupportedLanguage.ZH_HANS:
        return s.zhHans ?? s.enUS ?? '';
      default:
        return s.enUS ?? '';
    }
  }

  isErrorLocalized(errorCode: string): boolean {
    return errorCode in errorCodeMessageKeys;
  }

  localizeErrorTitle(err: unknown): string {
    if (err instanceof NetworkError) {
      return this.instant(l10n('ERROR.NETWORK_ERROR.TITLE'));
    }
    return this.localizeErrorMessage(err);
  }

  localizeErrorMessage(err: unknown): string {
    if (err instanceof APIError) {
      const key = errorCodeMessageKeys[err.code];
      if (key) {
        return this.instant(key, (err.params as any) ?? {});
      }
    } else if (err instanceof HttpErrorResponse) {
      return this.instant(l10n('ERROR.HTTP_ERROR'));
    } else if (err instanceof NetworkError) {
      return this.instant(l10n('ERROR.NETWORK_ERROR.CONTENT'));
    } else if (err instanceof AccessCardBatchUploadTimeoutError) {
      return this.instant(
        l10n('ERROR.ACCESS_CARD_BATCH_UPLOAD_TIMEOUT_ERROR.CONTENT')
      );
    }
    return this.instant(l10n('ERROR.GENERIC_ERROR'));
  }
}
