import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizeErrorPipe } from './localize-error.pipe';

@NgModule({
  declarations: [LocalizeErrorPipe],
  imports: [CommonModule],
  exports: [LocalizeErrorPipe],
})
export class LocalizeErrorModule {}
