<div
  *ngIf="count"
  class="min-w-[1.25rem] h-5 rounded-full bg-secondary_800 flex justify-center items-center text-primary_light px-[0.3125rem] sm:px-1.5"
>
  <span *ngIf="!shouldShowDot">
    <span class="text-sm font-medium">{{ displayNumber }}</span>
    <span
      *ngIf="shouldAppendPlusSign"
      class="text-xs font-semibold tracking-wide"
      >+</span
    >
  </span>
  <span
    *ngIf="shouldShowDot"
    class="w-[0.1875rem] h-[0.1875rem] rounded-full bg-primary_light align-middle"
  ></span>
</div>
