import { HttpErrorResponse } from '@angular/common/http';
import { APIError } from '@core/http/errors';

export const HandledError = Symbol('HandledError');

export function logError(err: unknown): void {
  console.error(err);
  if (!err || err instanceof APIError || err instanceof HttpErrorResponse) {
    return;
  }
  setTimeout(() => {
    throw Object.assign(err, { [HandledError]: true });
  }, 0);
}
