import { InjectionToken } from '@angular/core';
import { OperationCode } from '@core/enum';
import { IUserSupportContact } from '@model/user';

export interface IResetPasswordParams {
  email: string;
  password: string;
  token: string;
}

export interface ISignUpParams {
  email: string;
  password: string;
  token: string;
}

export interface IContactGroup {
  buildingName: string;
  contacts: IUserSupportContact[];
}

export interface IAccessRightFilterOptions {
  buildingName?: string;
  hideExpired?: boolean;
}

export interface OTPResponse {
  uuid: string;
  expiryMinutes: number;
  otpGenerationWait: boolean;
}

export const LOCATION_TOKEN = new InjectionToken<Location>('Location', {
  providedIn: 'root',
  factory(): Location {
    return window.location;
  },
});

export const requestOpcodes: Set<OperationCode> = new Set([
  OperationCode.AccessWriteACRequests,
  OperationCode.AccessReadACRequests,
  OperationCode.AccessWriteFacilityMaintenance,
  OperationCode.AccessReadFacilityMaintenance,
  OperationCode.AccessWriteForms,
  OperationCode.AccessReadForms,
  OperationCode.AccessWriteStaffAccess,
  OperationCode.AccessWriteStaffAccessLeaseFloor,
  OperationCode.AccessReadStaffAccess,
  OperationCode.AccessReadStaffAccessLeaseFloor,
  OperationCode.AccessMassWriteStaffAccess,
  OperationCode.AccessMassWriteStaffAccessLeaseFloor,
  OperationCode.AccessMassDeleteStaffAccess,
  OperationCode.AccessMassDeleteStaffAccessLeaseFloor,
  OperationCode.AccessExportStaffList,
  OperationCode.AccessExportStaffListLeaseFloor,
  OperationCode.AccessWriteStandardFitting,
  OperationCode.AccessReadStandardFitting,
  OperationCode.AccessWriteVisitorAccess,
  OperationCode.AccessWriteVisitorAccessLeaseFloor,
  OperationCode.AccessReadVisitorAccess,
  OperationCode.AccessReadVisitorAccessLeaseFloor,
  OperationCode.AccessWriteDirectorySignage,
  OperationCode.AccessReadDirectorySignage,
  OperationCode.AccessWriteMonthlyCarParking,
  OperationCode.AccessReadMonthlyCarParking,
]);
