import { NgModule } from '@angular/core';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import { MatIconModule } from '@angular/material/icon';

const shellDisplay: IShellDisplay = {
  route: '/pages/otp',
  navTitleKey: null,
  navTitleSvgIcon: null,
  matchAs: (router) => router.routerState.snapshot.root.queryParams.returnTo,
};

@NgModule({
  declarations: [],
  imports: [MatIconModule],
  providers: [{ provide: SHELL_DISPLAY, useValue: shellDisplay, multi: true }],
})
export class OTPMetadataModule {}
