<div class="main-container">
  <header class="sm:h-96 shell-header block relative">
    <app-background
      class="absolute top-0 left-0 right-0 bottom-0 overflow-hidden shell-header__background"
    ></app-background>
    <nav class="nav-container">
      <div
        class="h-full flex flex-row sm:flex-wrap sm:content-start items-center"
      >
        <div class="flex-shrink-0 flex items-center">
          <button
            mat-icon-button
            class="-mt-1 -mr-2.5 sm:-my-2.5 sm:-mx-2.5"
            (click)="openMenu.emit()"
          >
            <mat-icon class="text-white">menu</mat-icon>
          </button>
          <a
            class="pl-6 pt-0.5 items-center sm:flex hidden"
            routerLink="/pages/home"
            [ngSwitch]="translate.language$ | async"
          >
            <ng-container *ngSwitchCase="SupportedLanguage.EN">
              <img
                class="w-44 object-contain"
                src="assets/swire_logo-white_en.png"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
              <span
                class="mx-4 sm:h-7 h-4 border-l border-white border-solid"
              ></span>
              <img
                class="w-24"
                src="assets/logo-white.svg"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
            </ng-container>
            <ng-container *ngSwitchCase="SupportedLanguage.ZH_HANT_HK">
              <img
                class="w-24"
                src="assets/swire_logo-white-zh-hant-hk.png"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
              <span
                class="mx-4 sm:h-7 h-4 border-l border-white border-solid"
              ></span>
              <img
                class="w-28"
                src="assets/logo-white-zh-hant-hk.svg"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
            </ng-container>
            <ng-container *ngSwitchCase="SupportedLanguage.ZH_HANS">
              <img
                class="w-24"
                src="assets/swire_logo-white-zh-hans.png"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
              <span
                class="mx-4 sm:h-7 h-4 border-l border-white border-solid"
              ></span>
              <img
                class="w-28"
                src="assets/logo-white-zh-hans.svg"
                [alt]="'NAV.LOGO_ALT' | translate"
              />
            </ng-container>
          </a>
        </div>
        <h1
          class="text-white text-2xl leading-tight font-light tracking-wide5 flex items-center sm:mt-4 sm:font-medium sm:order-last sm:w-full"
        >
          <span class="w-5 h-5 sm:flex hidden"
            ><mat-icon
              class="icon"
              [inline]="true"
              [svgIcon]="navTitleSvgIcon"
            ></mat-icon
          ></span>
          <span appHoverTooltip class="ml-2 sm:ml-6 line-clamp-1 break-all">{{
            navTitle
          }}</span>
        </h1>
        <div
          class="flex-auto flex-shrink-0 sm:flex-1 flex justify-end pl-6 min-w-0"
        >
          <app-user-menu class="min-w-0"></app-user-menu>
        </div>
      </div>
    </nav>
  </header>
</div>
