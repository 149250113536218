import { IPhone } from '@model/common';
import { AppRegex } from './regex';

export const areaCodeOthers = 'OTHERS';

export function sanitizePhone(
  phone: IPhone | null,
  allowedAreaCodes: string[]
): IPhone | null {
  if (!phone) {
    return null;
  }
  if (
    phone.areaCode !== areaCodeOthers &&
    !allowedAreaCodes.includes(phone.areaCode)
  ) {
    return {
      areaCode: areaCodeOthers,
      phoneNumber: `+${phone.areaCode}-${phone.phoneNumber}`,
    };
  }

  return {
    areaCode: phone.areaCode,
    phoneNumber: phone.phoneNumber,
  };
}

export function parsePhone(phone: string): IPhone {
  const userPhoneRegex = AppRegex.PHONE_NUMBER.exec(phone);
  const parsedPhone = userPhoneRegex?.length
    ? {
        areaCode: userPhoneRegex[1],
        phoneNumber: userPhoneRegex[2],
      }
    : {
        phoneNumber: phone,
        areaCode: areaCodeOthers,
      };

  return parsedPhone;
}

export function formatPhone(
  areaCode: string | null,
  phoneNumber: string
): string {
  if (!areaCode || areaCode === areaCodeOthers) {
    return phoneNumber;
  }
  return `+${areaCode}-${phoneNumber}`;
}

export function formatDisplayPhone(
  areaCode: string | null,
  phoneNumber: string
): string {
  if (!areaCode || areaCode === areaCodeOthers) {
    return phoneNumber;
  }
  return `+${areaCode} ${phoneNumber}`;
}
