import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@core/util/untilDestroyed';
import { IUserManagementUser } from '@service/api/models/user-management';
import { AuthService } from '@service/auth/auth.service';
import { observeScreenSize, ScreenSize } from '@shared/utils/screen-size';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  isMenuActive = false;
  isSmallerScreen = false;

  @ViewChild('confirmDialog')
  confirmDialog: TemplateRef<unknown>;

  constructor(
    private readonly breakpoint: BreakpointObserver,
    private readonly dialog: MatDialog,
    private authService: AuthService,
    private ref: ElementRef<HTMLElement>
  ) {}

  get user$(): Observable<IUserManagementUser> {
    return this.authService.authenticatedUser$;
  }

  ngOnInit(): void {
    observeScreenSize(this.breakpoint)
      .pipe(untilDestroyed(this))
      .subscribe((size) => {
        this.isSmallerScreen = size <= ScreenSize.XS;
      });
  }

  @HostListener('document:click', ['$event'])
  clickedOut(e: Event): void {
    if (!this.ref.nativeElement.contains(e.target as HTMLElement)) {
      this.isMenuActive = false;
    }
  }
  onLogOut(): void {
    this.isMenuActive = false;
    this.dialog.open(this.confirmDialog, { closeOnNavigation: false });
  }
  doLogOut = (): Observable<boolean> => {
    return this.authService.logout();
  };
}
