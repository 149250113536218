import { pipe } from 'fp-ts/function';
import { contramap } from 'fp-ts/lib/Ord';
import { Ord as numberOrd } from 'fp-ts/number';

export interface ISequenceComparable {
  sequence: number;
}

export function compareSequence<T extends ISequenceComparable>(
  lhs: T,
  rhs: T
): number {
  if (lhs.sequence < rhs.sequence) {
    return -1;
  } else if (lhs.sequence > rhs.sequence) {
    return 1;
  }
  return 0;
}

export const sequenceOrd = pipe(
  numberOrd,
  contramap((i: ISequenceComparable) => i.sequence)
);
