import { IUserAccessRight } from '@model/user';
import { mapLease, mapRole } from '@service/mappers/common';
import {
  safeMapArray,
  mapNullable,
  mapNullableArray,
} from '@shared/utils/mapper';
import {
  APIRoleACLType,
  IAPIRole,
  IAPIUser,
  IAPIUserAccessRight,
  IAPIAuthSubmissionGroup,
  IAPIUserPhone,
  IAPIAuthSubmissionGroupRole,
  APILeaseFloorCodec,
  IAPIUserACL,
  IAPIUserACLRole,
  IAPIUserACLEntitlement,
  IAPIUserPreference,
  IAPIUserPhoneType,
} from '../models/auth';
import { makeImpureDecode } from './iots';

export function flatMapUserAccessRight(right: any): IUserAccessRight[] {
  if (!right.leaseIdentifier) {
    // Ignore any rights without leaseIdentifier, such as gpp rights
    return [];
  }
  return [
    {
      lease: mapLease({
        currentShopList: right.shopName,
        unitName: right.unitName,
        building: {
          buildingID: right.buildingID,
          localBuildingName: right.localBuildingName,
        },
        portfolioType: right.portfolioType,
        tradeCategoryGroupID: right.tradeCategoryGroupID,
        leaseIdentifier: right.leaseIdentifier,
        leaseNo: right.leaseNo,
        currentTenantName: right.tenantName,
        status: right.status,
        additionalInfo: right.additionalInfo,
      }),
      roles: ((right.roles as any[]) ?? []).map(mapRole).sort((a, b) =>
        a.description.enUS.localeCompare(b.description.enUS, 'en', {
          sensitivity: 'base',
        })
      ),
      isTenantAdmin: right.tenantAdmin,
    },
  ];
}

export function mapUserPhoneType(t: any): IAPIUserPhoneType {
  return {
    tpShow: t.tpShow,
    active: t.active,
    phoneType: t.phoneType,
    description: t.description,
    descriptionZhHK: t.descriptionZhHK,
    descriptionZhCN: t.descriptionZhCN,
    sequence: t.sequence,
  };
}

export function mapAPIUser(raw: any): IAPIUser {
  return {
    version: Number(raw.version),
    userId: Number(raw.userId),
    displayName: String(raw.displayName),
    firstName: String(raw.firstName),
    lastName: String(raw.lastName),
    email: String(raw.email),
    jobTitle: mapNullable(raw.jobTitle, String),
    department: mapNullable(raw.department, String),
    company: mapNullable(raw.company, String),
    remarks: mapNullable(raw.remarks, String),
    status: String(raw.status),
    operationCodes: safeMapArray(raw.operationCodes ?? [], String),
    accessRights: safeMapArray(raw.accessRights, mapAPIUserAccessRight),
    roles: mapNullableArray(raw.roles, mapAPIRole),
    acl: mapNullable(raw.acl, mapAPIUserACL),
    phones: safeMapArray(raw.phones, mapAPIUserPhone),
    hasFullAccess: Boolean(raw.hasFullAccess),
    migratedUser: Boolean(raw.migratedUser),
    preference: mapNullable(raw.preference, mapAPIUserPreference),
  };
}

export function mapAPIUserPreference(raw: any): IAPIUserPreference {
  return {
    lang: raw.lang,
    domainWhitelist: safeMapArray(raw.domainWhitelist, String),
  };
}

export function mapAPIUserACLEntitlement(raw: any): IAPIUserACLEntitlement {
  return {
    gppSubmissionGroupID: mapNullable(raw.gppSubmissionGroupID, Number),
    buildingFloorID: mapNullable(raw.buildingFloorID, String),
    leaseIdentifier: mapNullable(raw.leaseIdentifier, String),
    buildingID: mapNullable(raw.buildingID, String),
  };
}

export function mapAPIUserACLRole(raw: any): IAPIUserACLRole {
  return {
    roleCode: raw.roleCode,
    entitlements: safeMapArray(raw.entitlements, mapAPIUserACLEntitlement),
  };
}

export function mapAPIUserACL(raw: any): IAPIUserACL {
  return {
    roles: safeMapArray(raw.roles, mapAPIUserACLRole),
  };
}

export function mapAPIUserAccessRight(raw: any): IAPIUserAccessRight {
  return {
    buildingID: mapNullable(raw.buildingID, Number),
    buildingName: mapNullable(raw.buildingName, String),
    gppSubmissionGroupID: mapNullable(raw.gppSubmissionGroupID, Number),
    leaseIdentifier: mapNullable(raw.leaseIdentifier, String),
    leaseNo: mapNullable(raw.leaseNo, String),
    localBuildingName: mapNullable(raw.localBuildingName, String),
    portfolioType: mapNullable(raw.portfolioType, String),
    roles: safeMapArray(raw.roles, mapAPIRole),
    shopName: mapNullable(raw.shopName, String),
    tenantName: mapNullable(raw.tenantName, String),
    unitName: mapNullable(raw.unitName, String),
    status: mapNullable(raw.status, String),
    additionalInfo: raw.additionalInfo,
    tenantAdmin: Boolean(raw.tenantAdmin),
  };
}

export function mapAPIRole(raw: any): IAPIRole {
  if (!Object.values(APIRoleACLType).includes(raw.aclType)) {
    throw new TypeError(`Unknown aclType ${raw.aclType}`);
  }
  return {
    roleCode: String(raw.roleCode),
    description: raw.description,
    descriptionZhHK: raw.descriptionZhHK,
    descriptionZhCN: raw.descriptionZhCN,
    allowSetupInTP: Boolean(raw.allowSetupInTP),
    visibleInTP: Boolean(raw.visibleInTP),
    aclType: raw.aclType as APIRoleACLType,
  };
}

export function mapAPIUserPhone(raw: any): IAPIUserPhone {
  return {
    telNo: String(raw.telNo),
    phoneType: String(raw.phoneType),
    sequence: Number(raw.sequence),
  };
}

export function mapAPIAuthSubmissionGroup(raw: any): IAPIAuthSubmissionGroup {
  return {
    companyID: raw.companyID,
    companyName: raw.companyName,
    submissionGroupID: raw.submissionGroupID,
    submissionGroupName: raw.submissionGroupName,
    effectiveMonth: new Date(raw.effectiveMonth),
    expiryMonth: new Date(raw.expiryMonth),
    expiryMonthMode: raw.expiryMonthMode,
    status: {
      code: raw.status,
      description: {
        enUS: raw.statusInfo.description,
        zhHant: raw.statusInfo.descriptionZhHK,
        zhHans: raw.statusInfo.descriptionZhCN,
      },
    },
    buildingIDs: raw.buildingIds ?? [],
    buildingNames: raw.buildings ?? [],
  };
}

export function mapAPIAuthSubmissionGroupRole(
  raw: any
): IAPIAuthSubmissionGroupRole {
  return {
    roles: safeMapArray(raw.roles, mapAPIRole),
    gppSubmissionGroupID: raw.gppSubmissionGroupID,
  };
}

export const mapAPILeaseFloor = makeImpureDecode(APILeaseFloorCodec);
