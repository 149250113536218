import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InvalidLinkComponent } from './invalid-link/invalid-link.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { ScaffoldComponent } from './scaffold/scaffold.component';
import { ServiceUnavailableComponent } from './service-unavailable/service-unavailable.component';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';
import { FormatDatePipeModule } from '@shared/pipes/format-date/format-date.module';

@NgModule({
  declarations: [
    ScaffoldComponent,
    ForbiddenComponent,
    NotFoundComponent,
    InvalidLinkComponent,
    GenericErrorComponent,
    ServiceUnavailableComponent,
    UnsupportedBrowserComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    StreamlineIconsModule,
    FormatDatePipeModule,
  ],
})
export class ErrorPageModule {}
