import { Routes, Route } from '@angular/router';
import { ErrorComponent } from './error.component';

export class ErrorShell {
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ErrorComponent,
      children: routes,
    };
  }
}
