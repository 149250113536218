import { Injectable } from '@angular/core';
import { IEditableBuilding } from '@model/common';
import { RequestModule } from '@model/requests';
import { RequestsAPIService } from '@service/api/requests-api.service';
import { mapEditableBuilding } from '@service/mappers/common';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  private getModuleBuildings$: Observable<
    Record<RequestModule, IEditableBuilding[]>
  > | null = null;

  constructor(private readonly api: RequestsAPIService) {}

  public getModuleBuildings(
    module: RequestModule
  ): Observable<IEditableBuilding[]> {
    return this.getBuildings().pipe(map((buildings) => buildings[module]));
  }

  public getBuildings(): Observable<
    Record<RequestModule, IEditableBuilding[]>
  > {
    if (this.getModuleBuildings$ == null) {
      this.getModuleBuildings$ = this.loadBuildings().pipe(shareReplay(1));
    }
    return this.getModuleBuildings$;
  }

  private loadBuildings(): Observable<
    Record<RequestModule, IEditableBuilding[]>
  > {
    return this.api.getBuildings().pipe(
      map((bs) => ({
        [RequestModule.ACRequest]: bs.ac.map(mapEditableBuilding),
        [RequestModule.DirectorySignage]:
          bs.directorySignage.map(mapEditableBuilding),
        [RequestModule.FacilityMaintenance]:
          bs.facilityMaintenance.map(mapEditableBuilding),
        [RequestModule.MonthlyCarParking]:
          bs.monthlyCarParking.map(mapEditableBuilding),
        [RequestModule.ServiceLift]: bs.serviceLift.map(mapEditableBuilding),
        [RequestModule.StaffAccess]:
          bs.staffAccessCard.map(mapEditableBuilding),
        [RequestModule.StandardFitting]:
          bs.standardFitting.map(mapEditableBuilding),
        [RequestModule.VisitorAccess]: bs.accessCard.map(mapEditableBuilding),
        [RequestModule.OtherForm]: bs.form.map(mapEditableBuilding),
      }))
    );
  }
}
