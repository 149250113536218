import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { IFormCategory } from '@model/forms';
import { IForm } from '@model/forms/v2';
import { FormsV2Service } from '@service/forms/forms-v2.service';
import { Lazy } from '@shared/utils/lazy';
import { OptimisticState } from '@shared/utils/optimistic-state';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, share, startWith, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FormsStateService implements CanDeactivate<any> {
  private readonly forms = new Lazy(() => this.service.getForms());
  private readonly categories = new Lazy(() => this.service.getCategories());
  private readonly starState: OptimisticState<number, boolean>;
  private readonly onStarError = new Subject<unknown>();

  readonly forms$: Observable<IForm[]>;
  readonly categories$: Observable<IFormCategory[]>;
  readonly onStarError$: Observable<unknown> = this.onStarError;

  indexUIState: unknown | null = null;

  readonly submitNavTitle$ = new BehaviorSubject<string | null>(null);

  constructor(private readonly service: FormsV2Service) {
    this.starState = new OptimisticState(new Map(), (id, isStarred) =>
      this.service.setStar(id, isStarred).pipe(
        tap({
          error: (err) => this.onStarError.next(err),
        })
      )
    );
    this.forms$ = combineLatest([
      this.forms.value$,
      this.starState?.stateChange$.pipe(startWith(0)),
    ]).pipe(
      map(([forms]) => {
        return forms.map((f) => ({
          ...f,
          starred: this.starState.get(f.id) ?? f.starred,
        }));
      }),
      share()
    );
    this.categories$ = this.categories.value$;
  }

  resetData(): void {
    this.forms.reset();
    this.categories.reset();
    this.starState.clear();
  }

  setStarred(id: number, isStarred: boolean): void {
    this.starState.set(id, isStarred);
  }

  canDeactivate(): boolean {
    this.resetData();
    this.indexUIState = null;

    return true;
  }
}
