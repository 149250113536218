import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';

const shellDisplay: IShellDisplay = {
  route: '/pages/user-management/edit-user',
  navTitleKey: l10n('USER_MANAGEMENT.EDIT_USER.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:multiple-neutral-1',
};

@NgModule({
  declarations: [],
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [{ provide: SHELL_DISPLAY, useValue: shellDisplay, multi: true }],
})
export class EditUserMetadataModule {}
