import { Injectable } from '@angular/core';
import { IAreaCode, ICodeDescription } from '@model/common';
import { IPublicHoliday } from '@model/common/IPublicHoliday';
import { SystemAPIService } from '@service/api/system-api.service';
import { mapCodeDescriptions } from '@service/mappers/common';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type SystemStatus =
  | { type: 'NORMAL' }
  | { type: 'MAINTENANCE'; start: Date; end: Date };

export type LoginHandler =
  | { type: 'NORMAL' }
  | { type: 'REDIRECT'; url: string };

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(private readonly api: SystemAPIService) {}

  public getAllowedAreaCodes(): Observable<IAreaCode[]> {
    return this.api.getAllowedAreaCodes().pipe(
      map((areaCodes) =>
        mapCodeDescriptions(
          areaCodes.map(({ code, ...rest }) => ({
            code: code.replace('+', ''),
            sequence: 0, // Area code has no sequence
            ...rest,
          }))
        )
      )
    );
  }

  public getStatus(): Observable<SystemStatus> {
    return this.api.getStatus().pipe(
      map((s): SystemStatus => {
        if (s.status === 'MAINTENANCE') {
          return {
            type: 'MAINTENANCE',
            start: s.maintenanceStart,
            end: s.maintenanceEnd,
          };
        }
        return { type: 'NORMAL' };
      })
    );
  }

  public getLoginHandler(): Observable<LoginHandler> {
    return this.api.getLoginHandler().pipe(
      map((h): LoginHandler => {
        if (!h.shouldRedirect) {
          return { type: 'NORMAL' };
        }
        return { type: 'REDIRECT', url: h.redirectURL };
      })
    );
  }

  public getPublicHolidays(): Observable<IPublicHoliday[]> {
    return this.api.getPublicHolidays().pipe(
      map((hs) =>
        hs.map((h) => ({
          date: h.date,
          description: h.description,
        }))
      )
    );
  }

  public getCodeDescriptions(codeKey?: string): Observable<ICodeDescription[]> {
    return this.api
      .getRawCodeDescriptions(codeKey)
      .pipe(map((res) => mapCodeDescriptions(res.list)));
  }

  public getSystemNow(): Observable<Moment> {
    return this.api.getSystemNow();
  }
}
