import {
  ICodeDescription,
  IColorCodeDescription,
  ILease,
  IPhone,
  ISort,
} from '@model/common';
import { QuerySortField } from '@service/monthly-car-parking/monthly-car-parking.service';

export type DayType =
  | { type: 'weekday'; dayOfWeek: number }
  | { type: 'public-holiday' };

export interface IServiceLiftRequestOperatingHour {
  buildingID: number;
  day: DayType;
  startTime: string;
  endTime: string;
}

export enum ServiceLiftRequestInOut {
  In = 'IN',
  Out = 'OUT',
}

export interface IMonthlyCarParkingRequestSpace {
  optInContactlessParking: boolean;
  effectiveDate: Date;
  carType: ICodeDescription;
  octopusCards: IMonthlyCarParkingRequestOctopusCard[];
  smartCards: IMonthlyCarParkingRequestSmartCard[];
  remarks: string;
}

export interface IMonthlyCarParkingRequestOctopusCard {
  name: string;
  gender: ICodeDescription;
  userType: ICodeDescription;
  carplateNo: string;
  electricVehicleModel?: string;
  cardNo: string;
}

export interface IMonthlyCarParkingRequestSmartCard {
  name: string;
  gender: ICodeDescription;
  userType: ICodeDescription;
  carplateNo: string;
  electricVehicleModel?: string;
}

export type EFormRequestDetails =
  | IServiceLiftRequestForm
  | IMonthlyCarParkingRequestForm
  | IGenericRequestForm;

export interface IServiceLiftRequestForm {
  submissionType: 'SERVICE_LIFT_REQUEST';

  firstName: string;
  lastName: string;
  contactNo: IPhone;
  from: Date;
  to: Date;
  transportationCompany: string;
  inOut: ServiceLiftRequestInOut[];
  specialRequirements: string;
}

export interface IMonthlyCarParkingRequestForm {
  submissionType: 'MONTHLY_CAR_PARKING_REQUEST';

  firstName: string;
  lastName: string;
  contactNo: IPhone | null;

  spaces: IMonthlyCarParkingRequestSpace[];
}

export interface IGenericRequestForm {
  submissionType: 'GENERIC';
}

export interface IMonthlyCarParkingFormOptions {
  genders: ICodeDescription[];
  userTypes: ICodeDescription[];
  parkingSpaceTypes: ICodeDescription[];
}

export interface IMonthlyCarParkingSearchOptions {
  leases: ILease[];
  statuses: IColorCodeDescription[];
  views: IColorCodeDescription[];
  parkingSpaceTypes: ICodeDescription[];
}

// TODO(hinny): Update the interface
export interface IEditMonthlyCarParkingRequestBody {
  contactFirstName: string;
  contactLastName: string;
  contactNo: string;
  parkingSpaces: {
    carParkingSubmissionID: number;
    status: string;
    submissionRefNo: string;
    active: boolean;
    effectiveDate: Date;
    parkingSpaceType: 'CAR' | 'MOTORCYCLE';
    optInToContactlessParkingSystem: boolean;
    parkingSpaceDetails: {
      carParkingSubmissionDetailID: number;
      userName: string;
      gender: string;
      userType: string;
      carPlateNo: string;
      electricVehicle: boolean;
      electricVehicleModel: string;
      octopusCardNo: string;
    };
  }[];
}

export interface IMonthlyCarParkingSubmissionHistoryQuery {
  buildingIDs?: number[];
  leaseIdentifiers?: string[];
  statuses?: string[];
  parkingSpaceTypes?: string[];
  mySubmission?: boolean;
  hideExpiredTenancy?: boolean;
  search?: string;
  page?: number;
  limit?: number;
  sort?: ISort<QuerySortField>;
}
