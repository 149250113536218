import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageHistoryService {
  readonly historyUrls: string[] = [];

  constructor(private readonly router: Router) {}

  public getPreviousUrl(): string | null {
    if (this.historyUrls.length < 2) {
      return null;
    }
    return this.historyUrls[this.historyUrls.length - 2];
  }

  public subscribeEvent(): Subscription {
    return this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation.trigger === 'popstate') {
          this.popUrl();
        } else {
          this.pushUrl(event.url, navigation.extras.replaceUrl ?? false);
        }
      }
    });
  }

  private pushUrl(url: string, replace: boolean) {
    if (replace && this.historyUrls.length > 0) {
      this.historyUrls[this.historyUrls.length - 1] = url;
    } else {
      this.historyUrls.push(url);
    }
  }

  private popUrl() {
    if (this.historyUrls.length > 1) {
      this.historyUrls.pop();
    }
  }
}
