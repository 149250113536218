<mat-sidenav-container
  *ngIf="display$ | async as data"
  class="shell__sidenav-container overflow-visible sm:overflow-hidden w-full min-h-full flex sm:h-full"
>
  <mat-sidenav
    [(opened)]="isMenuOpened"
    mode="over"
    class="w-full sm:w-[19rem] app-sidenav"
  >
    <app-menu
      (closeMenu)="onCloseMenu()"
      [notificationAlerts]="notificationAlerts$ | async"
      class="w-full h-full"
    ></app-menu>
  </mat-sidenav>
  <div class="flex flex-col w-full h-full sm:overflow-hidden relative">
    <app-background
      class="shell__background absolute top-0 left-0 right-0 bottom-0"
      [class.hidden]="
        !data.display?.showFullBackground && !data.display?.fullScreen
      "
    ></app-background>
    <app-header
      class="flex-shrink-0 z-10 sm:z-auto sticky top-0 sm:static"
      (openMenu)="onOpenMenu()"
      [navTitle]="
        data.display?.navTitle$
          ? (data.display.navTitle$ | async)
          : (data.display?.navTitleKey | translate)
      "
      [navTitleSvgIcon]="data.display?.navTitleSvgIcon"
    ></app-header>
    <div
      class="shell__main-container flex flex-1 min-h-0 z-0"
      [class.shell__main-container--hide-nav]="
        data.display?.hideNavBar$ && (data.display.hideNavBar$ | async)
      "
      [class.shell__main-container--no-padding]="data.display?.fullScreen"
    >
      <div
        class="shell__main-content w-full h-full m-auto sm:overflow-hidden"
        [class.shell__main-content--hide-background]="data.display?.fullScreen"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-toolbar
      class="sticky bottom-0 sm:static flex-shrink-0 z-10 flex items-center justify-center sm:h-20 h-14 bg-white shadow-md-top"
      [class.hidden]="
        data.display?.hideNavBar$ && (data.display.hideNavBar$ | async)
      "
      [notificationAlerts]="notificationAlerts$ | async"
    ></app-toolbar>
  </div>
</mat-sidenav-container>
