import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OperationCode, SupportedLanguage } from '@core/enum';
import { OperationCodeExpression } from '@core/util/opCodeExpression';
import { environment } from '@env/environment';
import {
  IUserAccessRight,
  IUserSupportContactType,
  IAuthSubmissionGroup,
  IAuthUserSubmissionGroup,
  IRole,
  IAuthSubmissionGroupRole,
} from '@model/user';
import {
  compareLease,
  IAreaCode,
  IBuilding,
  IPhoneType,
  LeaseStatus,
} from '@model/common';
import { AuthAPIService, OTPResponse } from '@service/api/auth-api.service';
import {
  IAuthResponse,
  IUserInfoUpdateRequestParams,
} from '@service/api/models/auth';
import {
  mapLeaseFloor,
  mapSupportContact,
  mapSupportedLanguage,
} from '@service/mappers/auth';
import { formatPhone } from '@shared/utils/phone';
import { OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { groupBy, uniqBy } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  from,
  NEVER,
  Observable,
  of,
  pipe,
  zip,
  UnaryFunction,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { firstValueFrom } from '@core/util/firstValueFrom';
import { logError } from '@shared/utils/log';
import { RequestsService } from '@service/requests/requests.service';
import {
  mapAuthSubmissionGroup,
  mapAuthSubmissionGroupRole,
  mapAuthUserSubmissionGroup,
  mapBuilding,
  mapCodeDescriptions,
} from '@service/mappers/common';
import { IRegisterRequest } from '@pages/auth/register/models';
import { ILeaseFloor } from '@model/user/ILeaseFloor';

import { IUserManagementUser } from '@service/api/models/user-management';
import { mapUserManagementUser } from '@service/mappers/user-management';
import {
  LOCATION_TOKEN,
  requestOpcodes,
  ISignUpParams,
  IResetPasswordParams,
  IContactGroup,
  IAccessRightFilterOptions,
} from '@model/auth/models';
import { checkCanPerformaOperation } from './utils/checkCanPerformOperation';

declare class OAuthServiceProxy extends OAuthService {
  storeAccessTokenResponse: OAuthService['storeAccessTokenResponse'];
  eventsSubject: OAuthService['eventsSubject'];
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly oauth: OAuthServiceProxy;
  protected _token?: string;
  protected readonly _user$ = new BehaviorSubject<IUserManagementUser | null>(
    null
  );
  protected readonly _isInitialized$ = new BehaviorSubject<boolean>(false);
  protected readonly opCodeBuildings$ = new BehaviorSubject(
    new Map<OperationCode, Set<number>>()
  );

  get isInitialized$(): Observable<boolean> {
    return this._isInitialized$;
  }

  readonly user$ = this._isInitialized$.pipe(
    switchMap((isInitialized) => (isInitialized ? this._user$ : NEVER))
  );
  readonly authenticatedUser$ = this.user$.pipe(
    filter((user) => user != null),
    shareReplay(1)
  );

  readonly isAuthenticated$ = this.user$.pipe(map((user) => !!user));

  constructor(
    oauthService: OAuthService,
    private readonly requests: RequestsService,
    private readonly api: AuthAPIService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    @Inject(LOCATION_TOKEN) private readonly location: Location
  ) {
    this.oauth = oauthService as OAuthServiceProxy;
    this.oauth.configure({
      ...environment.authentication,
      strictDiscoveryDocumentValidation: false,
    });
    this.oauth.setStorage(sessionStorage);

    this.oauth.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe(() => {
        if (this._token !== this.oauth.getAccessToken()) {
          this.refreshUser();
        }
        this._token = this.oauth.getAccessToken();
      });

    this.oauth.events
      .pipe(filter((e) => e.type === 'token_refresh_error'))
      .subscribe(() => this.onLoggedOut(this.router.url));

    this.oauth.setupAutomaticSilentRefresh();
    this._token = this.oauth.getAccessToken();
  }

  public getUserIDSync(): number | null {
    return this._user$.value?.id ?? null;
  }

  public canPerformOperation(
    expression: OperationCodeExpression,
    buildingID?: number
  ): Observable<boolean> {
    return combineLatest([this.authenticatedUser$, this.opCodeBuildings$]).pipe(
      map(([user, opCodeBuildings]) => {
        return checkCanPerformaOperation(
          expression,
          user.operationCodes,
          opCodeBuildings,
          buildingID
        );
      })
    );
  }

  public async checkAuthz(
    opCodeExpr: OperationCodeExpression,
    buildingID?: number
  ): Promise<boolean> {
    let authzOk;

    if (typeof opCodeExpr === 'string') {
      authzOk = await firstValueFrom(
        this.canPerformOperation(opCodeExpr, buildingID)
      );
    } else if (opCodeExpr.operator === 'not') {
      authzOk = !(await this.checkAuthz(opCodeExpr.subExpr, buildingID));
    } else {
      authzOk = await firstValueFrom(
        zip(
          ...opCodeExpr.subExprArray.map(async (subExpr) => {
            return this.checkAuthz(subExpr, buildingID);
          })
        ).pipe(
          map((authzResultArray) => {
            if (opCodeExpr.operator === 'and') {
              return authzResultArray.every((authzResult) => authzResult);
            }
            if (opCodeExpr.operator === 'or') {
              return authzResultArray.some((authzResult) => authzResult);
            }
            return true;
          })
        )
      );
    }

    return authzOk;
  }

  protected async loadUser(): Promise<IUserManagementUser | null> {
    let accessTokenOk: boolean;
    if (this.oauth.hasValidAccessToken()) {
      accessTokenOk = true;
    } else if (this.oauth.getRefreshToken() != null) {
      try {
        await this.oauth.refreshToken();
        accessTokenOk = true;
      } catch {
        accessTokenOk = false;
      }
    } else {
      accessTokenOk = false;
    }

    let user: IUserManagementUser | null = null;
    if (accessTokenOk) {
      user = await this.api
        .getAccountInfo()
        .pipe(map(mapUserManagementUser))
        .toPromise();

      const ops: Promise<any>[] = [];
      if (
        user.operationCodes.some((opCode) =>
          requestOpcodes.has(opCode as OperationCode)
        )
      ) {
        // Only get building list when opCode includes request related opCodes
        // Preload to avoid API errors when checking opcodes
        ops.push(this.requests.getBuildings().toPromise());
      }
      if (user.operationCodes.length > 0) {
        ops.push(
          this.api
            .getMyAccountOpCodeBuildings(user.operationCodes)
            .toPromise()
            .then((res) => {
              const opCodeBuilding = new Map<OperationCode, Set<number>>();
              for (const [opCode, info] of Object.entries(res)) {
                opCodeBuilding.set(
                  opCode as OperationCode,
                  new Set(info.buildingIDs)
                );
              }
              this.opCodeBuildings$.next(opCodeBuilding);
            })
        );
      }
      await Promise.all(ops);
    }
    return user;
  }

  protected async refreshUser(): Promise<void> {
    let user: IUserManagementUser | null = null;
    try {
      user = await this.loadUser();
    } catch {
      user = null;
    }
    this._user$.next(user);
    if (!user) {
      this.onLoggedOut(this.router.url);
    }
  }

  public async initialize(): Promise<void> {
    try {
      await this.oauth.loadDiscoveryDocument();
      await this.refreshUser();
    } finally {
      this._isInitialized$.next(true);
    }
  }

  private handleAuthResponse(): UnaryFunction<
    Observable<IAuthResponse>,
    Observable<void>
  > {
    return pipe(
      tap((resp: IAuthResponse) => {
        this.oauth.storeAccessTokenResponse(
          resp.accessToken,
          resp.refreshToken,
          resp.expiresIn,
          ''
        );
        this._token = resp.accessToken;
        this.oauth.eventsSubject.next(new OAuthSuccessEvent('token_received'));
        this.oauth.eventsSubject.next(new OAuthSuccessEvent('token_refreshed'));
      }),
      switchMap(() =>
        from(this.loadUser().then((user) => this._user$.next(user)))
      )
    );
  }

  public loginByEmail(email: string, password: string): Observable<void> {
    return this.api.signIn({ email, password }).pipe(this.handleAuthResponse());
  }

  public loginByToken(token: string): Observable<void> {
    return this.api.signIn({ token }).pipe(this.handleAuthResponse());
  }

  onLoggedOut(returnTo?: string): void {
    this.dialog.closeAll();
    this.oauth.logOut({
      client_id: environment.authentication.clientId,
      logout_uri: '/pages/login',
    });
    this._user$.next(null);
    sessionStorage.removeItem('otp_token');
    if (this._token) {
      this._token = undefined;
      if (returnTo) {
        this.location.href =
          '/pages/login?returnTo=' + encodeURIComponent(returnTo);
      } else {
        this.location.href = '/pages/login';
      }
    }
  }

  public logout(): Observable<any> {
    return this.api.signOut().pipe(
      catchError((err) => {
        logError(err);
        return of({});
      }),
      tap(() => {
        this.onLoggedOut();
      })
    );
  }

  public requestOTP(): Observable<OTPResponse> {
    return this.api.requestOTP();
  }

  public validateOTP(uuid: string, otp: string): Observable<boolean> {
    return this.api.validateOTP({ uuid, otp }).pipe(
      tap((token) => {
        if (token) {
          sessionStorage.setItem('otp_token', token);
        }
      }),
      map((resp) => !!resp)
    );
  }

  public getUserInfoByToken(
    token: string
  ): Observable<IUserManagementUser | null> {
    return this.api.validateToken(token).pipe(
      switchMap((valid) => {
        if (!valid) {
          throw Error('invalid token');
        }
        return this.api
          .getUserInfoByToken(token)
          .pipe(map(mapUserManagementUser));
      })
    );
  }

  public signUp(params: ISignUpParams): Observable<void> {
    return this.api.signUp(params).pipe(switchMap(() => of(null)));
  }

  public requestForgotPassword(email: string): Observable<void> {
    return this.api.requestForgotPassword(email);
  }

  public resetPassword(params: IResetPasswordParams): Observable<void> {
    return this.api.resetPassword(params).pipe(switchMap(() => of(null)));
  }

  public updateUserInfo(info: Partial<IUserManagementUser>): Observable<void> {
    const currentUser = this._user$.value;

    if (!currentUser) {
      throw new Error('Unable to get user info');
    }

    const userPhones = (info.phones ?? currentUser.phones).map((p, i) => {
      const formattedPhone = formatPhone(p.areaCode, p.phoneNumber);
      return {
        phoneType: p.phoneType,
        telNo: formattedPhone,
        sequence: i + 1,
      };
    });

    const updatedInfo: IUserInfoUpdateRequestParams = {
      displayName: info.displayName ?? currentUser.displayName,
      firstName: info.firstName ?? currentUser.firstName,
      lastName: info.lastName ?? currentUser.lastName,
      jobTitle: info.jobTitle ?? currentUser.jobTitle ?? '',
      company: info.company ?? currentUser.company ?? '',
      phones: userPhones,
    };

    return this.api
      .updateAccountInfo(updatedInfo)
      .pipe(switchMap(() => from(this.refreshUser())));
  }

  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<void> {
    return this.api.changePassword({
      oldPassword,
      newPassword,
    });
  }

  public updateLanguagePreference(
    language: SupportedLanguage
  ): Observable<void> {
    const apiLanguage = mapSupportedLanguage(language);
    return this.api
      .updateLanguagePreference({
        language: apiLanguage,
      })
      .pipe(switchMap(() => from(this.refreshUser())));
  }

  public getAllContacts(): Observable<IContactGroup[]> {
    return combineLatest([
      this.api.getUserContacts(),
      this.api.getUserBuildings(),
      this.getUserContactTypes(),
    ]).pipe<IContactGroup[]>(
      map(([contacts, buildings, types]) => {
        const newContacts = contacts.map((contact) =>
          mapSupportContact(contact, types)
        );
        const buildingGroups = Object.entries(
          groupBy(buildings, (b) => b.name)
        ).map(([name, bs]) => ({ name, ids: bs.map((b) => b.id) }));
        return buildingGroups
          .sort((a, b) =>
            a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
          )
          .map((group) => {
            const groupContacts = uniqBy(
              newContacts.filter((c) => group.ids.includes(c.buildingId)),
              (c) =>
                [c.contactType.code, c.phone ?? '', c.email ?? ''].join(':')
            );

            return {
              buildingName: group.name,
              contacts: groupContacts,
            };
          })
          .filter((g) => g.contacts.length > 0);
      })
    );
  }

  public getMyAccountInfo(): Observable<IUserManagementUser> {
    return this.api.getAccountInfo().pipe(map(mapUserManagementUser));
  }

  public getAllAccessRights(): Observable<IUserAccessRight[]> {
    return this.getMyAccountInfo().pipe(map((user) => user.accessRights));
  }

  public getUserPhoneTypes(): Observable<IPhoneType[]> {
    return this.api.getUserPhoneTypes().pipe(
      map((types) =>
        mapCodeDescriptions(
          types.map(({ phoneType, ...rest }) => ({
            code: phoneType,
            ...rest,
          }))
        )
      )
    );
  }

  public filterAccessRights(
    accessRights: IUserAccessRight[],
    options?: IAccessRightFilterOptions
  ): Observable<IUserAccessRight[]> {
    let filteredRights = accessRights;
    if (options?.buildingName) {
      filteredRights = filteredRights.filter(
        (ar) => ar.lease.buildingName === options.buildingName
      );
    }
    if (options?.hideExpired) {
      filteredRights = filteredRights.filter(
        (ar) => ar.lease.status !== LeaseStatus.Expired
      );
    }

    return of(filteredRights.sort((a, b) => compareLease(a.lease, b.lease)));
  }

  public getUserContactTypes(): Observable<IUserSupportContactType[]> {
    return this.api.getUserContactTypes().pipe(
      map((contactTypes) => {
        return mapCodeDescriptions(
          contactTypes.map((t) => ({
            // Support contact type has no tpShow, active, sequence
            tpShow: true,
            active: true,
            sequence: 0,
            ...t,
          }))
        );
      })
    );
  }

  public getMasterAccountBuildings(): Observable<IBuilding[]> {
    return this.api
      .getMasterAccountBuildings()
      .pipe(map((buildings) => buildings.map(mapBuilding)));
  }

  public getMasterAccountPhoneTypes(): Observable<IPhoneType[]> {
    return this.api.getMasterAccountPhoneTypes().pipe(
      map((types) =>
        mapCodeDescriptions(
          types.map(({ phoneType, ...rest }) => ({
            code: phoneType,
            ...rest,
          }))
        )
      )
    );
  }

  public getMasterAccountAreaCodes(): Observable<IAreaCode[]> {
    return this.api.getMasterAccountAreaCodes().pipe(
      map((areaCodes) =>
        mapCodeDescriptions(
          areaCodes.map(({ code, ...rest }) => ({
            code: code.replace('+', ''),
            sequence: 0, // Area code has no sequence
            ...rest,
          }))
        )
      )
    );
  }

  public registerRequest(request: IRegisterRequest): Observable<void> {
    return this.api.registerRequest(request);
  }

  public getBuildings(): Observable<IBuilding[]> {
    return this.api.getUserBuildings();
  }

  public getAuthSubmissionGroups(): Observable<IAuthSubmissionGroup[]> {
    return this.api
      .getAuthSubmissionGroups()
      .pipe(map((resp) => resp.map(mapAuthSubmissionGroup)));
  }

  public getAuthSubmissionGroupRoles(): Observable<IAuthSubmissionGroupRole[]> {
    return this.api
      .getAuthSubmissionGroupRoles()
      .pipe(map((res) => res.map(mapAuthSubmissionGroupRole)));
  }

  public getUserSubmissionGroups(): Observable<IAuthUserSubmissionGroup[]> {
    return combineLatest([
      this.getAuthSubmissionGroupRoles(),
      this.getBuildings(),
      this.getAuthSubmissionGroups(),
    ]).pipe(
      map(([accessRights, buildings, submissionGroups]) => {
        const submissionGroupIdToRole = new Map<number, IRole[]>();
        const buildingIDTobuildingName = new Map<number, string>();

        for (const accessRight of accessRights) {
          if (accessRight.gppSubmissionGroupID !== null) {
            submissionGroupIdToRole.set(
              accessRight.gppSubmissionGroupID,
              accessRight.roles
            );
          }
        }

        for (const building of buildings) {
          buildingIDTobuildingName.set(building.id, building.name);
        }

        return submissionGroups.map((submissionGroup) =>
          mapAuthUserSubmissionGroup(
            submissionGroupIdToRole,
            buildingIDTobuildingName,
            submissionGroup
          )
        );
      })
    );
  }

  public resendInvitation(userID: number): Observable<void> {
    return this.api.resendInvitation(userID);
  }

  public getLeaseFloors(leaseIdentifiers: string[]): Observable<ILeaseFloor[]> {
    return this.api
      .getLeaseFloors(leaseIdentifiers)
      .pipe(map((list) => list.map(mapLeaseFloor)));
  }

  public getUserTenancies(): Observable<IUserAccessRight[]> {
    return this.api
      .getUserTenancies()
      .pipe(
        map((tenancies) =>
          tenancies.slice().sort((a, b) => compareLease(a.lease, b.lease))
        )
      );
  }
}

export { IAccessRightFilterOptions };
