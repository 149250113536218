import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SupportedLanguage } from '@core/enum/supported-language.enum';
import { AppTranslateService } from '@shared/services/translate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input()
  navTitle = '';

  @Input()
  navTitleSvgIcon: string | null = null;

  @Output()
  readonly openMenu = new EventEmitter<boolean>();

  readonly SupportedLanguage = SupportedLanguage;

  constructor(readonly translate: AppTranslateService) {}
}
