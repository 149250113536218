import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { ModalRouteGuard } from './modal-route.guard';
import { UnsavedFormRouteGuard } from './unsaved-form.guard';

const guards = [ModalRouteGuard, UnsavedFormRouteGuard];

@Injectable({ providedIn: 'root' })
export abstract class DeactivateChildGuard implements CanActivateChild {
  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
    const config = childRoute.routeConfig;
    if (config && childRoute.children.length === 0) {
      // Ensure deactivate route guards are configured in child routes

      config.canDeactivate = config.canDeactivate ?? [];
      for (const guard of guards) {
        if (!config.canDeactivate.includes(guard)) {
          config.canDeactivate.push(guard);
        }
      }

      if (!config.runGuardsAndResolvers) {
        config.runGuardsAndResolvers = 'paramsOrQueryParamsChange';
      }
    }
    return true;
  }
}
