export const AppRegex = {
  CONTAIN_UPPERCASE: /[A-Z]/,
  CONTAIN_LOWERCASE: /[a-z]/,
  CONTAIN_NUMBER: /\d/,
  PHONE_NUMBER: /^\+([0-9 ]+)-(.+)$/,
  EMAIL:
    /^$|^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  CONTAIN_ONLY_NUMBER: /^[0-9]+$/,
  CONTAIN_ONLY_CHARACTER: /^[a-zA-Z]+$/,
  CONAIN_BOTH_NUMBER_AND_CHARACTER: /^[a-zA-Z0-9]+$/,
};
