<router-outlet></router-outlet>

<div
  [ngClass]="{ 'bg-[#F257C3]': env === 'DEV', 'bg-[#FF6B00]': env === 'UAT' }"
  class="fixed bottom-6 left-0 text-white font-bold pt-0.5 pb-0.5 pl-3 pr-3 overlay"
  *ngIf="env === 'DEV' || env === 'UAT'"
>
  {{ env }}
</div>
<div
  [class.hidden]="!(isLoading$ | async)"
  class="shell-page-load-indicator fixed left-0 top-0 w-full z-50"
>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</div>
