import { Routes } from '@angular/router';
import { ForbiddenComponent } from './error-page/forbidden/forbidden.component';
import { GenericErrorComponent } from './error-page/generic-error/generic-error.component';
import { InvalidLinkComponent } from './error-page/invalid-link/invalid-link.component';
import { NotFoundComponent } from './error-page/not-found/not-found.component';
import { ServiceUnavailableComponent } from './error-page/service-unavailable/service-unavailable.component';
import { UnsupportedBrowserComponent } from './error-page/unsupported-browser/unsupported-browser.component';

export const SystemPageRoutes: Routes = [
  {
    path: 'error',
    component: GenericErrorComponent,
  },
  {
    path: 'invalid',
    component: InvalidLinkComponent,
  },
  {
    path: '403',
    component: ForbiddenComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '503',
    component: ServiceUnavailableComponent,
  },
  {
    path: 'unsupported-browser',
    component: UnsupportedBrowserComponent,
  },
  { path: '**', redirectTo: '/pages/system/404' },
];
