import { APIEFormSubmissionDetail } from '../models/e-forms';
import {
  IAPIForm,
  IAPIFormSubmission,
  IAPIFormSubmissionFormInfo,
} from '../models/forms-v2';
import { parseDatetime } from '../utils/parser';

export function mapAPIForm(form: any): IAPIForm {
  return {
    formID: form.formID,
    name: form.name,
    category: form.category,
    description: form.description,
    starred: form.starred ?? false,
    isMultipleLease: form.isMultipleLease,
    formType: form.formType,
    buildings:
      (form.buildings as any[])?.map((b) => ({
        formBuildingID: b.formBuildingID,
        buildingID: b.buildingID,
        editable: b.editable,
      })) ?? [],
    file: form.file
      ? {
          version: form.file.version,
          formFileID: form.file.formFileID,
          fileName: form.file.fileName,
          fileExtension: form.file.fileExtension,
          fileSize: form.file.fileSize,
          fileUrl: form.file.fileUrl,
        }
      : null,
    allowOnlineSubmission: form.allowOnlineSubmission,
    operations: form.operations ?? [],

    formItemID: form.formItemID || null,
    tandCDescription: form.tandCDescription || null,
    tandCDescriptionZhHK: form.tandCDescriptionZhHK || null,
    tandCDescriptionZhCN: form.tandCDescriptionZhCN || null,
  };
}

export function mapAPISubmission(item: any): IAPIFormSubmission | null {
  const detail = mapAPIFormSubmissionDetail(item.detail);
  if (detail.submissionType === 'UNKNOWN') {
    return null;
  }

  return {
    formSubmissionID: item.formSubmissionID,
    leases: (item.leases as any[]).map((l) => ({
      leaseIdentifier: l.leaseIdentifier,
    })),
    files: (item.files as any[]).map((f) => ({
      formSubmissionFileID: f.formSubmissionFileID,
      fileName: f.fileName,
      fileExtension: f.fileExtension,
      fileSize: f.fileSize,
    })),
    remarks: item.remarks,
    createdByName: item.createdByName,
    createdDate: parseDatetime(item.createdDate),
    lastUpdatedAt: parseDatetime(item.lastUpdatedAt ?? item.createdDate),
    lastUpdatedByName: item.lastUpdatedByName ?? item.createdByName,
    isByCurrentUser: item.isByCurrentUser,
    status: item.status,
    reviewedBy: item.reviewedBy,
    rejectReason: item.rejectReason,
    submissionRefNo: item.submissionRefNo,
    form: mapAPIFormSubmissionFormInfo(item.form),
    detail,
  };
}

export function mapAPIFormSubmissionDetail(
  detail: any
): APIEFormSubmissionDetail {
  switch (detail.submissionType) {
    case 'SERVICE_LIFT_REQUEST':
      return {
        submissionType: 'SERVICE_LIFT_REQUEST',
        firstName: detail.firstName,
        lastName: detail.lastName,
        contactNo: detail.contactNo,
        from: detail.from,
        to: detail.to,
        transportationCompany: detail.transportationCompany,
        inOut: detail.inOut,
        specialRequirements: detail.specialRequirements,
      };
    case 'GENERIC':
      return {
        submissionType: 'GENERIC',
      };
    case 'MONTHLY_CAR_PARKING':
      return {
        submissionType: 'MONTHLY_CAR_PARKING_REQUEST',

        firstName: detail.firstName,
        lastName: detail.lastName,
        contactNo: detail.contactNo,

        // TODO(newman): Add mapper to submit monthly car parking
        parkingSpaces: [],
      };
    default:
      return {
        submissionType: 'UNKNOWN',
      };
  }
}

function mapAPIFormSubmissionFormInfo(form: any): IAPIFormSubmissionFormInfo {
  return {
    formID: form.formID,
    name: form.name,
    category: form.category,
    formType: form.formType,
    formItemID: form.formItemID || null,
    tandCDescription: form.tandCDescription,
    tandCDescriptionZhCN: form.tandCDescriptionZhCN,
    tandCDescriptionZhHK: form.tandCDescriptionZhHK,
  };
}
