import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { marker as l10n } from '@biesbjerg/ngx-translate-extract-marker';
import { of } from 'rxjs';
import { StreamlineIconsModule } from '@shared/icons/streamline-icons.module';
import { IShellDisplay, SHELL_DISPLAY } from '@shared/metadata/IShellDisplay';

const submitShellDisplay: IShellDisplay = {
  route: '/pages/service-lift/submit',
  navTitleKey: l10n('SERVICE_LIFT.SUBMIT.NAV_TITLE'),
  navTitleSvgIcon: 'streamline:pencil-write-2',
  hideNavBar$: of(true),
};

@NgModule({
  imports: [MatIconModule, StreamlineIconsModule],
  providers: [
    { provide: SHELL_DISPLAY, useValue: submitShellDisplay, multi: true },
  ],
})
export class ServiceLiftMetadataModule {}
