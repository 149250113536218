import { Injectable } from '@angular/core';
import { ApiClient } from '@core/http/api-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapAPIEditableBuilding } from './mappers/common-api';
import { IAPIBuildings } from './models/requests';

@Injectable({
  providedIn: 'root',
})
export class RequestsAPIService {
  constructor(private readonly http: ApiClient) {}

  public getBuildings(): Observable<IAPIBuildings> {
    return this.http.get<any>('requests/buildings').pipe(
      map((resp) => ({
        ac: (resp.ac as any[]).map(mapAPIEditableBuilding),
        accessCard: (resp.accessCard as any[]).map(mapAPIEditableBuilding),
        directorySignage: (resp.directorySignage as any[]).map(
          mapAPIEditableBuilding
        ),
        facilityMaintenance: (resp.facilityMaintenance as any[]).map(
          mapAPIEditableBuilding
        ),
        form: (resp.form as any[]).map(mapAPIEditableBuilding),
        monthlyCarParking: (resp.monthlyCarParking as any[]).map(
          mapAPIEditableBuilding
        ),
        serviceLift: (resp.serviceLift as any[]).map(mapAPIEditableBuilding),
        staffAccessCard: (resp.staffAccessCard as any[]).map(
          mapAPIEditableBuilding
        ),
        standardFitting: (resp.standardFitting as any[]).map(
          mapAPIEditableBuilding
        ),
      }))
    );
  }
}
