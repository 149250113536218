import {
  AlertType,
  INotification,
  INotificationAlerts,
  INotificationBuilding,
  INotificationLease,
  INotificationType,
  NotificationLinkType,
} from '@model/notifications';
import {
  IAPIBuilding,
  IAPINotification,
  IAPINotificationAlert,
  IAPINotificationLease,
  IAPINotificationType,
} from '@service/api/models/notifications';

export function mapNotificationLease(
  lease: IAPINotificationLease
): INotificationLease {
  return {
    leaseIdentifier: lease.leaseIdentifier,
    shopName: lease.currentShopList || null,
    tenantName: lease.currentTenantName,
    unitName: lease.unitName,
    portfolioType: lease.portfolioType,
  };
}

function mapNotificationBuilding(
  building: IAPIBuilding
): INotificationBuilding {
  return {
    id: building.buildingID,
    name: building.localBuildingName,
  };
}

function mapNotificationLinkType(type: string | null): NotificationLinkType {
  switch (type) {
    case 'CIRCULAR':
      return NotificationLinkType.Circular;
    case 'FORM_SUBMISSION':
      return NotificationLinkType.FormSubmission;
    case 'DEBIT_NOTE':
      return NotificationLinkType.DebitNote;
    case 'FACILITY_MAINTENANCE':
      return NotificationLinkType.FacilityMaintenance;
    case 'AIR_COND_REQUEST':
      return NotificationLinkType.ACRequest;
    case 'GPP_BASELINE_SETTING':
      return NotificationLinkType.GPPBaselineSetting;
    case 'GPP_AWARD_GROUP':
      return NotificationLinkType.GPPAwardGroup;
    default:
      return NotificationLinkType.Unknown;
  }
}

export function mapNotificationType(
  apiType: IAPINotificationType
): INotificationType {
  return {
    code: apiType.code,
    description: {
      enUS: apiType.description,
      zhHant: apiType.descriptionZhHK,
      zhHans: apiType.descriptionZhCN,
    },
    order: apiType.sequence,
    labelColour: apiType.labelColour,
  };
}

export function mapNotification(
  notification: IAPINotification,
  types: Map<string, INotificationType>
): INotification {
  return {
    id: notification.notificationID,
    type: types.get(notification.notificationType),
    isRead: !notification.unread,
    buildings: notification.buildings
      .map(mapNotificationBuilding)
      .sort((a, b) =>
        a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
      ),
    leases: notification.leases
      .map(mapNotificationLease)
      .sort(
        (a, b) =>
          (a.shopName || a.tenantName).localeCompare(
            b.shopName || b.tenantName,
            'en',
            { sensitivity: 'base' }
          ) ||
          a.unitName.localeCompare(b.unitName, 'en', { sensitivity: 'base' })
      ),
    submissionGroupInfo: notification.submissionGroupInfo,
    content: notification.description,
    date: notification.date,
    linkId: notification.linkID,
    linkType: mapNotificationLinkType(notification.linkType),
  };
}

function getAlertCountByType(
  alerts: IAPINotificationAlert[],
  type: string
): number | null {
  const alert = alerts.find((a) => a.type === type);

  // return values of find():

  // alert.count = number - alert type is present, count is normal number (only appears on the 4 other types) => no mapping needed
  // alert.count = null - alert type is present, count is null (only appears on 'SALES') => map `null` to `1`
  // alert === undefined - alert type is absent (can appear for any of the 5 types) => map `undefined` to `0`

  if (!alert) {
    return 0;
  }
  return alert.count ?? 1;
}

export function mapNotificationAlerts(
  alerts: IAPINotificationAlert[]
): INotificationAlerts {
  return {
    [AlertType.SALES]: getAlertCountByType(alerts, AlertType.SALES),
    [AlertType.BILLING]: getAlertCountByType(alerts, AlertType.BILLING),
    [AlertType.DOCUMENT]: getAlertCountByType(alerts, AlertType.DOCUMENT),
    [AlertType.CIRCULAR]: getAlertCountByType(alerts, AlertType.CIRCULAR),
    [AlertType.FORMS]: getAlertCountByType(alerts, AlertType.FORMS),
    // FIXME: use correct GPP key
    [AlertType.GPP]: getAlertCountByType(alerts, AlertType.GPP),
  };
}
