import { isLeft } from 'fp-ts/Either';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';

export class DecodeError extends Error {
  constructor(readonly validationErrors: string[]) {
    super(validationErrors.join('\n'));
  }
}

export function makeImpureDecode<T, I = unknown>(
  Decoder: t.Decoder<I, T>
): (i: I) => T {
  return (i: I): T => {
    const result = Decoder.decode(i);
    if (isLeft(result)) {
      throw new DecodeError(PathReporter.report(result));
    }
    return result.right;
  };
}
