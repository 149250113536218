import { flatMap as flatMapEither } from 'fp-ts/Either';
import * as t from 'io-ts';
import moment from 'moment';

export const nullable = <C extends t.Mixed>(c: C): t.UnionC<[t.NullC, C]> =>
  t.union([t.null, c]);
export const optional = <C extends t.Mixed>(
  c: C
): t.UnionC<[t.UndefinedC, C]> => t.union([t.undefined, c]);

export const LocalDateCodec = new t.Type<Date, string, unknown>(
  'LocalDateCodec',
  (u): u is Date => u instanceof Date,
  (u, c) =>
    flatMapEither(t.string.validate(u, c), (str) => {
      const m = moment(str, 'YYYY-MM-DD');
      if (!m.isValid()) {
        return t.failure(u, c, 'Invalid Date');
      }
      return t.success(m.toDate());
    }),
  (date) => moment(date).format('YYYY-MM-DD')
);

export const DateTimeCodec = new t.Type<Date, string, unknown>(
  'DateTimeCodec',
  (u): u is Date => u instanceof Date,
  (u, c) =>
    flatMapEither(t.string.validate(u, c), (str) => {
      const m = moment(str, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
      if (!m.isValid()) {
        return t.failure(u, c, 'Invalid Date');
      }
      return t.success(m.toDate());
    }),
  (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
);
