import { pipe } from 'fp-ts/function';
import * as Monoid from 'fp-ts/Monoid';
import * as Ord from 'fp-ts/Ord';
import { ILocalizedString, ordLocalizableString } from '.';
import { ISequenceComparable } from './ISequenceComparable';
import { sequenceOrd } from './SequenceComparable';

export interface BuildingFacility extends ISequenceComparable {
  code: string;
  description: ILocalizedString;
  status: FacilityTypeStatus;
  sequence: number;
}

export enum FacilityTypeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export const ordBuildingFacility = Monoid.concatAll(
  Ord.getMonoid<BuildingFacility>()
)([
  sequenceOrd,
  pipe(
    ordLocalizableString,
    Ord.contramap((bf: BuildingFacility) => bf.description)
  ),
]);
