import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { OAuthModule } from 'angular-oauth2-oidc';
import { IAPIEndpointConfig, API_ENDPOINT_CONFIG } from './http/api-client';
import { EncodeURIComponentInterceptor } from './http/codec';
import { NgIdleModule } from '@ng-idle/core';
import { TranslationLoader } from '@core/util/translation-loader';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: TranslationLoader },
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiEndpoint],
        sendAccessToken: true,
      },
    }),
    NgIdleModule.forRoot(),
  ],

  providers: [
    {
      provide: API_ENDPOINT_CONFIG,
      useValue: {
        endpoint: environment.apiEndpoint,
        apiKey: environment.apiKey,
      } as IAPIEndpointConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeURIComponentInterceptor,
      multi: true,
    },
    ...environment.providers,
  ],
  exports: [TranslateModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import Core Module in the AppModule only.`
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
